import { Step, Steps, useSteps } from "chakra-ui-steps";
import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import fetcher from "../../../../../lib/fetcher";
import { useLocation } from "react-router-dom";
import { count } from "sms-length";
import { Player } from "@lottiefiles/react-lottie-player";

const steps = [
  { id: 1, label: "Enter Mobile", description: "Enter Your Mobile Number" },
  {
    id: 2,
    label: "Notification Options",
    description: "Enter Your Notification Details",
  },
  { id: 3, label: "Preview", description: "Preview Your Data" },
];

export const StepsExample = (props) => {
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });
  const boxBg = useColorModeValue("white", "whiteAlpha.100");
  const textColor = useColorModeValue("#1b254b", "white");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [smsMessageObject, setSmsMessageObject] = useState({});

  const [notificationType, setNotificationType] = useState("INFO");
  const [audio] = useState(new Audio(""));
  const [resLoading, setResLoading] = useState(false);
  const [processDone, setProcessDone] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();
  const { type } = location.state;
  const isError = false;
  const toast = useToast();

  const resetNotification = () => {
    setPhoneNumber("");
    setSubject("");
    setMessage("");
    setTitle("");
    setResLoading(false);
    setProcessDone(false);
    reset();
  };

  const resetFunction = () => {
    setSubject("");
    setTitle("");
    setMessage("");
    setPhoneNumber("");
    setResLoading(false);
    setProcessDone(false);
  };

  const handleProcess = async () => {
    if (activeStep === 0) {
      if (phoneNumber.length < 9) {
        setError(true);
      } else {
        nextStep();
        setError(false);
      }
    }
    if (activeStep === 1) {
      if (subject.length < 2 || message.length === 0) {
        setError(true);
      } else {
        nextStep();
        setError(false);
      }
    }
    if (activeStep === 2) {
      if (phoneNumber && message && subject) {
        setResLoading(true);

        let payload = {
          notificationBody: {
            name: subject,
            channels: type === "in-app" ? ["IN_APP"] : ["SMS"],
            message: message,
            level: type === "in-app" ? notificationType : "INFO",
            title: subject,
          },
          phones: [`971${phoneNumber}`],
        };

        fetcher("notification/phones", payload)
          .then(async (res) => {
            setResLoading(false);
            setProcessDone(true);

            audio.src = "/sounds/mail_sent.mp3";
            await audio.play();
            toast({
              title: `Notification sent successfully`,
              description: `Notification has been sent.`,
              status: "success",
              duration: 4000,
              isClosable: true,
              onCloseComplete: async () => {
                resetFunction();
                reset();
              },
            });
          })
          .catch(async (e) => {
            setResLoading(false);

            audio.src = "/sounds/error.mp3";
            await audio.play();
            toast({
              title: "Something went wrong.",
              description: e.message,
              status: "error",
              duration: 4000,
              isClosable: true,
            });
          });
      }
    }
  };

  const phoneNumberValidator = (value) => {
    const maxLength = 9;
    setPhoneNumber(value);
    if (value.length > maxLength) {
      setPhoneNumber(value.slice(0, maxLength));
    }
  };

  const handleMessage = (e) => {
    setSmsMessageObject(count(e.target.value));
    setMessage(e.target.value);
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Flex
        flexDir="column"
        width="100%"
        bg={boxBg}
        p={5}
        borderRadius={20}
        shadow={"sm"}
      >
        <Steps colorScheme="pink" activeStep={activeStep}>
          {steps.map(({ label, description, id }, index) => (
            <Step
              label={label}
              key={label}
              description={description}
              textColor={"#fff"}
            >
              {id == 1 ? (
                <Flex px={4} pt={8} width="100%" flexDirection="column">
                  <Heading fontSize="md">{label}</Heading>
                  <InputGroup my={2} w={"350px"}>
                    <InputLeftAddon children="+971" />
                    <input
                      isInvalid={error}
                      color={textColor}
                      type="number"
                      className={"chakra-input"}
                      value={phoneNumber}
                      onChange={(e) => phoneNumberValidator(e.target.value)}
                      placeholder="Phone Number"
                      style={{
                        outline: "#7e1f1f00 solid 2px",
                        paddingLeft: "15px",
                        border: "solid #e7e7e7",
                        width: "100%",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderImage: "initial",
                        borderColor: "inherit",
                        background: "inherit",
                        borderBottomRightRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </InputGroup>
                  {error ? (
                    <Text color={"red"}>Phone number is required.</Text>
                  ) : null}
                </Flex>
              ) : null}

              {id == 2 ? (
                <Flex px={4} pt={8} width="100%" flexDirection="column">
                  <Heading fontSize="md">{label}</Heading>
                  <FormControl isInvalid={isError} width={"350px"} pt={5}>
                    <FormLabel pt={3} htmlFor="subject">
                      Title
                    </FormLabel>
                    <Input
                      id="subject"
                      type="text"
                      value={subject}
                      color={textColor}
                      isInvalid={error}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                    {!isError ? (
                      <FormHelperText>
                        Enter a notification subject.
                      </FormHelperText>
                    ) : (
                      <FormErrorMessage>Subject is required.</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={isError} width={"350px"} pt={5}>
                    <FormLabel htmlFor="message">Message</FormLabel>

                    <Textarea
                      id={"message"}
                      value={message}
                      onChange={(e) => handleMessage(e)}
                      isInvalid={error}
                      size="sm"
                    />
                    {!isError ? (
                      <>
                        <Flex
                          flexDir={"row"}
                          justifyContent={"space-between"}
                        ></Flex>
                        <Flex flexDir={"row"} justifyContent={"space-between"}>
                          <FormHelperText>
                            Enter the notification message.
                          </FormHelperText>
                          <FormHelperText>
                            {`${
                              message.length !== 0 ? message.length : null
                            } (${smsMessageObject?.characterPerMessage})`}
                          </FormHelperText>
                        </Flex>
                      </>
                    ) : (
                      <FormErrorMessage>Message is required.</FormErrorMessage>
                    )}
                    {smsMessageObject?.messages > 1 && (
                      <FormHelperText>
                        SMS Count{" "}
                        <Badge ml={1} colorScheme="red">
                          {smsMessageObject?.messages
                            ? smsMessageObject?.messages
                            : 1}
                        </Badge>
                        <Popover isLazy placement="right">
                          <PopoverTrigger>
                            <IconButton
                              size="sm"
                              justifyContent={"center"}
                              pb={1}
                              icon={
                                <Player
                                  autoplay
                                  loop
                                  speed={0.1}
                                  src="https://assets7.lottiefiles.com/packages/lf20_Z8MOn3.json"
                                  style={{ height: "35px", width: "35px" }}
                                ></Player>
                              }
                            />
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverHeader fontWeight="semibold">
                              B2Connect SMS Counter
                            </PopoverHeader>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody>
                              Seems, the entered sms characters exceeded and
                              reached {smsMessageObject.messages} SMS - It may
                              incur an additional cost & count!
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </FormHelperText>
                    )}
                  </FormControl>
                </Flex>
              ) : null}

              {id == 3 ? (
                <Flex px={4} pt={8} width="100%" flexDirection="column">
                  <Heading fontSize="md">{label} 3</Heading>
                  <TableContainer>
                    <Table variant="simple">
                      <TableCaption>
                        Check the current information and confirm
                      </TableCaption>
                      <Thead>
                        <Tr>
                          <Th>Title</Th>
                          <Th>Message</Th>
                          <Th>Phone Number</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>{subject}</Td>
                          <Td>{message}</Td>
                          <Td>{phoneNumber}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
              ) : null}
            </Step>
          ))}
        </Steps>
        {activeStep === steps.length ? (
          <Flex px={4} py={4} width="100%" flexDirection="column">
            <Heading fontSize="xl" textAlign="center">
              Woohoo! All steps completed!
            </Heading>
            <Button mx="auto" mt={6} size="sm" onClick={resetNotification}>
              Reset
            </Button>
          </Flex>
        ) : (
          <Flex width="100%" flexDir={"row"} justify="space-between" pt={5}>
            <Flex>
              <Button size="sm" onClick={resetNotification}>
                Reset
              </Button>
            </Flex>
            <Flex flexDir={"row"}>
              <Button
                isDisabled={activeStep === 0}
                mr={4}
                onClick={prevStep}
                size="sm"
                variant="ghost"
              >
                Prev
              </Button>
              <Button
                size="sm"
                onClick={handleProcess}
                isLoading={resLoading}
                loadingText={"Sending"}
                isDisabled={processDone || resLoading}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
