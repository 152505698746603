import React, { useRef, useMemo, useState } from "react";
import { MdReplay, MdOutlineEditNote } from "react-icons/md";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogContent,
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  SimpleGrid,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useStyleConfig,
  useToast,
} from "@chakra-ui/react";
import IconBox from "../../../components/icons/IconBox";
import { Player } from "@lottiefiles/react-lottie-player";
import RedirectCard from "../../../components/card/RedirectCard";
import { useGetUsers, useGetUsersCount } from "../../../lib/hooks";
import { MainTable } from "./components/MainTable";
import moment from "moment";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import nationalities from "./nationality.json";

function Main() {
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(10);
  const [searchText, setSearchText] = useState("");

  const [updateBtnEnable, setUpdateBtnEnable] = useState(false);

  const [editFirstName, setEditFirstName] = useState("");
  const [editLastName, setEditLastName] = useState("");
  const [editNationality, setEditNationality] = useState("");
  const [editEmpID, setEditEmpID] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editGender, setEditGender] = useState("");
  const [editRoom, setEditRoom] = useState("");
  const [editPosition, setEditPosition] = useState("");
  const [editDepartment, setEditDepartment] = useState("");
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const { usersData, isError, isLoading } = useGetUsers(
    page,
    per_page,
    searchText
  );
  const { user } = useAuth0();
  const userRole = user?.["https://admin.b2connect.me/roles"][0];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCell, setSelectedCell] = useState({});
  const {
    isOpen: editOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const textColor = useColorModeValue("secondaryGrey.700", "white");
  const [resetErr, setResetErr] = useState(false);

  const {
    isOpen: isOpenReset,
    onOpen: onOpenReset,
    onClose: onCloseReset,
  } = useDisclosure();

  const userData =
    !isError && !isLoading && usersData
      ? usersData?.body?.users?.map((item) => ({
          contactNumber: item.contactNumber,
          firstName: item.firstName,
          lastName: item.lastName,
          nationality: item.nationality,
          gender: item.gender,
          email: item.email,
          room: item.room,
          property: item.property,
          empId: item.empId,
          position: item.position,
          department: item.department,
          createdTime: moment(item.createdTime * 1000)
            .utcOffset("+0400")
            .format("LLL"),
          lastModifiedTime: moment(item.lastModifiedTime * 1000)
            .utcOffset("+0400")
            .format("LLL"),
        }))
      : [];

  const usersTotalCount =
    !isError && !isLoading && usersData ? usersData?.body?.total : 0;
  const data = React.useMemo(() => userData, [usersData]);

  const notificationsType = [
    {
      id: 1,
      name: "Onboard a Single User",
      icon: "https://assets1.lottiefiles.com/datafiles/6deVuMSwjYosId3/data.json",
      description: "On-Board a user",
      button: "Start",
      path: "/dashboard/boarding-single",
    },

    {
      id: 2,
      name: "Onboard Multiple Users (Bulk)",
      icon: "https://assets3.lottiefiles.com/packages/lf20_yxrxjnkt.json",
      description: "On-Board a batch of users",
      button: "Coming Soon",
      path: "/dashboard/boarding-batch-soon", //Should be boarding-batch
    },
    {
      id: 3,
      name: "Off-Board",
      icon: "https://assets6.lottiefiles.com/private_files/lf30_aprp5fnm.json",
      description: "Off-Board users",
      button: "Coming Soon",
      path: "/dashboard/offboarding",
    },
  ];

  const columns = useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "Employee Id",
            accessor: "empId",
          },
          {
            Header: "First Name",
            accessor: "firstName",
          },
          {
            Header: "Last Name",
            accessor: "lastName",
          },
          {
            Header: "Contact Number",
            accessor: "contactNumber",
          },
          {
            Header: "Nationality",
            accessor: "nationality",
          },
          {
            Header: "Room",
            accessor: "room",
          },
          {
            Header: "Property",
            accessor: "property",
          },
          {
            Header: "Position",
            accessor: "position",
          },
          {
            Header: "Department",
            accessor: "department",
          },
        ],
      },
    ],
    []
  );

  const handleDrawer = (data) => {
    setSelectedCell(data);
    onOpen();
  };

  const handleSearchText = (data) => {
    setSearchText(data);
    setPage(1);
  };

  let authToken = localStorage.getItem("auth0_token");
  const toast = useToast();

  const handleEditOpen = (e) => {
    setEditFirstName(e?.firstName);
    setEditLastName(e?.lastName);
    setEditNationality(e?.nationality);
    setEditEmail(e?.email);
    setEditEmpID(e?.empId);
    setEditGender(e?.gender);
    setEditRoom(e?.room);
    setEditPosition(e?.position);
    setEditDepartment(e?.department);
    setUpdateBtnEnable(false);
    onEditOpen();
  };

  const handleEditSubmit = async (e) => {
    setUpdateBtnEnable(true);
    let editSubmitBody = {
      login: e.contactNumber,
      firstName: editFirstName ? editFirstName : "",
      lastName: editLastName ? editLastName : null,
      nationality: editNationality ? editNationality : "",
      gender: editGender ? editGender : "",
      email: editEmail ? editEmail : "",
      room: editRoom ? editRoom : "",
      employeeId: editEmpID ? editEmpID : "",
      position: editPosition ? editPosition : "",
      department: editDepartment ? editDepartment : "",
    };
    Object.keys(editSubmitBody).forEach((key) => {
      if (editSubmitBody[key] === null || editSubmitBody[key] === "") {
        delete editSubmitBody[key];
      }
    });
    console.log(editSubmitBody);
    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/users/${e.contactNumber}`,
        editSubmitBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        setUpdateBtnEnable(false);
        if (!resetErr) {
          onCloseReset();
          toast({
            title: "Updated successfully.",
            description: "You've just updated the details of the user.",
            status: "success",
            duration: 3000,
            isClosable: true,
            onCloseComplete: () => {
              onEditClose();
              onClose();
            },
          });
        }
      })
      .catch((e) => {
        setResetErr(true);
        onCloseReset();
        toast({
          title: "Something went wrong .",
          description: "Please try again or contact the admin.",
          status: "error",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onEditClose();
            onClose();
          },
        });
      });
  };

  const resetPassword = async (e) => {
    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/users/reset-password/${e.contactNumber}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        if (!resetErr) {
          onCloseReset();
          toast({
            title: "Updated successfully.",
            description: "You've just reset the password of the user.",
            status: "success",
            duration: 3000,
            isClosable: true,
            onCloseComplete: () => {
              onCloseReset();
              onClose();
            },
          });
        }
      })
      .catch((e) => {
        setResetErr(true);
        onCloseReset();
        toast({
          title: "Something went wrong .",
          description: "Please try again or contact the admin.",
          status: "error",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onCloseReset();
            onClose();
          },
        });
      });
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        {notificationsType.map((card, i) => (
          <>
            {card?.id == 2 ? (
              <RedirectCard
                key={i}
                startContent={
                  <IconBox
                    w="60px"
                    h="60px"
                    bg={boxBg}
                    icon={
                      <Player
                        autoplay
                        loop
                        src={card.icon}
                        style={{ height: "120px", width: "120px" }}
                      ></Player>
                    }
                  />
                }
                name={card.name}
                description={card.description}
                value={card.button}
                path={card.path}
                targetBlank={false}
              />
            ) : (
              <RedirectCard
                startContent={
                  <IconBox
                    w="60px"
                    h="60px"
                    bg={boxBg}
                    icon={
                      <Player
                        autoplay
                        loop
                        src={card.icon}
                        style={{ height: "70px", width: "70px" }}
                      ></Player>
                    }
                  />
                }
                name={card.name}
                description={card.description}
                value={card.button}
                targetBlank={false}
                path={card.path}
              />
            )}
          </>
        ))}
      </SimpleGrid>
      <Drawer onClose={onClose} isOpen={isOpen} size={"md"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton mt={3} />
          <Flex flexDir={"row"}>
            <DrawerHeader mt={1}>{selectedCell.contactNumber}</DrawerHeader>
            <Button
              leftIcon={<MdOutlineEditNote />}
              p={2}
              ml={4}
              mt={4}
              borderRadius="10px"
              variant="outline"
              onClick={() => handleEditOpen(selectedCell)}
              colorScheme="blue"
            >
              {"Edit"}
            </Button>
            <Flex>
              {!userRole.includes("Corporate") && (
                <Button
                  leftIcon={<MdReplay />}
                  p={2}
                  ml={3}
                  mt={4}
                  borderRadius="10px"
                  variant="outline"
                  onClick={onOpenReset}
                  colorScheme="orange"
                >
                  {"Reset Password"}
                </Button>
              )}
              <AlertDialog isOpen={isOpenReset} onClose={onCloseReset}>
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      {"Reset User Password"}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      Are you sure? You can't undo this action afterwards.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <Button
                        colorScheme="red"
                        onClick={() => resetPassword(selectedCell)}
                      >
                        {"Reset"}
                      </Button>
                      <Button ml={3} onClick={onCloseReset}>
                        Cancel
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </Flex>
          </Flex>
          <DrawerBody>
            <Flex>
              <FormControl>
                <Stack spacing={3}>
                  <FormLabel htmlFor="contactNumber">Contact Number</FormLabel>
                  <Input
                    disabled={true}
                    id="contactNumber"
                    type="text"
                    value={selectedCell.contactNumber}
                    color={textColor}
                  />
                  <FormLabel htmlFor="name">Name</FormLabel>
                  <Input
                    disabled={true}
                    id="name"
                    type="text"
                    value={`${selectedCell.firstName} ${selectedCell.lastName}`}
                    color={textColor}
                  />

                  <FormLabel htmlFor="nationality">Nationality</FormLabel>
                  <Input
                    disabled={true}
                    id="nationality"
                    type="text"
                    value={selectedCell.nationality}
                    color={textColor}
                  />
                  <FormLabel htmlFor="room">Room</FormLabel>
                  <Input
                    disabled={true}
                    id="room"
                    type="text"
                    value={selectedCell.room}
                    color={textColor}
                  />
                  <FormLabel htmlFor="property">Property</FormLabel>
                  <Input
                    disabled={true}
                    id="property"
                    type="text"
                    value={selectedCell.property}
                    color={textColor}
                  />
                  <FormLabel htmlFor="empId">Employee Id</FormLabel>
                  <Input
                    disabled={true}
                    id="empId"
                    type="text"
                    value={selectedCell.empId}
                    color={textColor}
                  />
                  <FormLabel htmlFor="position">Position</FormLabel>
                  <Input
                    disabled={true}
                    id="position"
                    type="text"
                    value={selectedCell.position}
                    color={textColor}
                  />
                  <FormLabel htmlFor="department">Department</FormLabel>
                  <Input
                    disabled={true}
                    id="department"
                    type="text"
                    value={selectedCell.department}
                    color={textColor}
                  />
                  <FormLabel htmlFor="createdTime">Created Time</FormLabel>
                  <Input
                    disabled={true}
                    id="createdTime"
                    type="text"
                    value={selectedCell.createdTime}
                    color={textColor}
                  />
                  <FormLabel htmlFor="lastModifiedTime">
                    Last Modified Time
                  </FormLabel>
                  <Input
                    disabled={true}
                    id="lastModifiedTime"
                    type="text"
                    value={selectedCell.lastModifiedTime}
                    color={textColor}
                  />
                </Stack>
              </FormControl>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Flex>
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={editOpen}
          onClose={onEditClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>User Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Flex>
                <FormControl>
                  <FormLabel>First name</FormLabel>
                  <Input
                    color={textColor}
                    placeholder="First name"
                    defaultValue={selectedCell?.firstName}
                    onChange={(e) => setEditFirstName(e.target.value)}
                  />
                </FormControl>
                <FormControl ml={4}>
                  <FormLabel>Last name</FormLabel>
                  <Input
                    color={textColor}
                    placeholder="Last name"
                    defaultValue={selectedCell?.lastName}
                    onChange={(e) => setEditLastName(e.target.value)}
                  />
                </FormControl>
              </Flex>
              <Flex>
                <FormControl mt={4}>
                  <FormLabel>Employee ID</FormLabel>
                  <Input
                    color={textColor}
                    placeholder="Employee ID"
                    defaultValue={selectedCell?.empId}
                    onChange={(e) => setEditEmpID(e.target.value)}
                  />
                </FormControl>
                <FormControl mt={4} ml={4}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    color={textColor}
                    placeholder="Email"
                    defaultValue={selectedCell?.email}
                    onChange={(e) => setEditEmail(e.target.value)}
                  />
                </FormControl>
              </Flex>
              <Flex>
                <FormControl mt={4}>
                  <FormLabel>Gender</FormLabel>
                  <Select
                    placeholder={"Select Gender"}
                    onChange={(e) => setEditGender(e.target.value)}
                  >
                    <option selected={selectedCell.gender == "M"} value="M">
                      Male
                    </option>
                    <option selected={selectedCell.gender == "F"} value="F">
                      Female
                    </option>
                  </Select>
                </FormControl>
                <FormControl ml={4} mt={4}>
                  <FormLabel>Nationality</FormLabel>
                  <Select
                    color={textColor}
                    placeholder={"Nationality"}
                    onChange={(e) => setEditNationality(e.target.value)}
                  >
                    {nationalities.map((item, i) => (
                      <option
                        key={i}
                        value={item.name.toUpperCase()}
                        selected={
                          item.name.toUpperCase() === selectedCell?.nationality
                        }
                      >
                        {item.name.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>
              <Flex>
                <FormControl mt={4}>
                  <FormLabel>Room</FormLabel>
                  <Input
                    color={textColor}
                    placeholder="Room"
                    defaultValue={selectedCell?.room}
                    onChange={(e) => setEditRoom(e.target.value)}
                  />
                </FormControl>
                <FormControl mt={4} ml={4}>
                  <FormLabel>Position</FormLabel>
                  <Input
                    color={textColor}
                    placeholder="Position"
                    defaultValue={selectedCell?.position}
                    onChange={(e) => setEditPosition(e.target.value)}
                  />
                </FormControl>
              </Flex>
              <Flex>
                <FormControl mt={4}>
                  <FormLabel>Department</FormLabel>
                  <Input
                    color={textColor}
                    placeholder="Department"
                    defaultValue={selectedCell?.department}
                    onChange={(e) => setEditDepartment(e.target.value)}
                  />
                </FormControl>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button
                disabled={updateBtnEnable}
                colorScheme="pink"
                mr={3}
                onClick={() => handleEditSubmit(selectedCell)}
              >
                Update
              </Button>
              <Button onClick={onEditClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <MainTable
        loaded={!isLoading}
        data={data}
        columns={columns}
        setPage={setPage}
        setPerPage={setPer_page}
        searchText={searchText}
        handleSearchText={handleSearchText}
        currentpage={page}
        perPage={per_page}
        exports={userRole === "Admin" ? userData : false}
        totalPage={parseInt(usersData?.body?.pages)}
        drawer={true}
        handleDrawer={handleDrawer}
        name={`All Users (${numberWithCommas(usersTotalCount)})`}
      />
    </Box>
  );
}

export default Main;
