import React from "react";
import { usePagination, useTable } from "react-table";
import { CSVLink } from "react-csv";
import {
  Button,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import Card from "../card/Card";
import { MdCloudDownload, MdAddBox } from "react-icons/md";
import { Player } from "@lottiefiles/react-lottie-player";
import { useAuth0 } from "@auth0/auth0-react";
import IconBox from "../../../../../components/icons/IconBox";
import MiniStatistics from "../../../../../components/card/MiniStatistics";
import { Link } from "react-router-dom";

export function MainTable({
  setPerPage,
  exports,
  setPage,
  columns,
  data,
  currentpage,
  perPage,
  totalPage,
  name,
  drawer,
  totalRows,
  handleDrawer,
  tableName,
  loaded,
}) {
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.400");

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { user } = useAuth0();
  const userRole = user?.["https://admin.b2connect.me/roles"][0];
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: currentpage,
          }),
          [state, currentpage]
        );
      },
      initialState: { pageIndex: currentpage },
      manualPagination: true,
      pageCount: totalPage,
    },
    usePagination
  );

  return (
    <Skeleton isLoaded={loaded} fadeDuration={1.5} borderRadius={20}>
      <Card direction="column" w="100%" px="0px" overflowX={"scroll"}>
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            {name}
          </Text>
          <Flex px="25px" mb="20px" align="center">
            <Button
              onClick={onOpen}
              leftIcon={<MdAddBox />}
              colorScheme="pink"
              variant="solid"
              mx="5"
            >
              New Notification
            </Button>

            {data.length > 0 && exports && (
              <CSVLink filename={name + " " + Date()} data={exports}>
                <Flex flexDir={"row"}>
                  {" "}
                  <Icon
                    color="secondaryGray.600"
                    w="22px"
                    mr={2}
                    h="22px"
                    as={MdCloudDownload}
                  />{" "}
                  Export to CSV
                </Flex>{" "}
              </CSVLink>
            )}
          </Flex>
        </Flex>
        <Table {...getTableProps()}>
          {data.length > 0 ? (
            <>
              <Thead>
                {headerGroups.map((headerGroup, index) => (
                  <Tr key={index}>
                    {headerGroup.headers.map((column) => (
                      <Th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <Tr
                      {...row.getRowProps()}
                      cursor={drawer ? "pointer" : null}
                      _hover={
                        drawer ? { background: "rgba(81, 81, 81, 0.16)" } : null
                      }
                      key={i}
                      onClick={(e) =>
                        drawer
                          ? handleDrawer(row.cells[0]?.row?.original)
                          : null
                      }
                    >
                      {row.cells.map((cell, i) => {
                        return (
                          <Td
                            {...cell.getCellProps()}
                            fontSize={{ base: "md", md: "sm", xl: "sm" }}
                            key={i}
                          >
                            {cell.render("Cell")}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </>
          ) : (
            <Flex
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Player
                autoplay
                loop
                src="https://assets6.lottiefiles.com/packages/lf20_9lxy9vc3.json"
                style={{ height: "200px", width: "200px" }}
              ></Player>

              <Flex flexDir={"column"} justifyContent={"space-between"}>
                <Text fontSize={"lg"} my={3}>
                  No Records Yet
                </Text>
              </Flex>
            </Flex>
          )}
        </Table>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent justifyContent={"center"} pb={6}>
            <ModalHeader>Select Notification Type</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Link to={"/dashboard/notification/individual"}>
                <MiniStatistics
                  loaded={loaded}
                  color="black"
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Player
                          autoplay
                          loop
                          src="/lottiefiles/individual.json"
                          style={{ height: "120px", width: "120px" }}
                        ></Player>
                      }
                    />
                  }
                  name="Individual"
                />
              </Link>
              <Link to={"/dashboard/notification/group-new"}>
                <MiniStatistics
                  loaded={loaded}
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Player
                          autoplay
                          loop
                          src="/lottiefiles/group.json"
                          style={{ height: "80px", width: "80px" }}
                        ></Player>
                      }
                    />
                  }
                  name="Group"
                />
              </Link>
              {/* <Link to={"/dashboard/notification/filter"}>
                <MiniStatistics
                  loaded={loaded}
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Player
                          autoplay
                          loop
                          src="/lottiefiles/filter.json"
                          style={{ height: "80px", width: "80px" }}
                        ></Player>
                      }
                    />
                  }
                  name="Filter"
                />
              </Link> */}
            </ModalBody>
          </ModalContent>
        </Modal>
        {data.length > 0 ? (
          <>
            <Flex justifyContent="space-between" m={4} alignItems="center">
              <Flex>
                <Tooltip label="First Page">
                  <IconButton
                    onClick={() => {
                      setPage(1);
                    }}
                    isDisabled={currentpage === 1}
                    icon={<ArrowLeftIcon h={3} w={3} />}
                    mr={4}
                  />
                </Tooltip>
                <Tooltip label="Previous Page">
                  <IconButton
                    onClick={() => {
                      setPage((s) => (s === 0 ? 0 : s - 1));
                    }}
                    isDisabled={currentpage === 1}
                    icon={<ChevronLeftIcon h={6} w={6} />}
                  />
                </Tooltip>
              </Flex>

              <Flex alignItems="center">
                <Text flexShrink="0" mr={8}>
                  Page{" "}
                  <Text fontWeight="bold" as="span">
                    {pageIndex}
                  </Text>{" "}
                  of{" "}
                  <Text fontWeight="bold" as="span">
                    {totalPage || 1}
                  </Text>
                </Text>
                <Text flexShrink="0" color={textColor}>
                  Go to page:
                </Text>{" "}
                <NumberInput
                  ml={2}
                  mr={8}
                  w={28}
                  min={1}
                  max={totalPage}
                  onChange={(e) => {
                    const page = e ? Number(e) : 1;
                    setPage(page);
                  }}
                  color={textColor}
                  isDisabled={currentpage === totalPage || !totalPage}
                  defaultValue={pageIndex}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Select
                  w={32}
                  value={perPage}
                  onChange={(e) => {
                    setPerPage(Number(e.target.value));
                  }}
                >
                  {[5, 10, 20].map((pageSize, i) => (
                    <>
                      <option key={i} value={pageSize}>
                        Show {pageSize}
                      </option>
                    </>
                  ))}
                </Select>
              </Flex>

              <Flex>
                <Tooltip label="Next Page">
                  <IconButton
                    onClick={() => {
                      setPage((s) => s + 1);
                    }}
                    isDisabled={currentpage === totalPage || !totalPage}
                    icon={<ChevronRightIcon h={6} w={6} />}
                  />
                </Tooltip>
                <Tooltip label="Last Page">
                  <IconButton
                    onClick={() => {
                      setPage(totalPage);
                    }}
                    isDisabled={currentpage === totalPage || !totalPage}
                    icon={<ArrowRightIcon h={3} w={3} />}
                    ml={4}
                  />
                </Tooltip>
              </Flex>
            </Flex>
          </>
        ) : null}
      </Card>
    </Skeleton>
  );
}
