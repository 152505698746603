import {Redirect, Route, Router, Switch} from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import theme from "./theme/theme";
import history from "./utils/history";
import {ChakraProvider, useColorMode} from "@chakra-ui/react";
import loader from "./assets/img/Main/loader.gif";


const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  return !isLoading && isAuthenticated ? (
    children
  ) : (
    <Redirect to={"/auth/login"} />
  );
};

const App = () => {
    const [dataLoading, setDataLoading] = useState(false)
    const [mounted, setMounted] = useState(false);

    const { user, isAuthenticated, getAccessTokenSilently, isLoading, error } =
    useAuth0();
  const [authToken1, setAuthToken1] = useState(null);
  useColorMode();

  useEffect(() => {
      setDataLoading(true)
    if (user && isAuthenticated) {
      getAccessTokenSilently({ audience: "https://api.b2connect.me" }).then(
        (access_token) => {
          localStorage.setItem("auth0_token", access_token);
          setAuthToken1(access_token);
          setMounted(true)
        }
      );
    }
  }, [authToken1, isAuthenticated]);
    const userRole = user?.["https://admin.b2connect.me/roles"][0];
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading && !mounted) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img
          src={loader}
          alt={"loader"}
          style={{
            width: "300px",
          }}
        />
      </div>
    );
  }

  return (
      <>
         
          <Router history={history}>
              <ChakraProvider theme={theme}>
                  <Switch>
                      <Route path={`/auth`} component={AuthLayout} />
                      <Route
                          path="/dashboard"
                          render={() => (
                              <PrivateRoute>
                                  <AdminLayout />
                              </PrivateRoute>
                          )}
                      />

                      {userRole == "ProductManager" ? (
                          <>
                              <Redirect from="/" to="/dashboard/sales" />
                              <Redirect from="/dashboard" to="/dashboard/sales" />
                          </>

                      ) : (
                          <>
                              <Redirect from="/" to="/dashboard/main" />
                              <Redirect from="/dashboard" to="/dashboard/main" />
                          </>

                      )}
                  </Switch>
              </ChakraProvider>
          </Router>
      </>

  );
};

export default App;
