// Chakra imports
import { Box, SimpleGrid, useColorModeValue } from "@chakra-ui/react";

// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import millify from "millify";
import RecentNotification from "views/admin/default/components/RecentNotification";
import PieCard from "views/admin/default/components/PieCard";
import { columnsDataCheck } from "views/admin/default/variables/columnsData";
import { useStates, useUsers } from "../../../lib/hooks";
import { Player } from "@lottiefiles/react-lottie-player";
import { useAuth0 } from "@auth0/auth0-react";

export default function UserReports() {
  // Chakra Color Mode
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [mounted, setMounded] = useState(false);
  const { states, isLoading, isError } = useStates(mounted);
  const { user, isLoading: userLoading } = useAuth0();

  const userLocation = user?.["https://admin.b2connect.me/app_metadata"]
    ?.locations
    ? user?.["https://admin.b2connect.me/app_metadata"]?.locations
    : null;
  const { usersCount, usersError, usersLoading } = useUsers(userLocation);

  useEffect(() => {
    const token = localStorage.getItem("auth0_token");
    if (user && !userLoading && token) {
      setMounded(true);
    }
  });

  useEffect(() => {}, [usersCount, states]);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          loaded={!isLoading && !isError}
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Player
                  autoplay
                  loop
                  src="/lottiefiles/sms.json"
                  style={{ height: "60px", width: "60px" }}
                ></Player>
              }
            />
          }
          name="SMS"
          value={
            !isLoading && !isError
              ? millify(states?.body?.totalSMS, {
                  precision: 2,
                  decimalSeparator: ".",
                })
              : null
          }
        />

        <MiniStatistics
          loaded={!isLoading && !isError && !usersLoading && !userLoading}
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Player
                  autoplay
                  loop
                  src="/lottiefiles/push.json"
                  style={{ height: "60px", width: "60px" }}
                ></Player>
              }
            />
          }
          name="IN APP"
          value={
            !isLoading && !isError
              ? millify(states?.body?.totalPush, {
                  precision: 2,
                  decimalSeparator: ".",
                })
              : null
          }
        />
        <MiniStatistics
          loaded={!isLoading && !isError && !usersLoading && !userLoading}
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Player
                  autoplay
                  loop
                  src="/lottiefiles/survey.json"
                  style={{ height: "50px", width: "50px" }}
                ></Player>
              }
            />
          }
          name="SURVEY"
          value={
            !isLoading && !isError
              ? millify(states?.body?.totalSurvey, {
                  precision: 2,
                  decimalSeparator: ".",
                })
              : null
          }
        />

        <MiniStatistics
          loaded={!isLoading && !isError && !usersLoading && !userLoading}
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Player
                  autoplay
                  loop
                  src="/lottiefiles/announcment.json"
                  style={{ height: "50px", width: "50px" }}
                ></Player>
              }
            />
          }
          name="ANNOUNCEMENT"
          value={
            !isLoading && !isError
              ? millify(states?.body?.totalAnnouncement, {
                  precision: 2,
                  decimalSeparator: ".",
                })
              : null
          }
        />

        <MiniStatistics
          loaded={!isLoading && !isError && !usersLoading && !userLoading}
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Player
                  autoplay
                  loop
                  src="/lottiefiles/users.json"
                  style={{ height: "50px", width: "50px" }}
                ></Player>
              }
            />
          }
          name="USERS"
          value={
            !usersLoading && !usersError
              ? millify(usersCount?.body?.count, {
                  precision: 2,
                  decimalSeparator: ".",
                })
              : null
          }
        />
      </SimpleGrid>

      <SimpleGrid
        columns={{ base: 1, md: 2, xl: 2 }}
        gap="20px"
        mb="20px"
      ></SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <RecentNotification
          loaded={!isLoading && !isError}
          columnsData={columnsDataCheck}
          tableData={states?.body?.recentNotifications || []}
        />

        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px">
          <PieCard loaded={!isLoading && !isError} />
        </SimpleGrid>
      </SimpleGrid>
    </Box>
  );
}
