import {Step, Steps, useSteps} from "chakra-ui-steps";
import {
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Input,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  SimpleGrid,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, {useRef, useState} from "react";
import fetcher from "../../../../../lib/fetcher";
import {useGroups} from "../../../../../lib/hooks";
import {Player} from "@lottiefiles/react-lottie-player";

const steps = [
  {
    id: 1,
    label: "Group details",
    description: "Enter your group details",
  },
  { id: 2, label: "Preview", description: "Preview and create" },
];
const contactPattern = /(9715)\d{8}/g

export const createGroup = () => {
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });
  const boxBg = useColorModeValue("white", "whiteAlpha.100");
  const textColor = useColorModeValue("#1b254b", "white");
  const [audioAsset, setAudioAsset] = useState("");

  const [audio] = useState(new Audio(audioAsset));

  const [playing, setPlaying] = useState(false);
  const toggle = () => setPlaying(!playing);
  const {
    groups,
    isError: groupsError,
    isLoading: groupsLoading,
  } = useGroups();

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [groupCount, setGroupCount] = useState(0);

  const [error, setError] = useState(false);

  const [contactNumbersList, setContactNumbersList] = useState([])
  const [foundUsersCount, setFoundUsersCount] = useState(0);
  const [groupUsers, setGroupUsers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [groupDetail, setGroupDetail] = useState({});
  const [resLoading, setResLoading] = useState(false);
  const [processDone, setProcessDone] = useState(false);
  const [contactNumberCount, setContactNumberCount] = useState(0);

  const [title, setTitle] = useState("");
  const [type, setType] = useState("INFO");
  const [group, setGroup] = useState("");
  const nationalityRef = useRef("");
  const locationRef = useRef("");
  const activityRef = useRef("");
  const activityDaysRef = useRef(0);

  const isError = false;
  const toast = useToast();
  let usersCountObject = [
    { filterField: "CHANNEL", filterType: "EQUALS", text: "IN_APP" },
  ];



  const resetFunction = () => {
    
    setSubject("");
    setTitle("");
    setType("");
    nationalityRef.current = "";
    locationRef.current = "";
    activityRef.current = "";
    activityDaysRef.current = 0;
    setFoundUsersCount(0);
    setResLoading(false);
    setProcessDone(false);
    reset()
  };

  const handleProcess = async () => {
    if (activeStep === 0) {
      if (subject.length < 2 || contactNumbersList.length == 0) {
        setError(true);
      } else {
        nextStep();
        setError(false);
      }
    }
    if (activeStep === 1) {
      setResLoading(true);
      var createGroupBody = [];
      contactNumbersList.forEach(function(item) {
      var singleContact = {};
      singleContact['phone'] = item;
      createGroupBody.push(singleContact);
      });
      console.log(createGroupBody)
      fetcher("groups", {
          name: subject,
          users: createGroupBody
      })
        .then(async (res) => {
          if (res?.success) {
            setResLoading(false);
            setProcessDone(true);
            audio.src = "/sounds/mail_sent.mp3";
            await audio.play();
            toast({
              title: `Success`,
              description: `Group created successfully.`,
              status: "success",
              duration: 2000,
              isClosable: true,
              onCloseComplete: async () => {
                resetFunction();
                reset();
              },
            });
          } else {
            setResLoading(false);

            toast({
              title: "Something went wrong. " + res?.message,
              description: "Please try again or contact the admin.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        })
        .catch(async (e) => {
          setResLoading(false);

          audio.src = "/sounds/error.mp3";
          await audio.play();
          toast({
            title: "Something went wrong.",
            description: "Please try again or contact the admin.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  const handleContactNumbers = (e) => {
    let count = 0;
    let matches = e.target.value.match(contactPattern);
    if (matches !== null) {
      matches = [...new Set(matches)]
      count = matches.length
      setContactNumbersList(matches)
    } else {
      count = 0
      setContactNumbersList([])
    }
    setContactNumberCount(count)
    setMessage(e.target.value)
    //console.log(contactNumberCount)

  }
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Drawer onClose={onClose} isOpen={isOpen} size={"lg"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{`Group Users Phone Number`}</DrawerHeader>
          <DrawerBody>
            <nav>
              <ul style={{ listStyle: "none" }}>
                {groupUsers?.map((item, i) => (
                  <li key={i}>{item.phone}</li>
                ))}
              </ul>
            </nav>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Flex
        flexDir="column"
        width="100%"
        bg={boxBg}
        p={5}
        borderRadius={20}
        shadow={"sm"}
      >
        <Steps colorScheme="pink" activeStep={activeStep}>
          {steps.map(({ label, description, id }, index) => (
            <Step
              label={label}
              key={label}
              description={description}
              textColor={"#fff"}
            >
              {id == 1 ? (
                <Flex px={4} pt={8} width="100%">
                  <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} spacing={7}>
                    <Flex flexDir={"column"}>
                      <Heading fontSize="md">{label}</Heading>

                      <FormControl isInvalid={isError} width={"350px"}>
                        <FormLabel pt={3} htmlFor="subject">
                          Group Name
                        </FormLabel>
                        <Input
                          id="subject"
                          type="text"
                          color={textColor}
                          value={subject}
                          isInvalid={error}
                          onChange={(e) => setSubject(e.target.value)}

                        />
                        {!isError ? (
                          <FormHelperText>
                            Enter a group name.
                          </FormHelperText>
                        ) : (
                          <FormErrorMessage>
                            Group Name is required.
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Flex>
                    <Flex flexDir={"column"}>
                      <FormControl isInvalid={isError} width={"350px"} pt={2}>
                        <Flex>
                        <FormLabel htmlFor="message" pt={1}>Contact Numbers</FormLabel>
                        <Popover isLazy  placement='right'>
                                <PopoverTrigger>
                                  <IconButton size='sm' justifyContent={"center"} icon={
                                    <Player
                                        autoplay
                                        loop
                                        speed={0.1}
                                        src="https://assets7.lottiefiles.com/packages/lf20_Z8MOn3.json"
                                        style={{ height: "30px", width: "30px" }}
                                    ></Player>
                                  } />


                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverHeader fontWeight='semibold'>Contact Number Format</PopoverHeader>
                                  <PopoverArrow />
                                  <PopoverCloseButton />
                                  <PopoverBody>
                                    Format of contact numbers should be 971 5X XXXXXXX
                                  </PopoverBody>
                                </PopoverContent>
                              </Popover>
                              </Flex>
                        <Textarea
                          id={"message"}
                          isInvalid={error}
                          value={message}
                          size="sm"
                          wrap="off"
                          height={"300px"}
                          resize="both"
                          onChange={(e) => handleContactNumbers(e)}
                        />
                        {!isError ? (
                          <Flex
                            flexDir={"row"}
                            justifyContent={"space-between"}
                          >
                            <FormHelperText>
                              Enter contact numbers list.
                            </FormHelperText>
                            <FormHelperText>
                              {contactNumberCount}
                            </FormHelperText>
                          </Flex>
                        ) : (
                          <FormErrorMessage>
                            Contact numbers list is required.
                          </FormErrorMessage>
                        )}
                        {contactNumberCount >= 1 && (
                            <FormHelperText>

                              Contact Numbers Found:  <Badge ml={1}  colorScheme='red'>{contactNumberCount}</Badge>

                              
                            </FormHelperText>
                        )}

                      </FormControl>
                    </Flex>
                  </SimpleGrid>
                </Flex>
              ) : null}

              {id == 2 ? (
                <Flex px={4} pt={8} width="100%" flexDirection="column">
                  <Heading fontSize="md" mb="3">{"Group Name"}</Heading>
                  <Box boxShadow='md' py='3' px='8' mb="3" rounded='md' bg='white'  maxW={"fit-content"}>
                    {subject}
                  </Box>
                  <Flex my="3">
                    <Heading fontSize="md" >{"Group Users"}</Heading>
                    <Text ml="4">{contactNumbersList.length + " unique users"}</Text>
                  </Flex>
                  <Box boxShadow='md'  mb="3" rounded='md' bg='white'  maxW={"fit-content"}>
                    <List size={"lg"} focusBorderColor='pink.400' overflowY={'scroll'} spacing={2} pl={1} pt={3} width={"xs"} height={"300px"}>
                      {contactNumbersList.map((c) => <Box><ListItem>{c}</ListItem><Divider mt={1} /></Box>)}
                    </List>
                  </Box>
                </Flex>
              ) : null}
            </Step>
          ))}
        </Steps>
        {activeStep === steps.length ? (
          <Flex px={4} py={4} width="100%" flexDirection="column">
            <Heading fontSize="xl" textAlign="center">
              Woohoo! All steps completed!
            </Heading>
            <Button mx="auto" mt={6} size="sm" onClick={reset}>
              Reset
            </Button>
          </Flex>
        ) : (
            <Flex width="100%" flexDir={"row"} justify="space-between" pt={5}>



              <Flex>
                <Button   size="sm" onClick={resetFunction}>
                  Reset
                </Button>
              </Flex>
              <Flex flexDir={"row"}>
                <Button
                    isDisabled={activeStep === 0}
                    mr={4}
                    onClick={prevStep}
                    size="sm"
                    variant="ghost"
                >
                  Prev
                </Button>
                <Button size="sm" onClick={handleProcess} isLoading={resLoading} loadingText={"Sending"} isDisabled={processDone || resLoading}>
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Flex>


            </Flex>
        )}
      </Flex>
    </Box>
  );
};
