import {Step, Steps, useSteps} from "chakra-ui-steps";
import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Select,
  SimpleGrid,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";
import fetcher from "../../../../../lib/fetcher";
import {useDepartment, useJobRoles, useLocations} from "../../../../../lib/hooks";
import {useAuth0} from "@auth0/auth0-react";
import {count} from "sms-length";
import {Player} from "@lottiefiles/react-lottie-player";

const steps = [
  {
    id: 1,
    label: "Notification details",
    description: "Enter notification details",
  },
  {
    id: 2,
    label: "Filters options",
    description: "Enter your preferred filters",
  },
  { id: 3, label: "Preview", description: "Preview and send" },
];

export const FilterApp = () => {
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });
  const boxBg = useColorModeValue("white", "whiteAlpha.100");
  const textColor = useColorModeValue("#1b254b", "white");
  const [audio] = useState(new Audio("/sounds/mail_sent.mp3"));
  const [playing, setPlaying] = useState(false);
  const toggle = () => setPlaying(!playing);
  const { user } = useAuth0();




  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const { locations, isLoading, isError: LocationError } = useLocations(true);
  const { job_roles, isError: JobRolesError } = useJobRoles(true);
  const { department, isError: departmentError } = useDepartment(true);

  const [error, setError] = useState(false);

  const [activity, setActivity] = useState("");

  const [foundUsersCount, setFoundUsersCount] = useState(0);

  const [activityDays, setActivityDays] = useState("");

  const [groupName, setGroupName] = useState("");

  const [groupNameError, setGroupNameError] = useState(false);
  const [smsMessageObject, setSmsMessageObject] = useState({});

  const [createdGroupId, setCreatedGroupId] = useState(null);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("INFO");
  const [resLoading, setResLoading] = useState(false);
  const [processDone, setProcessDone] = useState(false);
  const [groupCreationLoading, setGroupCreationLoading] = useState(false);

  const nationalityRef = useRef("");
  const departmentRef = useRef("");
  const jobRoleRef = useRef("");

  const locationRef = useRef("");
  const activityRef = useRef("");
  const activityDaysRef = useRef(0);
  const isError = false;
  const toast = useToast();

  let usersCountObject = [
    { filterField: "CHANNEL", filterType: "EQUALS", text: "IN_APP" },
  ];

  useEffect(() => {
    usersCountObject = [
      { filterField: "CHANNEL", filterType: "EQUALS", text: "IN_APP" },
    ];

    fetcher("users/count", {
      userFilterItems: usersCountObject || [],
    })
        .then((res) => {
          setFoundUsersCount(res?.body?.count);
        })
        .catch((e) => {
          // console.log(e);
        });
  }, [])


  const userRole = user?.["https://admin.b2connect.me/roles"][0];

  const resetNotification = () => {
    setSubject("");
    setMessage("");
    setTitle("");
    setFoundUsersCount(0);
    resetFunction();
    reset();
  }
  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);
  const countUsersFetcher = () => {
    usersCountObject = [
      { filterField: "CHANNEL", filterType: "EQUALS", text: "IN_APP" },
    ];

    if (locationRef.current.length > 0 && locationRef.current !== "ALL") {
      usersCountObject.push({
        filterField: "LOCATION",
        filterType: "EQUALS",
        text: locationRef.current,
      });
    }
    //console.log(locationRef.current)
    if(nationalityRef.current) {
      usersCountObject.push({
        filterField: "NATIONALITY",
        filterType: "CONTAINS",
        text: nationalityRef.current,
      });
    }
    if(departmentRef.current) {
      usersCountObject.push({
        filterField: "DEPARTMENT",
        filterType: "CONTAINS",
        text: departmentRef.current,
      });
    }
    if(jobRoleRef.current) {
      usersCountObject.push({
        filterField: "JOB_ROLE",
        filterType: "CONTAINS",
        text: jobRoleRef.current,
      });
    }
    if (activityRef.current) {

      if(activityRef.current == "no_filter") {
        const index = usersCountObject.findIndex(function(o){
          return o.filterType === 'no_filter';
        })
        if (index !== -1) usersCountObject.splice(index, 1);
      }else{
        usersCountObject.push({
          filterField: "ACTIVITY",
          filterType: activityRef.current,
          text: activityDaysRef.current,
        });
      }


    }

    if (locationRef || activityRef || activityDaysRef || nationalityRef || departmentRef || jobRoleRef) {
      fetcher("users/count", {
        userFilterItems: usersCountObject || [],
      })
        .then((res) => {
          setFoundUsersCount(res?.body?.count);
        })
        .catch((e) => {
          toast({
            title: "Something went wrong.",
            description: e?.response?.data?.message,
            status: "error",
            duration: 1000,

          });
        });
    }
  };

  const CreateGroup = () => {
    let filter = [];


    if (nationalityRef.current) {
      filter.push({
        filterField: "NATIONALITY",
        filterType: "CONTAINS",
        text: nationalityRef.current,
      });
    }


    //console.log(locationRef.current)
    if (locationRef.current &&  locationRef.current !== "ALL") {
      filter.push({
        filterField: "LOCATION",
        filterType: "EQUALS",
        text: locationRef.current,
      });
    }
    if (activityRef.current) {
      filter.push({
        filterField: "ACTIVITY",
        filterType: activityRef.current,
        text: activityDaysRef.current,
      });
    }

    if (filter.length > 0) {
      setGroupCreationLoading(true)
      fetcher("groups/by-filter", {
        name: groupName,
        userFilter: {
          userFilterItems: filter,
        },
      })
        .then((res) => {
          setGroupCreationLoading(false)

          if (res.success == true && res.body?.groupId) {

            setCreatedGroupId(res.body.groupId);
            toast({
              title: "Group created.",
              description: "You've just created a new group.",
              status: "success",
              duration: 4000,
              isClosable: true,
              onCloseComplete: async () => {
                nextStep();
              },
            });
          }
        })
        .catch((e) => {

          setGroupCreationLoading(false)

            toast({
              title: "Something went wrong.",
              description: e?.response?.data?.message || "Please contact the admin",
              status: "error",
              duration: 4000,
              isClosable: true,

            });

        });
    }else{
      toast({
        title: "Please select a valid filter.",
        description: "To be able to create group you should have valid filters, users count more than 0",
        status: "error",
        duration: 4000,
        isClosable: true,

      });
    }

  };

  const resetFunction = () => {
    setSubject("");
    setTitle("");
    setMessage("");
    setType("");
    nationalityRef.current = "";
    departmentRef.current = "";
    jobRoleRef.current = "";
    locationRef.current = "";
    activityRef.current = "";
    activityDaysRef.current = 0;
    setFoundUsersCount(0);
  };

  const handleProcess = async () => {
    if (activeStep === 0) {
      if (subject.length < 2 || message.length === 0) {
        setError(true);
      } else {
        nextStep();
        setError(false);
      }
    }
    if (activeStep === 1) {
      if(foundUsersCount == 0) {
        audio.src = "/sounds/error.mp3";
        await audio.play();
        toast({
          title: "Minimum 1 user in the group.",
          description: "The group should have minimum 1 user.",
          status: "error",
          duration: 2000,
          isClosable: true,
         
        });
      }else{
        nextStep();
        setError(false);
      }
    }
    if (activeStep === 2) {
      setResLoading(true);

      let NotificationPayload = [
        { filterField: "CHANNEL", filterType: "EQUALS", text: "IN_APP" },
      ];
      if (locationRef.current.length && locationRef.current !== "ALL") {
        NotificationPayload.push({
          filterField: "LOCATION",
          filterType: "EQUALS",
          text: locationRef.current,
        });
      }
      if (nationalityRef.current) {
        NotificationPayload.push({
          filterField: "NATIONALITY",
          filterType: "CONTAINS",
          text: nationalityRef.current,
        });
      }
      if (activityRef.current) {
        NotificationPayload.push({
          filterField: "ACTIVITY",
          filterType: activityRef.current,
          text: activityDaysRef.current,
        });
      }
      fetcher("notification/filter", {
        notificationBody: {
          name: subject,
          channels: ["IN_APP"],
          message: message,
          title: subject,
          level: type,
        },
        userFilter: {
          userFilterItems: NotificationPayload || [],
        },
      })
        .then(async (res) => {
          if (res.success) {
            setResLoading(false);
            setProcessDone(true);
            audio.src = "/sounds/mail_sent.mp3";
            await audio.play();
            toast({
              title: `Notification sent successfully`,
              description: `Notification has been sent to ${foundUsersCount} users.`,
              status: "success",
              duration: 2000,
              isClosable: true,
              onCloseComplete: async () => {
                toggle();
                resetFunction();
                reset();
              },
            });
          } else {
            setResLoading(false);

            toast({
              title: "Something went wrong .",
              description: "Please try again or contact the admin.",
              status: "failed",
              duration: 3000,
              isClosable: true,
            });
          }
        })
        .catch(async (e) => {
          setResLoading(false);

          audio.src = "/sounds/error.mp3";
          await audio.play();
          toast({
            title: "Something went wrong.",
            description: e?.response?.data?.message || "Please contact the admin",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };
  const handleNationality = (event) => {
    nationalityRef.current = event.target.value;
    countUsersFetcher();
  };

  const handleLocation = (event) => {
    locationRef.current = event.target.value;
    countUsersFetcher();
  };

  const handleActivity = (event) => {

    if (event.target.value == 5) {
      activityRef.current = "no_filter";
      activityDaysRef.current = 99;
    }

    if (event.target.value == 1) {
      activityRef.current = "ACTIVE_DURING_DAYS";
      activityDaysRef.current = 30;
    }
    if (event.target.value == 2) {
      activityRef.current = "ACTIVE_DURING_DAYS";
      activityDaysRef.current = 90;
    }
    if (event.target.value == 3) {
      activityRef.current = "INACTIVE_DURING_DAYS";
      activityDaysRef.current = 30;
    }
    if (event.target.value == 4) {
      activityRef.current = "INACTIVE_DURING_DAYS";
      activityDaysRef.current = 90;
    }
    if (activityDaysRef.current) {
      countUsersFetcher();
    }
  };

  const handleDepartment = (event) => {

    departmentRef.current = event.target.value;
    countUsersFetcher();
  };
  const handleJobRole = (e) => {
    jobRoleRef.current = e.target.value;
    countUsersFetcher();
  }
  const handleMessage = (e) => {
      setSmsMessageObject(count(e.target.value))
      setMessage(e.target.value)
    //console.log(smsMessageObject)

  }


  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Flex
        flexDir="column"
        width="100%"
        bg={boxBg}
        p={5}
        borderRadius={20}
        shadow={"sm"}
      >
        <Steps colorScheme="pink" activeStep={activeStep}>
          {steps.map(({ label, description, id }, index) => (
            <Step
              label={label}
              key={label}
              description={description}
              textColor={"#fff"}
            >
              {id == 1 ? (
                <Flex px={4} pt={8} width="100%" >

                    <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} spacing={5}>

                      <Flex flexDir={"column"}>
                        <Heading fontSize="md">{label}</Heading>

                        <FormControl isInvalid={isError} width={"350px"}>
                          <FormLabel pt={3} htmlFor="subject">
                            Subject
                          </FormLabel>
                          <Input
                              id="subject"
                              type="text"
                              color={textColor}
                              value={subject}
                              isInvalid={error}
                              onChange={(e) => setSubject(e.target.value)}
                          />
                          {!isError ? (
                              <FormHelperText>
                                Enter a notification subject.
                              </FormHelperText>
                          ) : (
                              <FormErrorMessage>
                                Subject is required.
                              </FormErrorMessage>
                          )}
                        </FormControl>
                      </Flex>
                      <Flex flexDir={"column"} pt={3}>
                        <FormControl isInvalid={isError} width={"350px"} pt={2}>
                          <FormLabel htmlFor="message">Message</FormLabel>
                          <Textarea
                              id={"message"}
                              isInvalid={error}
                              value={message}
                              size="sm"
                              onChange={(e) => handleMessage(e) }
                          />
                          {!isError ? (
                              <Flex
                                  flexDir={"row"}
                                  justifyContent={"space-between"}
                              >
                                <FormHelperText>
                                  Enter the notification message.
                                </FormHelperText>
                                <FormHelperText>
                                  {smsMessageObject.length}
                                </FormHelperText>



                              </Flex>
                          ) : (
                              <FormErrorMessage>
                                Message is required.
                              </FormErrorMessage>
                          )}
                          {smsMessageObject?.messages > 1 && (
                              <FormHelperText>

                               SMS Count  <Badge ml={1}  colorScheme='red'>{smsMessageObject?.messages ? smsMessageObject?.messages : 1}</Badge>

                                <Popover isLazy  placement='right'>
                                  <PopoverTrigger>
                                    <IconButton size='sm' justifyContent={"center"} pb={1} icon={
                                      <Player
                                          autoplay
                                          loop
                                          speed={0.1}
                                          src="https://assets7.lottiefiles.com/packages/lf20_Z8MOn3.json"
                                          style={{ height: "35px", width: "35px" }}
                                      ></Player>
                                    } />


                                  </PopoverTrigger>
                                  <PopoverContent>
                                    <PopoverHeader fontWeight='semibold'>B2Connect SMS Counter</PopoverHeader>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverBody>
                                      Seems, the entered sms characters exceeded and reached {smsMessageObject.messages} SMS - It may incur an additional cost & count!
                                    </PopoverBody>
                                  </PopoverContent>
                                </Popover>
                              </FormHelperText>
                          )}

                        </FormControl>
                      </Flex>
                    </SimpleGrid>



                </Flex>
              ) : null}

              {id == 2 ? (
                <SimpleGrid
                  columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }}
                  gap="10px"
                  mb="20px"
                >
                  <Stack spacing={4}>
                    <Flex px={4} pt={8} width="100%" flexDirection="column">
                      <Heading fontSize="md">{label}</Heading>
                      <Stack spacing={4} mt={4}>


                        <Flex flexDir={"column"}>
                          <FormLabel htmlFor="location">
                            Location
                            <span style={{ color: "gray", fontSize: "14px" }}>
                              (optional)
                            </span>
                          </FormLabel>
                          <Select
                            id={"location"}
                            placeholder="Filter by location"
                            background={boxBg}
                            onChange={(e) => handleLocation(e)}
                          >
                            {userRole !== "Corporate User" && (
                                <option value={"ALL"} selected>All</option>
                            )}
                            {!isLoading && !isError && locations
                              ? locations?.body?.locations?.map((item, i) => (
                                  <option key={i} value={item}>{item}</option>
                                ))
                              : null}
                          </Select>
                        </Flex>
                        <Flex flexDir={"column"}>
                          <FormLabel htmlFor="nationality">
                            Nationality{" "}
                            <span style={{ color: "gray", fontSize: "14px" }}>
                              (optional)
                            </span>
                          </FormLabel>
                          <Select
                              id={"nationality"}
                              placeholder="Select Nationality"
                              background={boxBg}
                              onChange={(e) => handleNationality(e)}
                          >
                            <option value="INDIA">INDIA</option>
                            <option value="PAKISTAN">PAKISTAN</option>
                            <option value="NEPAL">NEPAL</option>
                            <option value="BANGLADESH">BANGLADESH</option>
                          </Select>
                        </Flex>
                        <Flex flexDir={"column"}>
                          <FormLabel htmlFor="activity">
                            Activity{" "}
                            <span style={{ color: "gray", fontSize: "14px" }}>
                              (optional)
                            </span>
                          </FormLabel>
                          <Select
                            id={"activity"}

                            background={boxBg}
                            onChange={(e) => handleActivity(e)}
                          >
                            <option value={5} selected={true}>
                              No Filter
                            </option>
                            <option value={1}>
                              User visited within a 30 days
                            </option>
                            <option value={2}>
                              User visited within 6 months
                            </option>
                            <option value={3}>
                              User not visited within a 30 days
                            </option>
                            <option value={4}>
                              User not visited within 6 months
                            </option>
                          </Select>
                        </Flex>
                        {userRole != "Corporate User" && (
                            <>
                              <Flex
                                  flexDir={"row"}
                                  justifyContent={"space-between"}
                                  mt={15}
                                  background={"white"}
                                  borderRadius={5}
                                  p={2}
                              >
                                <Flex>
                                  <Box>
                                    <Text color={"black"}>
                                      Found: {foundUsersCount} User
                                    </Text>
                                  </Box>
                                </Flex>
                                <Flex>
                                  {foundUsersCount > 0 && (
                                      <Box>
                                        <Popover>
                                          <PopoverTrigger>
                                            <Button size={"xs"} colorScheme={"pink"}>
                                              Create Group
                                            </Button>
                                          </PopoverTrigger>
                                          <Portal>
                                            <PopoverContent>
                                              <PopoverArrow />
                                              <PopoverHeader>Group Name</PopoverHeader>
                                              <PopoverCloseButton />
                                              <PopoverBody>
                                                <Input
                                                    type={"text"}
                                                    placeholder={"Group name"}
                                                    value={groupName}
                                                    onChange={(e) =>
                                                        setGroupName(e.target.value)
                                                    }
                                                    color={textColor}
                                                    isInvalid={groupNameError}
                                                />
                                              </PopoverBody>
                                              <PopoverFooter>
                                                <Button
                                                    onClick={() => {
                                                      if (groupName) {
                                                        CreateGroup();
                                                      } else {
                                                        setGroupNameError(true);
                                                      }
                                                    }}
                                                    isLoading={groupCreationLoading}
                                                    size={"xs"}
                                                    color={"black"}
                                                >
                                                  Confirm
                                                </Button>
                                              </PopoverFooter>
                                            </PopoverContent>
                                          </Portal>
                                        </Popover>
                                      </Box>
                                  )}
                                </Flex>
                              </Flex>
                            </>
                        )}


                      </Stack>

                    </Flex>
                  </Stack>

                  {userRole === "Corporate User"  && (
                      <Stack style={{marginTop: 66}}  px={4} spacing={4}>
                        <Flex flexDir={"column"}  width="100%" >
                          <FormLabel htmlFor="activity">
                            Department{" "}
                            <span style={{ color: "gray", fontSize: "14px" }}>
                              (optional)
                            </span>
                          </FormLabel>
                          <Select
                              id={"department"}
                              placeholder="Filter by department"
                              background={boxBg}
                              onChange={(e) => handleDepartment(e)}
                          >
                            {!isLoading && !departmentError && department
                                ? department?.body?.map((item, i) => (
                                    <option key={i} value={item}>{item}</option>
                                ))
                                : null}
                          </Select>
                        </Flex>
                        <Flex flexDir={"column"}  width="100%" >
                          <FormLabel htmlFor="activity">
                            Job Role{" "}
                            <span style={{ color: "gray", fontSize: "14px" }}>
                              (optional)
                            </span>
                          </FormLabel>
                          <Select
                              id={"job_role"}
                              placeholder="Filter by job role"
                              background={boxBg}
                              onChange={(e) => handleJobRole(e)}
                          >

                            {!isLoading && !JobRolesError && job_roles
                                ? job_roles?.body?.map((item, i) => (
                                    <option key={i} value={item}>{item}</option>
                                ))
                                : null}
                          </Select>
                        </Flex>
                        <Flex
                            flexDir={"row"}
                            justifyContent={"space-between"}
                            mt={15}
                            background={"white"}
                            borderRadius={5}
                            p={2}
                        >
                          <Flex>
                            <Box>
                              <Text color={"black"}>
                                Found: {foundUsersCount} User
                              </Text>
                            </Box>
                          </Flex>

                            {foundUsersCount > 0 && (
                                <Flex>
                                <Box>
                                  <Popover>
                                    <PopoverTrigger>
                                      <Button size={"xs"} colorScheme={"pink"}>
                                        Create Group
                                      </Button>
                                    </PopoverTrigger>
                                    <Portal>
                                      <PopoverContent>
                                        <PopoverArrow />
                                        <PopoverHeader>Group Name</PopoverHeader>
                                        <PopoverCloseButton />
                                        <PopoverBody>
                                          <Input
                                              type={"text"}
                                              placeholder={"Group name"}
                                              value={groupName}
                                              onChange={(e) =>
                                                  setGroupName(e.target.value)
                                              }
                                              isInvalid={groupNameError}
                                          />
                                        </PopoverBody>
                                        <PopoverFooter>
                                          <Button
                                              onClick={() => {
                                                if (groupName) {
                                                  CreateGroup();
                                                } else {
                                                  setGroupNameError(true);
                                                }
                                              }}
                                              size={"xs"}
                                              color={"black"}
                                          >
                                            Confirm
                                          </Button>
                                        </PopoverFooter>
                                      </PopoverContent>
                                    </Portal>
                                  </Popover>
                                </Box>
                              </Flex>
                            )}

                        </Flex>
                      </Stack>

                  ) }
                  {/*Corprate filters */}
                </SimpleGrid>
              ) : null}

              {id == 3 ? (
                <Flex px={4} pt={8} width="100%" flexDirection="column">
                  <Heading fontSize="md">{label}</Heading>
                  <TableContainer>
                    <Table variant="simple">
                      <TableCaption></TableCaption>
                      <Thead>
                        <Tr>
                          <Th>Subject</Th>
                          <Th>Message</Th>
                          <Th>Found Users</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>{subject}</Td>
                          <Td>{message}</Td>
                          <Td>{foundUsersCount} Users</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
              ) : null}
            </Step>
          ))}
        </Steps>
        {activeStep === steps.length ? (
          <Flex px={4} py={4} width="100%" flexDirection="column">
            <Heading fontSize="xl" textAlign="center">
              Woohoo! All steps completed!
            </Heading>
            <Button mx="auto" mt={6} size="sm" onClick={reset}>
              Reset
            </Button>
          </Flex>
        ) : (
            <Flex width="100%" flexDir={"row"} justify="space-between" pt={5}>



              <Flex>
                <Button   size="sm" onClick={resetNotification} isDisabled={resLoading}>
                  Reset
                </Button>
              </Flex>
              <Flex flexDir={"row"}>
                <Button
                    isDisabled={activeStep === 0 || resLoading}
                    mr={4}
                    onClick={prevStep}
                    size="sm"
                    variant="ghost"
                >
                  Prev
                </Button>
                <Button size="sm" onClick={handleProcess} isLoading={resLoading} loadingText={"Sending"} isDisabled={processDone || resLoading}>
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Flex>


            </Flex>
        )}
      </Flex>
    </Box>
  );
};
