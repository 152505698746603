import React, { useState } from "react";

// Chakra imports
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Stack,
  Textarea,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

// Custom Components
import IconBox from "../../../components/icons/IconBox";
import { Player } from "@lottiefiles/react-lottie-player";
import MiniStatistics from "../../../components/card/MiniStatistics";
import { useHistory } from "../../../lib/hooks";
import { Link } from "react-router-dom";
import { MainTable } from "./components/Table/MainTable";
import { useAuth0 } from "@auth0/auth0-react";
import millify from "millify";
import fetcher from "../../../lib/fetcher";
import { mutate } from "swr";
import moment from "moment";

export default function NotificationsNew() {
  // Chakra Color Mode
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(10);
  const { user } = useAuth0();
  const userRole = user?.["https://admin.b2connect.me/roles"][0];

  const [selectedCell, setSelectedCell] = useState({});
  const { history, isLoading } = useHistory(page, per_page);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [notificationEventHistory, setNotificationEventHistory] = useState({});
  const toast = useToast();
  const {
    isOpen: isOpenDeactivate,
    onOpen: onOpenDeactivate,
    onClose: onCloseDeactivae,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();

  function isWhatPercentOf(numA, numB) {
    return (numA / numB) * 100;
  }
  const historyData = history
    ? history?.body?.notifications?.map((item, i) => ({
        name: item.name,
        eventId: item.id,
        owner: (
          <Tooltip key={i} label={item.owner}>
            <Avatar
              name={item.owner}
              bg={"#f6ad56"}
              color={"white"}
              size={"sm"}
            />
          </Tooltip>
        ),
        channels:
          item.channels[0] === "IN_APP" ? (
            <Flex flexDir={"row"}>
              <Player
                autoplay
                loop
                src="https://assets2.lottiefiles.com/private_files/lf30_3f6iffaa.json"
                style={{ height: "30px", width: "30px", marginRight: 5 }}
              ></Player>
              <Badge>App Notification</Badge>
            </Flex>
          ) : (
            <Flex flexDir={"row"}>
              <Player
                autoplay
                loop
                src="https://assets4.lottiefiles.com/private_files/lf30_zfqjchrj.json"
                style={{ height: "28px", width: "28px", marginRight: 5 }}
              ></Player>
              <Badge>SMS Notification</Badge>
            </Flex>
          ),
        total: (
          <Badge mx={1} colorScheme={"purple"}>
            {millify(item.totalCount, {
              precision: 2,
              decimalSeparator: ".",
            })}
          </Badge>
        ),
        status: (
          <Flex felxDir={"row"}>
            <Badge mx={1} colorScheme={"green"}>
              {millify(item.successCount, {
                precision: 2,
                decimalSeparator: ".",
              })}{" "}
              Success
            </Badge>
            <Badge mx={1} colorScheme={"red"}>
              {millify(item.failedCount, {
                precision: 2,
                decimalSeparator: ".",
              })}{" "}
              Failed
            </Badge>
            {item.failedCount + item.successCount !== item.totalCount ? (
              <CircularProgress
                value={parseInt(
                  isWhatPercentOf(
                    item.failedCount + item.successCount,
                    item.totalCount
                  )
                )}
                thickness="12px"
                size="30px"
                color="#e0457b"
              >
                <CircularProgressLabel size>
                  {parseInt(
                    isWhatPercentOf(
                      item.failedCount + item.successCount,
                      item.totalCount
                    )
                  )}
                  %
                </CircularProgressLabel>
              </CircularProgress>
            ) : null}
          </Flex>
        ),
      }))
    : [];

  const data = React.useMemo(() => historyData, [history]);

  const columns = React.useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "Name",
            accessor: "name",
          },
          {
            Header: "Sent by",
            accessor: "owner",
          },
          {
            Header: "Channels",
            accessor: "channels",
          },
          {
            Header: "Total",
            accessor: "total",
          },
        ],
      },
      {
        Header: " ",
        columns: [
          {
            Header: "Success - Failed",
            accessor: "status",
          },
        ],
      },
    ],
    []
  );

  const handleDrawer = async (data) => {
    setSelectedCell(data);

    const historyEvent = await fetcher(`notification/${data.eventId}`);

    setNotificationEventHistory(historyEvent?.body);

    onOpen();
  };

  const handleDrawerRetry = async (data) => {
    const historyEvent = await fetcher(`notification/resend/${data}`, {});
    //console.log(historyEvent)
    if (historyEvent.success) {
      toast({
        title: `Notification scheduled successfully`,
        description: `Notification has been scheduled.`,
        status: "success",
        duration: 2000,
        isClosable: true,
        onCloseComplete: async () => {
          await mutate("notification/history");

          onClose();
        },
      });
    }

    onOpen();
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Drawer onClose={onClose} isOpen={isOpen} size={"md"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{selectedCell.title}</DrawerHeader>
          <DrawerBody>
            <Flex>
              <FormControl>
                <Flex pb={5}>
                  <Flex felxDir={"row"}>
                    <Badge mx={1} colorScheme={"purple"}>
                      {notificationEventHistory?.totalCount &&
                        millify(notificationEventHistory?.totalCount, {
                          precision: 2,
                          decimalSeparator: ".",
                        })}{" "}
                      Total
                    </Badge>
                    <Badge mx={1} colorScheme={"green"}>
                      {notificationEventHistory?.successCount &&
                        millify(notificationEventHistory?.successCount, {
                          precision: 2,
                          decimalSeparator: ".",
                        })}{" "}
                      Success
                    </Badge>
                    <Badge mx={1} colorScheme={"red"}>
                      {notificationEventHistory?.failedCount &&
                        millify(notificationEventHistory?.failedCount, {
                          precision: 2,
                          decimalSeparator: ".",
                        })}{" "}
                      Failed
                    </Badge>
                    {notificationEventHistory?.channels?.map((item) => (
                      <Flex flexDir={"row"}>
                        <Badge>
                          {item === "IN_APP"
                            ? "App Notification"
                            : "SMS Notification"}
                        </Badge>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
                <Stack spacing={3}>
                  <FormLabel htmlFor="title">Title</FormLabel>
                  <Input
                    id="title"
                    type="text"
                    disabled
                    value={notificationEventHistory?.name}
                    color={textColor}
                  />

                  <FormLabel htmlFor="category">Owner</FormLabel>
                  <Input
                    id="Owner"
                    type="text"
                    disabled
                    value={notificationEventHistory?.owner}
                    color={textColor}
                  />
                  <FormLabel htmlFor="created">Message</FormLabel>
                  <Textarea
                    id="created"
                    type="text"
                    disabled
                    value={notificationEventHistory?.message}
                    color={textColor}
                  />
                  <FormLabel htmlFor="expiry">Date</FormLabel>
                  <Input
                    id="date"
                    type="text"
                    disabled
                    value={moment
                      .utc(notificationEventHistory?.timestamp)
                      .format("MMMM Do YYYY, h:mm:ss a")}
                    color={textColor}
                  />
                </Stack>
                <Flex>
                  {notificationEventHistory?.failedCount > 0 && (
                    <Button
                      mt={4}
                      onClick={() =>
                        handleDrawerRetry(notificationEventHistory?.id)
                      }
                      color={"white"}
                      background={"#e0457b"}
                    >
                      Retry{" "}
                      {notificationEventHistory?.failedCount &&
                        millify(notificationEventHistory?.failedCount, {
                          precision: 2,
                          decimalSeparator: ".",
                        })}{" "}
                      Failed
                    </Button>
                  )}

                  <AlertDialog
                    isOpen={isOpenDeactivate}
                    onClose={onCloseDeactivae}
                  >
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                          {selectedCell.bkStatus === "ACTIVE"
                            ? "Deactivate announcement"
                            : "Activate announcement"}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                          Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                          <Button onClick={onCloseDeactivae}>Cancel</Button>
                          <Button colorScheme="red" ml={3}>
                            {selectedCell.bkStatus === "ACTIVE"
                              ? "Deactivate"
                              : "Activate"}
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </Flex>
              </FormControl>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          loaded={!isLoading}
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Player
                  autoplay
                  loop
                  src="/lottiefiles/individual.json"
                  style={{ height: "120px", width: "120px" }}
                ></Player>
              }
            />
          }
          name="Individual"
          value={
            <div p={4}>
              <Button>
                <Link to={"/dashboard/notification/individual"}>Send</Link>{" "}
              </Button>
            </div>
          }
        />
        <MiniStatistics
          loaded={!isLoading}
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Player
                  autoplay
                  loop
                  src="/lottiefiles/group.json"
                  style={{ height: "80px", width: "80px" }}
                ></Player>
              }
            />
          }
          name="Group"
          value={
            <div m={4}>
              <Button>
                <Link to={"/dashboard/notification/group"}>Send</Link>
              </Button>
            </div>
          }
        />
        <MiniStatistics
          loaded={!isLoading}
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Player
                  autoplay
                  loop
                  src="/lottiefiles/filter.json"
                  style={{ height: "80px", width: "80px" }}
                ></Player>
              }
            />
          }
          name="Filters"
          value={
            <div m={4}>
              <Button>
                <Link to={"/dashboard/notification/filter"}>Send</Link>
              </Button>
            </div>
          }
        />
      </SimpleGrid> */}
      <MainTable
        loaded={!isLoading}
        tableName={"Notifications"}
        data={data}
        exports={userRole === "Admin" ? history?.body?.notifications : false}
        columns={columns}
        setPage={setPage}
        setPerPage={setPer_page}
        currentpage={page}
        perPage={per_page}
        drawer={true}
        handleDrawer={handleDrawer}
        totalPage={parseInt(history?.body?.total / per_page)}
        name={"History"}
      />
    </Box>
  );
}
