import React from "react";
import {useDropzone} from "react-dropzone";
import {MdClose, MdCloudUpload, MdFilePresent} from "react-icons/md";
import {Box, Button, Flex, SimpleGrid, Stack} from "@chakra-ui/react";

function FileUploaderSingle(props) {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      props.setFiles([
        ...props.files,
        ...acceptedFiles.map((file) => Object.assign(file)),
      ]);
    },
  });
  const renderFilePreview = (file) => {
    if (file.type.startsWith("image")) {
      return (
        <img
          className="rounded"
          alt={file.name}
          src={URL.createObjectURL(file)}
          height="28"
          width="28"
        />
      );
    } else {
      return <MdFilePresent size="28" />;
    }
  };
  const handleRemoveFile = (file) => {
    const uploadedFiles = props.files;
    const filtered = uploadedFiles.filter((i) => i.name !== file.name);
    props.setFiles([...filtered]);
  };
  const renderFileSize = (size) => {
    if (Math.round(size / 100) / 10 > 1000) {
      return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`;
    } else {
      return `${(Math.round(size / 100) / 10).toFixed(1)} kb`;
    }
  };

  const fileList = props.files.map((file, index) => (
    <SimpleGrid mt={3} columns={2} spacing={5} key={`${file.name}-${index}`}>
      <Flex alignItems={"center"}>
        <div>{renderFilePreview(file)}</div>
        <Flex flexDir={"column"} ml={2}>
          <p className="file-name mb-0">{file.name}</p>
          <p className="file-size mb-0">{renderFileSize(file.size)}</p>
        </Flex>
      </Flex>
      <span size="xs" onClick={() => handleRemoveFile(file)}>
        <MdClose size={14} />
      </span>
    </SimpleGrid>
  ));

  const handleRemoveAllFiles = () => {
    props.setFiles([]);
  };

  return (
    <Box>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          cursor={"pointer"}
        >
          <MdCloudUpload size={64} />
          <h5>Drop your image here to upload</h5>
        </Flex>
      </div>
      {props.files.length ? (
        <>
          <Stack direction={"column"} spacing="24px">
            {fileList}
          </Stack>
          <Flex className="d-flex justify-content-end">
            <Button
              my={3}
              className="me-1"
              color="danger"
              outline
              onClick={handleRemoveAllFiles}
            >
              Remove All
            </Button>
          </Flex>
        </>
      ) : null}
    </Box>
  );
}

export default FileUploaderSingle;
