import { useAuth0 } from "@auth0/auth0-react";
import useSWR, { mutate } from "swr";
import fetcher from "./fetcher";

export const useUser = () => {
  const { user, isAuthenticated, error, isLoading } = useAuth0();
  return {
    user,
    isLoading,
    isAuthenticated,
    isError: error,
  };
};

export const useStates = (mounted) => {
  const { data, error } = useSWR(
    mounted ? "notification/stats" : null,
    fetcher,
    { onLoadingSlow: () => {} }
  );
  return {
    states: data || [],
    isLoading: !data && !error,
    isError: error,
  };
};

export const useChartDount = (mounted) => {
  const { data, error } = useSWR(
    mounted ? "dictionaries/chart-count" : null,
    fetcher
  );

  return {
    dataChart: data,
    isLoading: !data && !error,
    isError: error,
  };
};

export const useHistory = (page, per_page) => {
  const { data, error } = useSWR(
    `notification/history?page=${page}&per_page=${per_page}`,
    fetcher,
    { refreshInterval: 6000 }
  );

  return {
    history: data,
    isLoading: !data && !error,
    isError: error,
  };
};

export const useFilterGroups = (page, per_page) => {
  const { data, error } = useSWR(
    `groups/filter?page=${page}&per_page=${per_page}`,
    fetcher,
    { refreshInterval: 6000 }
  );

  return {
    filterGroups: data,
    isGroupsLoading: !data && !error,
    isError: error,
  };
};

export const useUsers = (location) => {
  const body = {
    userFilterItems: location
      ? [
          {
            text: location,
            filterField: "LOCATION",
            filterType: "EQUALS",
          },
        ]
      : [
          {
            text: "IN_APP",
            filterField: "CHANNEL",
            filterType: "EQUALS",
          },
        ],
  };
  const { data, error } = useSWR("users/count", () =>
    fetcher("users/count", body)
  );
  return {
    usersCount: data,
    usersLoading: !error && !data,
    usersError: error,
  };
};

export const useSurvey = () => {
  const { data, error } = useSWR("survey/", fetcher);
  return {
    survey: data,
    isLoading: !data && !error,
    isError: error,
  };
};

export const useAnnouncements = (page, per_page) => {
  const { data, error } = useSWR(
    `announcement/?page=${page}&per_page=${per_page}`,
    fetcher
  );

  return {
    announcementsData: data,
    isError: error,
    isLoading: !data && !error,
  };
};

export const useGetPackageOrders = (page, per_page, searchText) => {
  const { data, error } = useSWR(
    `package-orders?page=${page}&per-page=${per_page}&search=${searchText}`,
    fetcher
  );
  console.log(data);

  return {
    packageOrdersData: data,
    isPackageOrdersError: error,
    isPackageOrdersLoading: !data && !error,
  };
};

export const useGetDevices = (page, per_page, searchText, apiFilterParam) => {
  const { data, error } = useSWR(
    `devices/?page=${page}&per-page=${per_page}${
      searchText ? `&search=${searchText}` : ""
    }&filter-by=${apiFilterParam ? apiFilterParam : ""}`,
    fetcher
  );

  return {
    devicesData: data,
    isError: error,
    isLoading: !data && !error,
  };
};

export const useGetDeviceForBox = (page, per_page, searchText) => {
  const { data, error } = useSWR(
    `devices/?page=${page}&per-page=${per_page}${
      searchText ? `&search=${searchText}` : "&search=!!!!!!!!!"
    }`,
    fetcher
  );

  return {
    devicesData: data,
    isError: error,
    isLoading: !data && !error,
  };
};

export const useGetAssetZonesBuildings = () => {
  const { data, error } = useSWR(`devices/zone-buildings`, fetcher);
  console.log(data);

  return {
    zoneBuildingData: data,
    isZoneBuildingError: error,
    isZoneBuildingLoading: !data && !error,
  };
};

export const useGetBoxes = (type) => {
  const { data, error } = useSWR(
    `devices/boxes${type ? `?type=${type}` : ""}`,
    fetcher
  );
  console.log(data);

  return {
    boxesData: data,
    isBoxesError: error,
    isBoxesLoading: !data && !error,
  };
};

export const useGetBoxById = (id) => {
  const { data, error } = useSWR(`devices/boxes/${id}`, fetcher);
  console.log(data);

  return {
    boxInfoData: data,
    isBoxInfoError: error,
    isBoxInfoLoading: !data && !error,
  };
};

export const useGetAssetLogs = (filter) => {
  const { data, error } = useSWR(`devices/logs/${filter}`, fetcher);
  console.log(data);

  return {
    assetsLogData: data,
    isAssetsLogError: error,
    isAssetsLogLoading: !data && !error,
  };
};

export const useGetBoxLogs = (filter) => {
  const { data, error } = useSWR(`devices/boxes/logs/${filter}`, fetcher);
  console.log(data);

  return {
    boxesLogData: data,
    isBoxesLogError: error,
    isBoxesLogLoading: !data && !error,
  };
};

export const useGetUsers = (page, per_page, searchText) => {
  const { data, error } = useSWR(
    `users/?page=${page}&per_page=${per_page}&search=${
      searchText ? searchText : ""
    }`,
    fetcher
  );

  return {
    usersData: data,
    isError: error,
    isLoading: !data && !error,
  };
};

export const useGetUsersV2 = (page, per_page, extraParams) => {
  const { data, error } = useSWR(
    `users/v2/?page=${page}&per_page=${per_page}${
      extraParams ? extraParams : ""
    }`,
    fetcher
  );

  return {
    usersData: data,
    isError: error,
    isLoading: !data && !error,
  };
};

export const useGetUsersCount = () => {
  const { data, error } = useSWR(`users/`, fetcher);

  return {
    usersData: data,
    isError: error,
    isLoading: !data && !error,
  };
};
export const useSales = (mounted, page, per_page, fillterArray) => {
  const { data, error } = useSWR(
    mounted
      ? `orders/?page=${page}&per-page=${per_page}&filter-by=${fillterArray}`
      : null,
    fetcher,
    { refreshInterval: 7000 }
  );
  return {
    sales: data,
    isLoading: !data && !error,
    isError: error,
    mutate,
  };
};

export const useStatus = (mounted) => {
  const { data, error } = useSWR(
    mounted ? "dictionaries/order-statuses/" : null,
    fetcher
  );

  return {
    status: data,
    isLoading: !data && !error,
    isError: error,
  };
};

export const useLocations = (mounted) => {
  const { data, error } = useSWR(
    mounted ? "dictionaries/locations/" : null,
    fetcher
  );

  return {
    locations: data,
    isLoading: !data && !error,
    isError: error,
  };
};

export const useNewAnnouncement = (payload) => {
  const { data, error } = useSWR(
    "announcement/",
    fetcher("announcement/", payload)
  );

  return {
    NewResponse: data,
    isLoading: !data && !error,
    isError: error,
  };
};

export const useGroups = () => {
  const { data, error } = useSWR("groups", fetcher);

  return {
    groups: data,
    isLoading: !data && !error,
    isError: error,
  };
};
export const useJobRoles = (mounted) => {
  const { data, error } = useSWR(
    mounted ? "dictionaries/job-roles/" : null,
    fetcher
  );

  return {
    job_roles: data,
    isLoading: !data && !error,
    isError: error,
  };
};
export const useDepartment = (mounted) => {
  const { data, error } = useSWR(
    mounted ? "dictionaries/departments/" : null,
    fetcher
  );

  return {
    department: data,
    isLoading: !data && !error,
    isError: error,
  };
};

export const useSalesPerson = (person) => {
  const { data, error } = useSWR(
    person ? "dictionaries/salespersons" : null,
    fetcher
  );

  if (data?.success) {
    const salesPersonData = data?.body?.filter((item) =>
      item.salesperson_name.includes(person)
    );

    return {
      salesPerson: salesPersonData || data,
      isLoading: !data && !error,
      isError: error,
    };
  } else {
    return {
      salesPerson: data,
      isLoading: !data && !error,
      isError: error,
    };
  }
};
export const useSalesPersons = () => {
  const { data, error } = useSWR("dictionaries/salespersons", fetcher);
  return {
    salesPersons: data,
    isLoading: !data && !error,
    isError: error,
  };
};
