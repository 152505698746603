import React, { useRef, useMemo, useState } from "react";
import {
  MdReplay,
  MdOutlineEditNote,
  MdDownload,
  MdArrowRightAlt,
} from "react-icons/md";

import { CSVLink } from "react-csv";

import { FaCartArrowDown } from "react-icons/fa";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogContent,
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  SimpleGrid,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useStyleConfig,
  useToast,
  Icon,
} from "@chakra-ui/react";
import IconBox from "../../../components/icons/IconBox";
import { Player } from "@lottiefiles/react-lottie-player";
import RedirectCard from "../../../components/card/RedirectCard";
import {
  useGetUsers,
  useGetDevices,
  useGetUsersCount,
  useGetDeviceForBox,
  useGetBoxes,
  useGetAssetZonesBuildings,
  useGetBoxById,
} from "../../../lib/hooks";
import { MainTable } from "./components/MainTable";
import moment from "moment";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import nationalities from "./nationality.json";

import Html5QrcodePlugin from "./components/Html5QrcodePlugin";
import Card from "components/card/Card";
import { useEffect } from "react";
import { filter, set } from "lodash";

function AssetBox() {
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const inventoryStatusTypes = [
    {
      key: "INSTALLED",
      value: "INSTALLED",
    },
    {
      key: "DECOMMISSIONED",
      value: "CHECKOUT",
    },
    {
      key: "MISSING",
      value: "MISSING",
    },
  ];

  const boxTypes = [
    {
      key: "NEW AND GOOD",
      value: "NEW_AND_GOOD",
    },
    {
      key: "NEED TO CLEAN",
      value: "NEED_TO_CLEAN",
    },
    {
      key: "TO BE REFURBISHED",
      value: "TO_BE_REFURBISHED",
    },
    {
      key: "UNKNOWN",
      value: "UNKNOWN",
    },
  ];

  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [apiFilterParam, setApiFilterParam] = useState("");

  const [visible, setVisible] = useState(false);

  const [updateBtnEnable, setUpdateBtnEnable] = useState(false);

  const [editFirstName, setEditFirstName] = useState("");
  const [editLastName, setEditLastName] = useState("");
  const [editNationality, setEditNationality] = useState("");
  const [editEmpID, setEditEmpID] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editGender, setEditGender] = useState("");
  const [editRoom, setEditRoom] = useState("");
  const [editPosition, setEditPosition] = useState("");
  const [editDepartment, setEditDepartment] = useState("");
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const { devicesData, isError, isLoading } = useGetDeviceForBox(
    page,
    per_page,
    searchText
  );

  const { zoneBuildingData, isZoneBuildingError, isZoneBuildingLoading } =
    useGetAssetZonesBuildings();
  const [selectedBoxType, setSelectedBoxType] = useState("");

  const { boxesData, isBoxesError, isBoxesLoading } =
    useGetBoxes(selectedBoxType);

  const [filteredBoxes, setFilteredBoxes] = useState([]);

  const [zones, setZones] = useState([]);
  const [zonesBuildings, setZonesBuildings] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [selectedBox, setSelectedBox] = useState("");
  const [boxInfo, setBoxInfo] = useState([]);
  const [boxAssetsInfo, setBoxAssetsInfo] = useState([]);

  const { boxInfoData, isBoxInfoError, isBoxInfoLoading } =
    useGetBoxById(selectedBox);

  const [selectedInventoryStatus, setSelectedInventoryStatus] = useState("");

  const [zoneBuildingFilterValue, setZoneBuildingFilterValue] = useState("");

  const [inventoryFilterValue, setInventoryFilterValue] = useState("");
  const [boxTypeFilterValue, setBoxTypeFilterValue] = useState("");

  const [buildingsForSelectedZone, setBuildingsForSelectedZone] = useState([]);
  const [boxForSelectedType, setBoxForSelectedType] = useState([]);
  useEffect(() => {
    let filtersArray = [];
    if (inventoryFilterValue && inventoryFilterValue !== "") {
      filtersArray.push(inventoryFilterValue);
    }
    if (boxTypeFilterValue && boxTypeFilterValue !== "") {
      filtersArray.push(boxTypeFilterValue);
    }
    if (zoneBuildingFilterValue && zoneBuildingFilterValue !== "") {
      filtersArray.push(zoneBuildingFilterValue);
    }
    let combinedFilter = filtersArray.join(",");
    console.log("COMBINED FILTER: " + filtersArray);
    setApiFilterParam(combinedFilter);
    setPage(1);
  }, [inventoryFilterValue, zoneBuildingFilterValue, boxTypeFilterValue]);

  const handleInventoryStatusSelection = (c) => {
    console.log("Selected inventory status is now: " + c.target.value);
    setSelectedInventoryStatus(c.target.value);
    let inventoryFilterVal = c.target.value
      ? `INVENTORY_STATUS:${c.target.value}`
      : "";
    setInventoryFilterValue(inventoryFilterVal);
  };

  const handleBoxTypeSelection = (c) => {
    console.log("Selected box type is now: " + c.target.value);
    setSelectedBoxType(c.target.value);
    setSelectedBox(undefined);
    let zoneFilterVal = c.target.value ? `ZONE:${c.target.value}` : "";
    setZoneBuildingFilterValue(zoneFilterVal);
  };

  const handleBoxSelection = (c) => {
    console.log("Selected Box is now: " + c.target.value);
    setSelectedBox(c.target.value);
  };

  useEffect(() => {
    setZonesBuildings(
      !isZoneBuildingError && !isZoneBuildingLoading && zoneBuildingData
        ? zoneBuildingData?.body
        : []
    );
  }, [zoneBuildingData]);

  useEffect(() => {
    setBoxInfo(
      !isBoxInfoError && !isBoxInfoLoading && boxInfoData
        ? boxInfoData?.body?.box
        : {}
    );
  }, [boxInfoData]);

  useEffect(() => {
    setBoxAssetsInfo(
      !isBoxInfoError && !isBoxInfoLoading && boxInfoData
        ? boxInfoData?.body?.assets
        : {}
    );
  }, [boxInfoData]);

  useEffect(() => {
    setZonesBuildings(
      !isZoneBuildingError && !isZoneBuildingLoading && zoneBuildingData
        ? zoneBuildingData?.body
        : []
    );
  }, [zoneBuildingData]);

  useEffect(() => {
    setFilteredBoxes(
      !isBoxesError && !isBoxesLoading && boxesData ? boxesData?.body : []
    );
  }, [boxesData]);

  useEffect(() => {
    setZones(Object.keys(zonesBuildings));
  }, [zonesBuildings]);

  useEffect(() => {
    console.log(selectedZone);
    setBuildingsForSelectedZone(zonesBuildings[selectedZone]);
  }, [selectedZone]);

  const { user } = useAuth0();
  const userRole = user?.["https://admin.b2connect.me/roles"][0];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCell, setSelectedCell] = useState({});
  const {
    isOpen: editOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const textColor = useColorModeValue("secondaryGrey.700", "white");
  const [resetErr, setResetErr] = useState(false);

  const {
    isOpen: isOpenReset,
    onOpen: onOpenReset,
    onClose: onCloseReset,
  } = useDisclosure();

  const {
    isOpen: isStatusOpenReset,
    onOpen: onStatusOpenReset,
    onClose: onStatusCloseReset,
  } = useDisclosure();

  const deviceData =
    !isError && !isLoading && devicesData
      ? devicesData?.body?.items?.map((item) => ({
          id: item.id,
          mac: item.mac,
          serial: item.serial,
          deviceName: item.deviceName,
          description: item.description,
          model: item.model,
          zone: item.zone,
          building: item.building,
          floor: item.floor ? item.floor : "",
          inventory_status: item.inventory_status,
          createdTime: moment(item.createdTime * 1000)
            .utcOffset("+0400")
            .format("LLL"),
          updatedTime: item.updatedTime
            ? moment(item.updatedTime * 1000)
                .utcOffset("+0400")
                .format("LLL")
            : null,
        }))
      : [];

  const usersTotalCount =
    !isError && !isLoading && devicesData ? devicesData?.body?.total : 0;
  const data = React.useMemo(() => deviceData, [devicesData]);

  const columns = useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "MAC",
            accessor: "mac",
          },
          {
            Header: "Serial",
            accessor: "serial",
          },
        ],
      },
      {
        Header: " ",
        columns: [
          {
            Header: "Zone",
            accessor: "zone",
          },
          {
            Header: "Building",
            accessor: "building",
          },
          {
            Header: "Description",
            accessor: "description",
          },
          {
            Header: "Inventory Status",
            accessor: "inventory_status",
          },
          {
            Header: "Device Name",
            accessor: "deviceName",
          },
        ],
      },
    ],
    []
  );

  const handleVisible = () => {
    setVisible(!visible);
  };

  const handleScanSuccess = (value) => {
    console.log(value);
    setSearchText(value);
    setPage(1);
    setVisible(false);
  };

  const handleDrawer = (data) => {
    setSelectedCell(data);
    onOpen();
  };

  const handleSearchText = (data) => {
    setSearchText(data);
    setPage(1);
  };

  let authToken = localStorage.getItem("auth0_token");
  const toast = useToast();

  const handleEditOpen = (e) => {
    setEditFirstName(e?.firstName);
    setEditLastName(e?.lastName);
    setEditNationality(e?.nationality);
    setEditEmail(e?.email);
    setEditEmpID(e?.empId);
    setEditGender(e?.gender);
    setEditRoom(e?.room);
    setEditPosition(e?.position);
    setEditDepartment(e?.department);
    setUpdateBtnEnable(false);
    onEditOpen();
  };

  const handleEditSubmit = async (e) => {
    setUpdateBtnEnable(true);
    let editSubmitBody = {
      login: e.contactNumber,
      firstName: editFirstName ? editFirstName : "",
      lastName: editLastName ? editLastName : null,
      nationality: editNationality ? editNationality : "",
      gender: editGender ? editGender : "",
      email: editEmail ? editEmail : "",
      room: editRoom ? editRoom : "",
      employeeId: editEmpID ? editEmpID : "",
      position: editPosition ? editPosition : "",
      department: editDepartment ? editDepartment : "",
    };
    Object.keys(editSubmitBody).forEach((key) => {
      if (editSubmitBody[key] === null || editSubmitBody[key] === "") {
        delete editSubmitBody[key];
      }
    });
    console.log(editSubmitBody);
    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/users/${e.contactNumber}`,
        editSubmitBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        setUpdateBtnEnable(false);
        onCloseReset();
        toast({
          title: "Updated successfully.",
          description: "You've just updated the details of the user.",
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onEditClose();
            onClose();
          },
        });
      })
      .catch((e) => {
        setResetErr(true);
        onCloseReset();
        toast({
          title: "Something went wrong .",
          description: "Please try again or contact the admin.",
          status: "error",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onEditClose();
            onClose();
          },
        });
      });
  };

  const changeBoxStatus = async (e) => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/devices/boxes/${selectedBox}/change-status`,
        null,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        onStatusCloseReset();
        toast({
          title: "Box status changed successfully",
          description: "Box status changed successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onStatusCloseReset();
            onClose();
          },
        });
      })
      .catch((e) => {
        setResetErr(true);
        onStatusCloseReset();
        toast({
          title: "Something went wrong .",
          description: "Please try again or contact the admin.",
          status: "error",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onCloseReset();
            onClose();
          },
        });
      });
  };

  const mapAssetToBox = async (e) => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/devices/${e.id}/map-box/${selectedBox}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        onCloseReset();
        toast({
          title: "Decommission successful.",
          description: "Device decommissioned successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onCloseReset();
            onClose();
          },
        });
      })
      .catch((e) => {
        setResetErr(true);
        onCloseReset();
        toast({
          title: "Something went wrong .",
          description: "Please try again or contact the admin.",
          status: "error",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onCloseReset();
            onClose();
          },
        });
      });
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <Card>
          <Stack spacing={3}>
            <Select
              variant="outline"
              placeholder="Select Box Type"
              value={selectedBoxType}
              onChange={handleBoxTypeSelection}
            >
              {boxTypes.map((item) => (
                <option key={item.key} value={item.value}>
                  {item.key}
                </option>
              ))}
            </Select>

            {selectedBoxType && (
              <Select
                variant="outline"
                placeholder="Select Box"
                value={selectedBox}
                onChange={handleBoxSelection}
              >
                {filteredBoxes &&
                  filteredBoxes.map((item) => (
                    <option key={item.serial} value={item.id}>
                      {item.serial}
                    </option>
                  ))}
              </Select>
            )}
          </Stack>
        </Card>
        {selectedBox && (
          <Card>
            <Text mb={1} fontSize={"lg"} fontWeight="bold">
              SELECTED BOX INFO
            </Text>
            <Flex>
              <Text my={1} fontWeight="bold" pr={0.5}>{`SERIAL: `}</Text>
              <Badge
                my={1}
                ml={2}
                colorScheme="green"
                fontSize="sm"
                fontWeight="bold"
              >
                {boxInfo?.serial ? boxInfo.serial : ""}
              </Badge>
            </Flex>
            <Flex>
              <Text my={1} fontWeight="bold" pr={4}>{`TYPE: `}</Text>
              <Badge
                my={1}
                ml={2}
                colorScheme="blue"
                fontSize="sm"
                fontWeight="bold"
              >
                {boxInfo?.type ? boxInfo?.type.replaceAll("_", " ") : ""}
              </Badge>
            </Flex>
            <Flex>
              <Text my={1} fontWeight="bold">{`STATUS: `}</Text>
              <Badge
                my={1}
                ml={2}
                colorScheme="red"
                fontSize="sm"
                fontWeight="bold"
              >
                {boxInfo?.status ? boxInfo?.status : ""}
              </Badge>
            </Flex>
            <Flex>
              <Text my={1} fontWeight="bold">{`ASSETS: `}</Text>
              <Badge
                my={1}
                ml={2}
                colorScheme="purple"
                fontSize="sm"
                fontWeight="bold"
              >
                {boxAssetsInfo?.length ? boxAssetsInfo?.length : "0"}
              </Badge>
            </Flex>
            <Button
              size={"md"}
              mt={4}
              mb={2}
              backgroundColor={"blue.500"}
              textColor="white"
              _hover={{ backgroundColor: "blue.400" }}
              onClick={onStatusOpenReset}
            >
              CHANGE BOX STATUS
            </Button>
            {boxAssetsInfo?.length > 0 && (
              <div>
                <Text my={2} fontWeight="bold">
                  Please click the below button to export all assets inside the
                  selected box
                </Text>

                <CSVLink
                  filename={
                    `box_${boxInfo?.serial}_${boxInfo?.type}_assets_info` +
                    " " +
                    Date()
                  }
                  data={boxAssetsInfo ? boxAssetsInfo : "empty"}
                >
                  <Button
                    size={"md"}
                    mt={2}
                    width="100%"
                    leftIcon={
                      <Icon
                        as={MdDownload}
                        width="20px"
                        height="20px"
                        color="inherit"
                      />
                    }
                    mb={2}
                    backgroundColor={"purple.500"}
                    textColor="white"
                    _hover={{ backgroundColor: "purple.400" }}
                  >
                    EXPORT
                  </Button>
                </CSVLink>
              </div>
            )}
            <AlertDialog
              isOpen={isStatusOpenReset}
              onClose={onStatusCloseReset}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    {`Change Box Status to ${
                      boxInfo?.status == "EMPTY" ? "IN STORAGE" : "SHIPPED"
                    }`}
                  </AlertDialogHeader>
                  <AlertDialogBody>
                    <Flex mb={2}>
                      <Badge colorScheme="blue">
                        {boxInfo?.status?.replaceAll("_", "")}
                      </Badge>
                      <Icon
                        as={MdArrowRightAlt}
                        width="20px"
                        height="20px"
                        color="inherit"
                        mx={3}
                      />
                      <Badge colorScheme="green">
                        {boxInfo?.status == "EMPTY" ? "IN STORAGE" : "SHIPPED"}
                      </Badge>
                    </Flex>
                    {`Are you sure you want to ${
                      boxInfo?.status == "EMPTY"
                        ? "put box in storage"
                        : "ship box to office"
                    }? You can't undo this action afterwards.`}
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button colorScheme="red" onClick={() => changeBoxStatus()}>
                      {"Confirm"}
                    </Button>
                    <Button ml={3} onClick={onStatusCloseReset}>
                      Cancel
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </Card>
        )}
        <Flex direction={"column"} gap={4} alignContent={"left"}>
          <Button
            onClick={handleVisible}
            size="lg"
            backgroundColor={"orange.500"}
            textColor="black"
            _hover={{ backgroundColor: "orange.400" }}
          >
            {visible ? "Close Device Scanner" : "Scan Device"}
          </Button>
          {visible && (
            <Html5QrcodePlugin
              fps={10}
              qrbox={250}
              disableFlip={true}
              qrCodeSuccessCallback={handleScanSuccess}
            />
          )}
        </Flex>
      </SimpleGrid>
      <Drawer onClose={onClose} isOpen={isOpen} size={"md"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton mt={3} />
          <Flex flexDir={"column"}>
            <DrawerHeader mt={1}>{selectedCell.mac}</DrawerHeader>
            <Flex>
              <Flex pl={3}>
                {selectedBox ? (
                  <Button
                    leftIcon={<FaCartArrowDown />}
                    p={2}
                    ml={3}
                    borderRadius="10px"
                    variant="outline"
                    onClick={onOpenReset}
                    colorScheme="orange"
                  >
                    {"Box Asset"}
                  </Button>
                ) : (
                  <Button
                    disabled
                    leftIcon={<FaCartArrowDown />}
                    p={2}
                    ml={3}
                    borderRadius="10px"
                    variant="outline"
                    onClick={onOpenReset}
                    colorScheme="orange"
                  >
                    {"Select A Box First"}
                  </Button>
                )}
                <AlertDialog isOpen={isOpenReset} onClose={onCloseReset}>
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {"Box Asset"}
                      </AlertDialogHeader>

                      <AlertDialogBody>
                        Are you sure? You can't undo this action afterwards.
                      </AlertDialogBody>

                      <AlertDialogFooter>
                        <Button
                          colorScheme="red"
                          onClick={() => mapAssetToBox(selectedCell)}
                        >
                          {"Confirm"}
                        </Button>
                        <Button ml={3} onClick={onCloseReset}>
                          Cancel
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </Flex>
            </Flex>
          </Flex>
          <DrawerBody>
            <Flex>
              <FormControl>
                <Stack spacing={3}>
                  <FormLabel htmlFor="mac">MAC</FormLabel>
                  <Input
                    disabled={true}
                    id="mac"
                    type="text"
                    value={selectedCell.mac}
                    color={"red"}
                    fontWeight="extrabold"
                    _disabled={{ color: "blue.500" }}
                  />
                  <FormLabel htmlFor="description">Description</FormLabel>
                  <Input
                    disabled={true}
                    id="description"
                    type="text"
                    value={selectedCell.description}
                    color={textColor}
                    fontWeight="extrabold"
                    _disabled={{ color: "blue.500" }}
                  />
                  <FormLabel htmlFor="serial">Serial</FormLabel>
                  <Input
                    disabled={true}
                    id="serial"
                    type="text"
                    value={selectedCell.serial}
                    color={textColor}
                    fontWeight="extrabold"
                  />

                  <FormLabel htmlFor="deviceName">Device Name</FormLabel>
                  <Input
                    disabled={true}
                    id="deviceName"
                    type="text"
                    value={selectedCell.deviceName}
                    color={textColor}
                  />

                  <FormLabel htmlFor="model">Model</FormLabel>
                  <Input
                    disabled={true}
                    id="model"
                    type="text"
                    value={selectedCell.model}
                    color={textColor}
                  />
                  <FormLabel htmlFor="zone">Zone</FormLabel>
                  <Input
                    disabled={true}
                    id="zone"
                    type="text"
                    value={selectedCell.zone}
                    color={textColor}
                  />
                  <FormLabel htmlFor="building">Building</FormLabel>
                  <Input
                    disabled={true}
                    id="building"
                    type="text"
                    value={selectedCell.building}
                    color={textColor}
                  />
                  <FormLabel htmlFor="inventoryStatus">
                    Inventory Status
                  </FormLabel>
                  <Input
                    disabled={true}
                    id="inventoryStatus"
                    type="text"
                    value={selectedCell.inventory_status}
                    color={textColor}
                  />
                  <FormLabel htmlFor="createdTime">Created Time</FormLabel>
                  <Input
                    disabled={true}
                    id="createdTime"
                    type="text"
                    value={selectedCell.createdTime}
                    color={textColor}
                  />
                  <FormLabel htmlFor="updatedTime">Updated Time</FormLabel>
                  <Input
                    disabled={true}
                    id="updatedTime"
                    type="text"
                    value={selectedCell.updatedTime}
                    color={textColor}
                  />
                </Stack>
              </FormControl>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Flex>
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={editOpen}
          onClose={onEditClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Device Mapping</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Flex>
                <FormControl ml={4} mt={4}>
                  <FormLabel>Box</FormLabel>
                  <Select
                    color={textColor}
                    placeholder={"Select Box"}
                    onChange={(e) => setEditNationality(e.target.value)}
                  >
                    {nationalities.map((item, i) => (
                      <option
                        key={i}
                        value={item.name.toUpperCase()}
                        selected={
                          item.name.toUpperCase() === selectedCell?.nationality
                        }
                      >
                        {item.name.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button
                disabled={updateBtnEnable}
                colorScheme="pink"
                mr={3}
                onClick={() => handleEditSubmit(selectedCell)}
              >
                Update
              </Button>
              <Button onClick={onEditClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      {/* <Text>{JSON.stringify(selectedBuilding)}</Text> */}
      <MainTable
        loaded={!isLoading}
        searchText={searchText}
        data={data}
        columns={columns}
        setPage={setPage}
        setPerPage={setPer_page}
        searchText={searchText}
        handleSearchText={handleSearchText}
        currentpage={page}
        perPage={per_page}
        exports={userRole === "Admin" ? deviceData : false}
        totalPage={parseInt(devicesData?.body?.pages)}
        drawer={true}
        handleDrawer={handleDrawer}
        name={`Search Asset to Box:`}
      />
    </Box>
  );
}

export default AssetBox;
