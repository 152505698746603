import React from "react";
import { Box, Popover, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import IconBox from "../../../components/icons/IconBox";
import { Player } from "@lottiefiles/react-lottie-player";
import RedirectExternalCard from "../../../components/card/RedirectExternalCard";

const notificationsType = [
  {
    id: 1,
    name: "WhatsApp Us",
    icon: "https://assets6.lottiefiles.com/private_files/lf30_lghsciar.json",
    description: "Send WhatsApp message",
    button: "WhatsApp now",
    path: "https://wa.me/+97148753894",
  },

  {
    id: 2,
    name: "Call Us",
    icon: "https://assets1.lottiefiles.com/packages/lf20_gi5wgkra.json",
    description: "Reach us by phone",
    button: "Call now",
    path: "tel:800614",
  },
  {
    id: 3,
    name: "Send via Email",
    icon: "https://assets8.lottiefiles.com/packages/lf20_kse8jmzt.json",
    description: "Contact us via email",
    button: "Mail Now",
    path: "mailto:support@bsquaredwifi.com",
  },
];

function Index() {
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        {notificationsType.map((card, i) => (
          <div key={i}>
            {card?.id == 2 ? (
              <Popover>
                <RedirectExternalCard
                  startContent={
                    <IconBox
                      w="60px"
                      h="60px"
                      bg={boxBg}
                      icon={
                        <Player
                          autoplay
                          loop
                          src={card.icon}
                          style={{ height: "120px", width: "120px" }}
                        ></Player>
                      }
                    />
                  }
                  name={card.name}
                  description={card.description}
                  value={card.button}
                  path={card.path}
                  call={true}
                />
              </Popover>
            ) : (
              <RedirectExternalCard
                startContent={
                  <IconBox
                    w="60px"
                    h="60px"
                    bg={boxBg}
                    icon={
                      <Player
                        autoplay
                        loop
                        src={card.icon}
                        style={{ height: "120px", width: "120px" }}
                      ></Player>
                    }
                  />
                }
                name={card.name}
                description={card.description}
                value={card.button}
                path={card.path}
              />
            )}
          </div>
        ))}
      </SimpleGrid>
    </Box>
  );
}

export default Index;
