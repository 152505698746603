import React from "react";
import {
  Button,
  Center,
  Flex,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import { Link } from "react-router-dom";

function NotificationCard(props) {
  const { startContent, endContent, name, value, description, path, type } =
    props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  return (
    <Card py="15px" shadow={"sm"}>
      <Flex w={"100%"} h="100%" flexDir={"column"} alignItems={"center"}>
        <Stat my="auto">
          <StatLabel
            lineHeight="100%"
            color={textColorSecondary}
            mb={5}
            textAlign={"center"}
          >
            <Heading size={"sm"}>{name}</Heading>
          </StatLabel>

          <Center mb={5}>{startContent}</Center>

          <Text textAlign={"center"} color={textColorSecondary} fontSize={"md"}>
            {description}
          </Text>
          <StatNumber
            color={textColor}
            textAlign={"center"}
            mt={2}
            fontSize={{
              base: "2xl",
            }}
          >
            <Button>
              <Link
                to={{
                  pathname: path,
                  state: {
                    type,
                  },
                }}
              >
                {value}
              </Link>
            </Button>
          </StatNumber>
        </Stat>

        <Flex ms="auto" w="max-content">
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}

export default NotificationCard;
