import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/App.css";
import App from "./App";
import {Auth0Provider} from "@auth0/auth0-react";
import history from "./utils/history";


const env = process.env.REACT_APP_ENVIRONMENT;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;

const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const clientId_backoffice = process.env.REACT_APP_AUTH0_CLIENT_ID_BACKOFFICE;

const audience = "https://api.b2connect.me";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};
const providerConfig = {
  domain: domain,
  clientId: env == "backoffice" ? clientId_backoffice : clientId,
  ...(audience ? { audience: audience } : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
};

ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);


