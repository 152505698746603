// Chakra imports
// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text, Skeleton,
} from "@chakra-ui/react";

import Card from "components/card/Card.js";

import React from "react";

export default function Default(props) {
  const { startContent, endContent, name, growth, value, loaded } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  return (
      <Skeleton isLoaded={loaded}  fadeDuration={1.5} borderRadius={20}>
        <Card py="15px">
          <Flex
              my="auto"
              h="100%"
              align={{ base: "center", xl: "start" }}
              justify={{ base: "center", xl: "center" }}
          >
            {startContent}

            <Stat my="auto" ms={startContent ? "18px" : "0px"}>
              <StatLabel
                  lineHeight="100%"
                  color={textColorSecondary}
                  fontSize={{
                    base: "md",
                  }}
              >
                {name}
              </StatLabel>
              <StatNumber
                  color={textColor}
                  fontSize={{
                    base: "2xl",
                  }}
                  mt={2}
              >
                {value}
              </StatNumber>
            </Stat>
            <Flex ms="auto" w="max-content">
              {endContent}
            </Flex>
          </Flex>
        </Card>
      </Skeleton>

  );
}
