import { Step, Steps, useSteps } from "chakra-ui-steps";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Skeleton,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import fetcher from "../../../../../lib/fetcher";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import { count } from "sms-length";
import { Player } from "@lottiefiles/react-lottie-player";
import { MainTable } from "../../components/MainTable";

import { useGetUsers, useGetUsersV2 } from "../../../../../lib/hooks";

const steps = [
  { id: 1, label: "Select User", description: "Select your user" },
  {
    id: 2,
    label: "Notification Options",
    description: "Enter Your Notification Details",
  },
  { id: 3, label: "Preview", description: "Preview Your Data" },
];

export const StepsExampleNew = (props) => {
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const [searchText, setSearchText] = useState("");
  const [extraParams, setExtraParams] = useState("");
  const [sortConfig, setSortConfig] = useState("");
  const [filterConfig, setFilterConfig] = useState("");
  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(10);
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  // const {
  //   usersData,
  //   isError: isUserError,
  //   isLoading: isUserLoading,
  // } = useGetUsers(page, per_page, searchText);

  useEffect(() => {
    setExtraParams(
      `${sortConfig ? `&${sortConfig}` : ""}${
        filterConfig ? `&${filterConfig}` : ""
      }`
    );
  }, [sortConfig, filterConfig]);

  const {
    usersData,
    isError: isUserError,
    isLoading: isUserLoading,
  } = useGetUsersV2(page, per_page, extraParams);

  useEffect(() => {
    console.log(`Loading: ${isUserLoading}`);
  }, [isUserLoading]);

  const { user } = useAuth0();
  const userRole = user?.["https://admin.b2connect.me/roles"][0];

  const userData =
    !isUserError && !isUserLoading && usersData
      ? usersData?.body?.users?.map((item) => ({
          contactNumber: item.contactNumber,
          firstName: item.firstName,
          lastName: item.lastName,
          nationality: item.nationality,
          gender: item.gender,
          email: item.email,
          room: item.room,
          property: item.property,
          empId: item.empId,
          position: item.position,
          department: item.department,
          createdTime: moment(item.createdTime * 1000)
            .utcOffset("+0400")
            .format("LLL"),
          lastModifiedTime: moment(item.lastModifiedTime * 1000)
            .utcOffset("+0400")
            .format("LLL"),
        }))
      : [];

  const {
    isOpen: isOpenReset,
    onOpen: onOpenReset,
    onClose: onCloseReset,
  } = useDisclosure();

  const usersTotalCount =
    !isUserError && !isUserLoading && usersData ? usersData?.body?.total : 0;
  const data = React.useMemo(() => userData, [usersData]);

  const boxBg = useColorModeValue("white", "whiteAlpha.100");
  const textColor = useColorModeValue("#1b254b", "white");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [smsMessageObject, setSmsMessageObject] = useState({});

  const [notificationType, setNotificationType] = useState("INFO");
  const [audio] = useState(new Audio(""));
  const [resLoading, setResLoading] = useState(false);
  const [processDone, setProcessDone] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();
  const [type, setType] = useState("in-app");
  const isError = false;
  const toast = useToast();

  const columns = useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "Employee Id",
            accessor: "empId",
          },
          {
            Header: "First Name",
            accessor: "firstName",
          },
          {
            Header: "Last Name",
            accessor: "lastName",
          },
          {
            Header: "Contact Number",
            accessor: "contactNumber",
          },
          {
            Header: "Nationality",
            accessor: "nationality",
          },
          {
            Header: "Room",
            accessor: "room",
          },
          {
            Header: "Property",
            accessor: "property",
          },
          {
            Header: "Position",
            accessor: "position",
          },
          {
            Header: "Department",
            accessor: "department",
          },
        ],
      },
    ],
    []
  );

  const resetNotification = () => {
    setPhoneNumber("");
    setSubject("");
    setMessage("");
    setTitle("");
    setResLoading(false);
    setProcessDone(false);
    reset();
  };

  const resetFunction = () => {
    setSubject("");
    setTitle("");
    setMessage("");
    setPhoneNumber("");
    setResLoading(false);
    setProcessDone(false);
  };

  const handleProcess = async () => {
    let step1flag = false;
    if (activeStep === 0) {
      if (phoneNumber.length < 9) {
        setError(true);
      } else {
        nextStep();
        setError(false);
      }
    }
    if (activeStep === 1) {
      if (subject.length < 2) {
        setError(true);
        step1flag = true;
      }
      if (message.length === 0) {
        setError(true);
        step1flag = true;
      }
      if (type === "in-app" && message.length > 1000) {
        setError(true);
        step1flag = true;
      }
      if (!step1flag) {
        nextStep();
        setError(false);
      }
    }
    if (activeStep === 2) {
      if (phoneNumber && message && subject) {
        setResLoading(true);

        let payload = {
          notificationBody: {
            name: subject,
            channels: type === "in-app" ? ["IN_APP"] : ["SMS"],
            message: message,
            level: type === "in-app" ? notificationType : "INFO",
            title: subject,
          },
          phones: [`971${phoneNumber}`],
        };

        fetcher("notification/phones", payload)
          .then(async (res) => {
            setResLoading(false);
            setProcessDone(true);

            audio.src = "/sounds/mail_sent.mp3";
            await audio.play();
            toast({
              title: `Notification sent successfully`,
              description: `Notification has been sent.`,
              status: "success",
              duration: 4000,
              isClosable: true,
              onCloseComplete: async () => {
                resetFunction();
                reset();
              },
            });
          })
          .catch(async (e) => {
            setResLoading(false);

            audio.src = "/sounds/error.mp3";
            await audio.play();
            toast({
              title: "Something went wrong.",
              description: e.message,
              status: "error",
              duration: 4000,
              isClosable: true,
            });
          });
      }
    }
  };

  const phoneNumberValidator = (value) => {
    const maxLength = 9;
    setPhoneNumber(value);
    if (value.length > maxLength) {
      setPhoneNumber(value.slice(0, maxLength));
    }
  };

  const handleSelectChannel = (e) => {
    setType(e.target.value);
    console.log(e.target.value);
  };

  const handleMessage = (e) => {
    if (type === "in-app" && e.target.value.length <= 1000) {
      setSmsMessageObject(count(e.target.value));
      setMessage(e.target.value);
    } else if (type === "sms") {
      setSmsMessageObject(count(e.target.value));
      setMessage(e.target.value);
    }
  };

  const handleDrawer = (data) => {
    setPhoneNumber(data.contactNumber.slice(3));
    toast({
      title: `User ${data.contactNumber} selected`,
      description: `Selected user will receive notification`,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    onOpenReset();
  };

  const handleSearchText = (data) => {
    setSearchText(data);
    setPage(1);
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Flex
        flexDir="column"
        width="100%"
        bg={boxBg}
        p={5}
        borderRadius={20}
        shadow={"sm"}
      >
        <Steps colorScheme="pink" activeStep={activeStep}>
          {steps.map(({ label, description, id }, index) => (
            <Step
              label={label}
              key={label}
              description={description}
              textColor={"#fff"}
            >
              {id == 1 ? (
                <Flex px={4} pt={8} width="100%" flexDirection="column">
                  <MainTable
                    loaded={!isUserLoading}
                    data={data}
                    columns={columns}
                    setPage={setPage}
                    setPerPage={setPer_page}
                    searchText={searchText}
                    handleSearchText={handleSearchText}
                    currentpage={page}
                    perPage={per_page}
                    exports={userRole === "Admin" ? userData : false}
                    totalPage={parseInt(usersData?.body?.pages)}
                    drawer={true}
                    handleDrawer={handleDrawer}
                    name={`All Users (${numberWithCommas(usersTotalCount)})`}
                    setSortConfig={setSortConfig}
                    setFilterConfig={setFilterConfig}
                  />
                  <AlertDialog isOpen={isOpenReset} onClose={onCloseReset}>
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                          {"Selected User"}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                          {`You selected 971${phoneNumber} user. Do you want to go to next step or continue browsing?`}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                          <Button
                            colorScheme="red"
                            onClick={() => {
                              onCloseReset();
                              handleProcess();
                            }}
                          >
                            {"Next Step"}
                          </Button>
                          <Button ml={3} onClick={onCloseReset}>
                            Close
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </Flex>
              ) : null}

              {id == 2 ? (
                <Flex px={4} pt={8} width="100%" flexDirection="column">
                  <Heading fontSize="md">{label}</Heading>
                  <FormControl isInvalid={isError} width={"350px"} pt={5}>
                    <FormLabel pt={3} htmlFor="selectedUser">
                      Selected User
                    </FormLabel>
                    <Input
                      id="selectedUser"
                      type="text"
                      value={`971${phoneNumber}`}
                      color={textColor}
                      isInvalid={error}
                      disabled={true}
                    />
                    <FormLabel>Channel</FormLabel>
                    <Select value={type} onChange={handleSelectChannel}>
                      <option selected value={"in-app"}>
                        IN APP
                      </option>
                      {!userRole.includes("Corporate") && (
                        <option value={"sms"}>SMS</option>
                      )}
                    </Select>
                    <FormLabel pt={3} htmlFor="subject">
                      Title
                    </FormLabel>
                    <Input
                      id="subject"
                      type="text"
                      value={subject}
                      color={textColor}
                      isInvalid={error}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                    {!isError ? (
                      <FormHelperText>
                        Enter a notification subject.
                      </FormHelperText>
                    ) : (
                      <FormErrorMessage>Subject is required.</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={isError} width={"350px"} pt={5}>
                    <FormLabel htmlFor="message">Message</FormLabel>

                    <Textarea
                      id={"message"}
                      value={message}
                      onChange={(e) => handleMessage(e)}
                      isInvalid={error}
                      size="sm"
                    />
                    {!isError ? (
                      <>
                        <Flex
                          flexDir={"row"}
                          justifyContent={"space-between"}
                        ></Flex>
                        <Flex flexDir={"row"} justifyContent={"space-between"}>
                          <FormHelperText>
                            Enter the notification message.
                          </FormHelperText>
                          <FormHelperText>
                            {message.length !== 0
                              ? `${message.length} (${
                                  type === "sms"
                                    ? smsMessageObject?.characterPerMessage
                                    : 1000
                                } per msg)`
                              : null}
                          </FormHelperText>
                        </Flex>
                      </>
                    ) : (
                      <FormErrorMessage>Message is required.</FormErrorMessage>
                    )}
                    {smsMessageObject?.messages > 1 && type === "sms" && (
                      <FormHelperText>
                        SMS Count{" "}
                        <Badge ml={1} colorScheme="red">
                          {smsMessageObject?.messages
                            ? smsMessageObject?.messages
                            : 1}
                        </Badge>
                        <Popover isLazy placement="right">
                          <PopoverTrigger>
                            <IconButton
                              size="sm"
                              justifyContent={"center"}
                              pb={1}
                              icon={
                                <Player
                                  autoplay
                                  loop
                                  speed={0.1}
                                  src="https://assets7.lottiefiles.com/packages/lf20_Z8MOn3.json"
                                  style={{ height: "35px", width: "35px" }}
                                ></Player>
                              }
                            />
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverHeader fontWeight="semibold">
                              B2Connect SMS Counter
                            </PopoverHeader>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody>
                              Seems, the entered sms characters exceeded and
                              reached {smsMessageObject.messages} SMS - It may
                              incur an additional cost & count!
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </FormHelperText>
                    )}
                  </FormControl>
                </Flex>
              ) : null}

              {id == 3 ? (
                <Flex px={4} pt={8} width="100%" flexDirection="column">
                  <Heading fontSize="md">{label} 3</Heading>
                  <TableContainer>
                    <Table variant="simple">
                      <TableCaption>
                        Check the current information and confirm
                      </TableCaption>
                      <Thead>
                        <Tr>
                          <Th>Channel</Th>
                          <Th>Title</Th>
                          <Th>Message</Th>
                          <Th>Phone Number</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>{type === "in-app" ? ["IN APP"] : ["SMS"]}</Td>
                          <Td>{subject}</Td>
                          <Td>{message}</Td>
                          <Td>{`971${phoneNumber}`}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
              ) : null}
            </Step>
          ))}
        </Steps>
        {activeStep === steps.length ? (
          <Flex px={4} py={4} width="100%" flexDirection="column">
            <Heading fontSize="xl" textAlign="center">
              Woohoo! All steps completed!
            </Heading>
            <Button mx="auto" mt={6} size="sm" onClick={resetNotification}>
              Reset
            </Button>
          </Flex>
        ) : (
          <Flex width="100%" flexDir={"row"} justify="space-between" pt={5}>
            <Flex>
              <Button size="sm" onClick={resetNotification}>
                Reset
              </Button>
            </Flex>
            <Flex flexDir={"row"}>
              <Button
                isDisabled={activeStep === 0}
                mr={4}
                onClick={prevStep}
                size="sm"
                variant="ghost"
              >
                Prev
              </Button>
              <Button
                size="sm"
                onClick={handleProcess}
                isLoading={resLoading}
                loadingText={"Sending"}
                isDisabled={processDone || resLoading}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
