import React from 'react';
import {Box, Button, Flex, SimpleGrid, useColorModeValue} from "@chakra-ui/react";
import MiniStatistics from "../../../components/card/MiniStatistics";
import IconBox from "../../../components/icons/IconBox";
import {Player} from "@lottiefiles/react-lottie-player";
import {Link} from "react-router-dom";

function Index(props) {
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Flex>
                <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
                    gap="20px"
                    mb="20px"
                >
                    <MiniStatistics
                        loaded={true}
                        startContent={
                            <IconBox
                                w="56px"
                                h="56px"
                                bg={boxBg}
                                icon={
                                    <Player
                                        autoplay
                                        loop
                                        src="https://assets3.lottiefiles.com/packages/lf20_dzdrmlop.json"
                                        style={{ height: "120px", width: "120px" }}
                                    ></Player>
                                }
                            />
                        }
                        name="Add new"
                        value={
                            <div p={3}>
                                <Button>
                                    <Link to={"/dashboard/announcement/new"}>Create</Link>{" "}
                                </Button>
                            </div>
                        }
                    />
                </SimpleGrid>
            </Flex>

            <Flex>

            </Flex>
        </Box>
    );
}

export default Index;