import React from "react";

// chakra imports
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

// Assets
import { IoMenuOutline } from "react-icons/io5";
import { useAuth0 } from "@auth0/auth0-react";

function Sidebar(props) {
  const { routes } = props;
  let variantChange = "0.2s linear";
  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("#E0457B", "navy.800");
  let sidebarMargins = "0px";
  const { user } = useAuth0();

  const userRole = user?.["https://admin.b2connect.me/roles"][0];

  switch (userRole) {
    case "Admin":
      for (let i = 0; i < routes.length; i++) {
        if (
          routes[i].name === "Support Tools" ||
          routes[i].name === "Order Verification" ||
          routes[i].name === "Corporate Admin"
        ) {
          routes.splice(i, 1);
        }
      }
      break;
    case "ProductManager":
      for (let i = 0; i < routes.length; i++) {
        if (
          routes[i].name === "Notifications" ||
          routes[i].name === "Surveys" ||
          routes[i].name === "Announcements" ||
          routes[i].name === "Dashboard" ||
          routes[i].name === "Reports" ||
          routes[i].name === "Corporate Admin" ||
          routes[i].name.includes("Boarding") ||
          routes[i].name === "Users" ||
          routes[i].name === "Assets"
        ) {
          routes.splice(i, 1);
        }
      }
      break;
    case "B2 Sales Manager":
      for (let i = 0; i < routes.length; i++) {
        if (
          routes[i].name === "Notifications" ||
          routes[i].name === "Surveys" ||
          routes[i].name === "Announcements" ||
          routes[i].name === "Dashboard" ||
          routes[i].name === "Reports" ||
          routes[i].name === "Order Verification" ||
          routes[i].name === "Corporate Admin" ||
          routes[i].name.includes("Boarding") ||
          routes[i].name === "Users" ||
          routes[i].name === "Assets"
        ) {
          routes.splice(i, 1);
        }
      }
      break;
    case "B2 Support":
      for (let i = 0; i < routes.length; i++) {
        if (
          routes[i].name === "Notifications" ||
          routes[i].name === "Surveys" ||
          routes[i].name === "Announcements" ||
          routes[i].name === "Dashboard" ||
          routes[i].name === "Reports" ||
          routes[i].name === "Corporate Admin" ||
          routes[i].name.includes("Boarding") ||
          routes[i].name === "Users" ||
          routes[i].name === "Assets"
        ) {
          routes.splice(i, 1);
        }
      }
      break;
    case "Technician":
      for (let i = 0; i < routes.length; i++) {
        if (
          routes[i].name === "Notifications" ||
          routes[i].name === "Surveys" ||
          routes[i].name === "Announcements" ||
          routes[i].name === "Dashboard" ||
          routes[i].name === "Reports" ||
          routes[i].name === "Corporate Admin" ||
          routes[i].name.includes("Boarding") ||
          routes[i].name === "Users" ||
          routes[i].name === "Support Tools" ||
          routes[i].name === "Order Verification" ||
          routes[i].name === "Product Sales" ||
          routes[i].name === "Assets Analytics" ||
          routes[i].name === "Packages"
        ) {
          routes.splice(i, 1);
        }
      }
    case "AssetManager":
      for (let i = 0; i < routes.length; i++) {
        if (
          routes[i].name === "Notifications" ||
          routes[i].name === "Surveys" ||
          routes[i].name === "Announcements" ||
          routes[i].name === "Dashboard" ||
          routes[i].name === "Reports" ||
          routes[i].name === "Corporate Admin" ||
          routes[i].name.includes("Boarding") ||
          routes[i].name === "Users" ||
          routes[i].name === "Support Tools" ||
          routes[i].name === "Order Verification" ||
          routes[i].name === "Product Sales" ||
          routes[i].name === "Packages"
        ) {
          routes.splice(i, 1);
        }
      }
      break;
    case "Corporate Admin":
      for (let i = 0; i < routes.length; i++) {
        if (
          routes[i].name === "Support Tools" ||
          routes[i].name === "Order Verification" ||
          routes[i].name === "Product Sales" ||
          routes[i].name === "Assets" ||
          routes[i].name === "Packages"
        ) {
          routes.splice(i, 1);
        }
      }
      break;
    case "Corporate User":
      for (let i = 0; i < routes.length; i++) {
        if (
          routes[i].name === "Support Tools" ||
          routes[i].name === "Order Verification" ||
          routes[i].name === "Corporate Admin" ||
          routes[i].name === "Product Sales" ||
          routes[i].name === "Assets" ||
          routes[i].name === "Packages"
        ) {
          routes.splice(i, 1);
        }
      }
      break;
  }

  if (
    user?.["https://admin.b2connect.me/app_metadata"]?.locations !== "ALDAR" &&
    user?.["https://admin.b2connect.me/app_metadata"]?.locations !== "EO" &&
    user?.["https://admin.b2connect.me/app_metadata"]?.locations !==
      "ECC 22 JABEL ALI" &&
    user?.["https://admin.b2connect.me/app_metadata"]?.locations !==
      "ECC Sharjah" &&
    user?.["https://admin.b2connect.me/app_metadata"]?.locations !==
      "ECC AL QUOS (09)" &&
    user?.["https://admin.b2connect.me/app_metadata"]?.locations !== "JAM"
  ) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].name.includes("Boarding") || routes[i].name === "Users") {
        routes.splice(i, 1);
      }
    }
  }

  if (user?.["https://admin.b2connect.me/app_metadata"]?.isSales === true) {
    for (let i = 0; i < routes.length; i++) {
      if (
        routes[i].name.includes("boarding") ||
        routes[i].name.includes("Dashboard") ||
        routes[i].name === "Users"
      ) {
      } else {
        routes.splice(i, 1);
      }
    }
  }

  // if (userRole !== "ProductManager" && userRole !== "Admin" && userRole !== "B2 Sales Agent") {
  //   for (let i = 0; i < routes.length; i++) {
  //     if(userRole === "Admin") {
  //       if (routes[i].name === "Support Tools" || routes[i].name === "Order Verification") {
  //         routes.splice(i, 1);
  //       }
  //     }
  //     if (routes[i].name === "Product Sales" || routes[i].name === "Reports" || routes[i].name === "Support Tools" || routes[i].name === "Order Verification") {
  //       routes.splice(i, 1);
  //     }
  //   }
  // } else if(userRole === "ProductManager") {
  //   for (let i = 0; i < routes.length; i++) {
  //     if (routes[i].name === "Notifications") {
  //       routes.splice(i, 1);
  //     }
  //
  //     if (routes[i].name === "Surveys") {
  //       routes.splice(i, 1);
  //     }
  //     if (routes[i].name === "Announcements") {
  //       routes.splice(i, 1);
  //     }
  //     if (routes[i].name === "Dashboard") {
  //       routes.splice(i, 1);
  //     }
  //     if (routes[i].name === "Reports") {
  //       routes.splice(i, 1);
  //     }
  //   }
  // }else{
  //   for (let i = 0; i < routes.length; i++) {
  //     if (routes[i].name === "Support Tools" || routes[i].name === "Order Verification") {
  //       routes.splice(i, 1);
  //     }
  //   }
  //
  // }

  // SIDEBAR
  return (
    <Box display={{ sm: "none", xl: "block" }} position="fixed" minH="100%">
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w="300px"
        h="100vh"
        m={sidebarMargins}
        minH="100%"
        overflowX="hidden"
        borderTopRightRadius={20}
        borderBottomRightRadius={20}
        boxShadow={shadow}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}
        >
          <Content routes={routes.filter((item) => item.sidebar === true)} />
        </Scrollbars>
      </Box>
    </Box>
  );
}

// FUNCTIONS
export function SidebarResponsive(props) {
  let sidebarBackgroundColor = useColorModeValue("#E0457B", "navy.800");
  let menuColor = useColorModeValue("gray.400", "white");

  // // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const { routes } = props;
  //  BRAND

  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems="center">
      <Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen}>
        <Icon
          as={IoMenuOutline}
          color={menuColor}
          my="auto"
          w="20px"
          h="20px"
          me="10px"
          _hover={{ cursor: "pointer" }}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent w="285px" maxW="285px" bg={sidebarBackgroundColor}>
          <DrawerCloseButton
            zIndex="3"
            onClose={onClose}
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody maxW="285px" px="0rem" pb="0">
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}
            >
              <Content routes={routes} />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
// PROPS

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

export default Sidebar;
