import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  SimpleGrid,
  Skeleton,
  Spinner,
  Tab,
  Table,
  TabList,
  Tabs,
  Tag,
  TagCloseButton,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import {
  MdArrowDropDown,
  MdCheckCircle,
  MdCloudDownload,
  MdOutlineFilterAlt,
} from "react-icons/md";
import { FaBoxes } from "react-icons/fa";

import Card from "../../../components/card/Card";
import { CSVLink } from "react-csv";
import { Player } from "@lottiefiles/react-lottie-player";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import OrderDrawer from "./components/OrderDrawer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useLocations,
  useSales,
  useSalesPersons,
  useStatus,
} from "../../../lib/hooks";
import { mutate } from "swr";
import moment from "moment/moment";
import IconBox from "../../../components/icons/IconBox";
import { usePagination, useTable } from "react-table";

function Sales(props) {
  let statusSelectedItems = [];
  let locationSelectedItems = [];

  const [pageHook, setPageHook] = useState(1);
  const [per_page, setPer_page] = useState(5);
  const [selectedOrder, setSelectedOrder] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filteredData, setFilterdData] = useState([]);
  const [filterNotFound, setFilterNotFound] = useState(false);
  const [orderRef, setOrderRef] = useState("");
  const [invoiceRef, setInvoiceRef] = useState("");
  const [agentRef, setAgentRef] = useState("");
  const [mobileRef, setMobileRef] = useState("");
  const [emiratesRef, setEmiratesRef] = useState("");
  const [emiDues, setEmiDues] = useState(false);

  const [filterTotalCount, setFilterTotalCount] = useState(0);
  const [filterArray, setFilterArray] = useState([]);
  const [filterArrayPayload, setFilterArrayPayload] = useState("");
  const [filterLoading, setFilterLoading] = useState(false);
  const [selectRef, setSelectRef] = useState("");
  const [locationSelectRef, setLocationSelectRef] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { user, isLoading: userLoading, error } = useAuth0();
  const [mounted, setMounted] = useState(false);
  const {
    sales,
    isError: salesError,
    isLoading,
  } = useSales(mounted, pageHook, per_page, filterArrayPayload);
  const {
    salesPersons,
    isLoading: salesPersonLoading,
    isError: salesPersonError,
  } = useSalesPersons();
  const [statusItemSelect, setStatusItemSelect] = useState(statusSelectedItems);
  const [locationItemSelect, setLocationItemSelect] = useState(
    locationSelectedItems
  );
  const [filterCount, setFilterCount] = useState(0);
  const boxesColor = useColorModeValue("white", "#1B254B");

  useEffect(() => {
    const token = localStorage.getItem("auth0_token");
    if (user && !userLoading && token) {
      setMounted(true);
      mutate(`orders/?page=${pageHook}&per-page=${per_page}`);
    }
  }, [onClose, onOpen]);

  const userRole = user?.["https://admin.b2connect.me/roles"][0];
  const {
    status: orderStatus,
    isError: OstatusError,
    isLoading: OstatusLoading,
  } = useStatus(mounted);

  const {
    locations,
    isError: locationError,
    isLoading: loactionLoading,
  } = useLocations(mounted);
  const {
    status,
    isError: statusError,
    isLoading: statusLoading,
  } = useStatus(mounted);
  const salesData =
    !salesError && !isLoading && sales && filteredData.length == 0
      ? sales?.body?.items?.map((item, i) => ({
          date: (
            <Flex w={"max-content"}>
              {" "}
              <Tooltip
                label={moment(item.time)
                  .utc()
                  .format("MMMM Do YYYY, h:mm:ss a")}
              >
                {moment(item.time).utc().format("LL")}
              </Tooltip>
            </Flex>
          ),
          dateEX: item.time,

          orderNo: item.salesOrderNo ? (
            <Flex>
              {" "}
              <Text w={"max-content"}>{item.salesOrderNo}</Text>
            </Flex>
          ) : (
            <>
              <Text color={"gray"}>No Order #ID</Text>
            </>
          ),
          orderNoEX: item.salesOrderNo,

          salesOrderId: item.orderId ? (
            <Flex>
              {" "}
              <Text w={"max-content"}>{item.orderId}</Text>
            </Flex>
          ) : (
            <>
              <Text color={"gray"}>No Order #ID</Text>
            </>
          ),
          invoiceNo: item.invoiceNo ? (
            <Flex w={"max-content"}>{item.invoiceNo}</Flex>
          ) : (
            <>
              <Text color={"gray"} w={"max-content"}>
                No Invoice Number
              </Text>
            </>
          ),
          invoiceNoExport: item.invoiceNo,
          status: (
            <Badge>
              {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
            </Badge>
          ),
          source:
            item.source == "APP" ? (
              <Flex flexDir={"row"}>
                <IconBox
                  w="22px"
                  h="22px"
                  icon={
                    <Player
                      autoplay
                      loop
                      src="https://assets1.lottiefiles.com/private_files/lf30_xtkkgbco.json"
                      style={{ height: "22px", width: "22px" }}
                    ></Player>
                  }
                />

                <IconBox
                  w="22px"
                  h="22px"
                  icon={
                    <Player
                      autoplay
                      loop
                      src="https://assets2.lottiefiles.com/temp/lf20_dFXDxA.json"
                      style={{ height: "52px", width: "52px" }}
                    ></Player>
                  }
                />
              </Flex>
            ) : (
              <span>{item.source}</span>
            ),
          location: item.userZone ? (
            <Flex w={"max-content"}>{item.userZone}</Flex>
          ) : (
            <>
              <Text color={"gray"} w={"max-content"}>
                No Location
              </Text>
            </>
          ),
          phone: item.userPhone ? (
            item.userPhone
          ) : (
            <>
              <Text color={"gray"}>Not Provided</Text>
            </>
          ),
          locationExport: item.userZone,
          total: `AED ${item.total}`,
          orderId: item.orderId,
          statusExport: item.status,
          sourceExport: item.source,
        }))
      : filteredData?.map((item) => ({
          date: moment(item.time).utc().format("LL"),
          orderNo: item.salesOrderNo,
          invoiceNo: item.invoiceNo,
          salesAgent: item.salesAgentCode,
          status: (
            <Badge>
              {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
            </Badge>
          ),

          source:
            item.source == "APP" ? (
              <Flex flexDir={"row"}>
                <IconBox
                  w="22px"
                  h="22px"
                  icon={
                    <Player
                      autoplay
                      loop
                      src="https://assets1.lottiefiles.com/private_files/lf30_xtkkgbco.json"
                      style={{ height: "22px", width: "22px" }}
                    ></Player>
                  }
                />

                <IconBox
                  w="22px"
                  h="22px"
                  icon={
                    <Player
                      autoplay
                      loop
                      src="https://assets2.lottiefiles.com/temp/lf20_dFXDxA.json"
                      style={{ height: "52px", width: "52px" }}
                    ></Player>
                  }
                />
              </Flex>
            ) : (
              <span>{item.source} 2</span>
            ),
          location: item.userZone,
          phone: "0" + item.userPhone.substring(3),
          method: item.paymentMethod,
          total: item.total,
          orderId: item.orderId,
          statusExport: item.status,
          locationExport: item.userZone,
          sourceExport: item.source,
        }));

  const data = React.useMemo(() => salesData, [sales, filteredData]);

  let exportData = [];

  data.forEach((item) => {
    exportData.push({
      Date: moment(item?.dateEX).utc().format("MMMM Do YYYY, h:mm:ss a"),
      "Order Number": item?.orderNoEX,
      "Invoice Number": item?.invoiceNoExport,
      Status: item?.statusExport,
      Source: item?.sourceExport,
      Location: item?.locationExport,
      "Phone number": item?.phone,
      Total: item?.total,
      "Order #ID": item?.orderId,
    });
  });

  const columns = React.useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "Date",
            accessor: "date",
          },
          {
            Header: "Order #",
            accessor: "orderNo",
          },
          {
            Header: "Internal Order ID",
            accessor: "salesOrderId",
          },
          {
            Header: "Invoice #",
            accessor: "invoiceNo",
          },
          {
            Header: "Location",
            accessor: "location",
          },
          {
            Header: "Mobile Number",
            accessor: "phone",
          },
        ],
      },
      {
        Header: " ",
        columns: [
          {
            Header: "Status",
            accessor: "status",
          },
          {
            Header: "Total",
            accessor: "total",
          },
        ],
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: pageHook,
          }),
          [state, pageHook]
        );
      },
      initialState: { pageIndex: pageHook },
      manualPagination: true,
      pageCount: 20,
    },
    usePagination
  );
  const filterButtonReset = () => {
    setOrderRef("");
    setMobileRef("");
    setInvoiceRef("");
    setAgentRef("");
    setSelectedOrder(0);
    setFilterdData([]);
    setFilterNotFound(false);
    setFilterTotalCount(0);
    setFilterArray([]);
    setFilterArrayPayload("");
    setSelectRef("");
    setLocationSelectRef("");
    setStatusItemSelect([]);
    setLocationItemSelect([]);
    setEmiDues(false);
    setEmiratesRef("");
    setFilterCount(0);
    setPageHook(1);
  };

  const orderInit = (item) => {
    setSelectedOrder({});
    if (item) {
      setSelectedOrder(item);
      onOpen();
    }
  };
  const handleSelectFilter = async (event) => {
    const statusElm = {
      status: event.target.value,
      active: true,
    };
    setStatusItemSelect((statusSelectedItems) => [
      ...statusSelectedItems,
      statusElm,
    ]);
  };

  const handleLocationFilter = async (event) => {
    const locationElement = {
      location: event.target.value,
      active: true,
    };
    setLocationItemSelect((locationItemSelect) => [
      ...locationItemSelect,
      locationElement,
    ]);
  };
  const handleAdvancedFilter = (event, type) => {
    if (type === "status") {
      handleSelectFilter(event);
    }
    if (type === "location") {
      handleLocationFilter(event);
    }
  };

  const statusListRemove = (event) => {
    const filterd = statusItemSelect.filter((item) => item.status !== event);
    setStatusItemSelect(filterd);
  };

  const locationListRemove = (event) => {
    const filterd = locationItemSelect.filter(
      (item) => item.location !== event
    );
    setLocationItemSelect(filterd);
  };

  const handleMultiFilter = () => {
    setFilterLoading(true);

    let filterArrayAdvanced = [];

    if (emiDues) {
      filterArrayAdvanced.push({
        type: "IS_NEXT_DUE",
        value: "TRUE",
      });
      setFilterCount(filterCount + 1);
      setPageHook(1);
    }

    if (emiratesRef) {
      filterArrayAdvanced.push({
        type: "EMIRATES_ID",
        value: emiratesRef,
      });
      setFilterCount(filterCount + 1);
      setPageHook(1);
    }
    if (mobileRef) {
      var mobD = mobileRef;
      if (mobD.slice(0, 3) === "971") {
        filterArrayAdvanced.push({
          type: "MOBILE_NUMBER",
          value: mobD.slice(3),
        });
      } else if (mobD.charAt(0) == "0") {
        filterArrayAdvanced.push({
          type: "MOBILE_NUMBER",
          value: mobD.slice(1),
        });
      } else if (mobD.charAt(0) == "5") {
        filterArrayAdvanced.push({
          type: "MOBILE_NUMBER",
          value: mobD,
        });
      }

      setFilterCount(filterCount + 1);
      setPageHook(1);
    }
    if (agentRef) {
      filterArrayAdvanced.push({
        type: "SALES_AGENT",
        value: agentRef,
      });
      setFilterCount(filterCount + 1);
      setPageHook(1);
    }
    if (invoiceRef) {
      filterArrayAdvanced.push({
        type: "INVOICE_NO",
        value: `INV-${invoiceRef}`,
      });
      setFilterCount(filterCount + 1);
      setPageHook(1);
    }
    if (orderRef) {
      filterArrayAdvanced.push({
        type: "ORDER_NO",
        value: `SO-${orderRef}`,
      });
      setFilterCount(filterCount + 1);
      setPageHook(1);
    }
    if (statusItemSelect.length > 0) {
      let statusesArray = [];
      statusItemSelect.forEach((item) => {
        statusesArray.push(item.status);
      });
      setFilterCount(filterCount + 1);

      filterArrayAdvanced.push({
        type: "STATUS",
        value: statusesArray.toString().replaceAll(",", ";"),
      });
    }
    if (locationItemSelect.length > 0) {
      let locationsArrayS = [];
      locationItemSelect.forEach((item) => {
        locationsArrayS.push(item.location);
      });
      setFilterCount(filterCount + 1);

      filterArrayAdvanced.push({
        type: "LOCATION",
        value: locationsArrayS.toString().replaceAll(",", ";"),
      });
      setPageHook(1);
    }

    let arrayToGo = [];
    filterArrayAdvanced.forEach((item) => {
      arrayToGo.push(`${item.type}:${item.value}`);
    });
    setFilterArrayPayload(arrayToGo.toString());

    setFilterLoading(false);
    setPageHook(1);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function capitalizeWords(string) {
    return string.replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  }

  const filtersCountFn = () => {
    let count = 0;
    if (statusItemSelect.length > 0) {
      count += statusItemSelect.length;
    }
    if (locationItemSelect.length > 0) {
      count += locationItemSelect.length;
    }
    if (agentRef) {
      count += 1;
    }
    if (emiDues) {
      count += 1;
    }
    if (emiratesRef) {
      count += 1;
    }
    if (mobileRef) {
      count += 1;
    }
    if (invoiceRef) {
      count += 1;
    }
    if (orderRef) {
      count += 1;
    }
    return count;
  };
  return (
    <>
      <Flex mt={20} flexDir={"row"} justifyContent={"space-between"}>
        <Flex>
          <Tabs size={"lg"} colorScheme={"pink"}>
            <TabList>
              <Tab>All Orders</Tab>
              {/*<Tab  ><Link to={"/dashboard/order-analytics"}>Orders Analytics</Link> </Tab>*/}
              {/*<Tab>Completed</Tab>*/}
              {/*<Tab>Cancelled</Tab>*/}
            </TabList>
          </Tabs>
        </Flex>
        {/*<Flex>*/}
        {/*    <Text>Data range(Comming)</Text>*/}
        {/*</Flex>*/}
      </Flex>
      <Flex></Flex>
      <SimpleGrid mt={10} columns={{ sm: 1, md: 1, lg: 3 }} spacing={5}>
        <Skeleton
          isLoaded={!isLoading && !salesError}
          fadeDuration={1.5}
          borderRadius={20}
        >
          <Box
            bg={boxesColor}
            h={120}
            borderRadius={10}
            borderColor={"#80808047"}
            borderWidth={0.5}
            boxShadow={"sm"}
          >
            <Flex flexDir={"row"} m={5}>
              <Flex>
                <Flex bg={"#e0457b6b"} borderRadius={100}>
                  <Icon p={5} as={FaBoxes} color={"white"} w={20} h={20} />
                </Flex>
              </Flex>
              <Flex flexDir={"column"} mx={5} justifyContent={"center"}>
                <Flex>
                  <Text fontSize={"xl"} color={textColor}>
                    Total Orders
                  </Text>
                </Flex>
                <Flex>
                  <Text fontSize={"2xl"} color={textColor} fontWeight={600}>
                    {sales?.body?.total}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Skeleton>

        {/*<Skeleton  isLoaded={!isLoading && !salesError}  fadeDuration={1.5} borderRadius={20}>*/}

        {/*    <Box bg='white' h={120} borderRadius={10} borderColor={"#80808047"} borderWidth={0.5} boxShadow={"sm"}>*/}
        {/*        <Flex flexDir={"row"} m={5}>*/}
        {/*            <Flex >*/}
        {/*                <Flex bg={"#f6ad55d6"} borderRadius={100}>*/}
        {/*                    <Icon p={5}  as={MdOutlinePendingActions} color={"white"} w={20} h={20}/>*/}

        {/*                </Flex>*/}
        {/*            </Flex>*/}
        {/*            <Flex flexDir={"column"} mx={5} justifyContent={"center"}>*/}
        {/*                <Flex >*/}
        {/*                    <Text fontSize={"xl"} color={"gray"}>*/}
        {/*                        Pending Orders*/}
        {/*                    </Text>*/}
        {/*                </Flex>*/}
        {/*                <Flex>*/}
        {/*                    <Text fontSize={"2xl"} color={"black"} fontWeight={600}>*/}
        {/*                        -*/}
        {/*                    </Text>*/}
        {/*                </Flex>*/}
        {/*            </Flex>*/}
        {/*        </Flex>*/}
        {/*    </Box>*/}
        {/*</Skeleton>*/}

        {/*<Skeleton  isLoaded={!isLoading && !salesError}  fadeDuration={1.5} borderRadius={20}>*/}
        {/*    <Box bg='white' h={120} borderRadius={10} borderColor={"#80808047"} borderWidth={0.5} boxShadow={"sm"}>*/}

        {/*        <Flex flexDir={"row"} m={5}>*/}
        {/*            <Flex >*/}
        {/*                <Flex bg={"gray.900"} borderRadius={100}>*/}
        {/*                    <Icon p={5}  as={TbTruckDelivery} color={"white"} w={20} h={20}/>*/}
        {/*                </Flex>*/}
        {/*            </Flex>*/}
        {/*            <Flex flexDir={"column"} mx={5} justifyContent={"center"}>*/}
        {/*                <Flex >*/}
        {/*                    <Text fontSize={"xl"} color={"gray"}>*/}
        {/*                        Delivered Orders*/}
        {/*                    </Text>*/}
        {/*                </Flex>*/}
        {/*                <Flex>*/}
        {/*                    <Text fontSize={"2xl"} color={"black"} fontWeight={600}>*/}
        {/*                        -*/}
        {/*                    </Text>*/}
        {/*                </Flex>*/}
        {/*            </Flex>*/}
        {/*        </Flex>*/}
        {/*    </Box>*/}
        {/*</Skeleton>*/}
      </SimpleGrid>

      <Box>
        <Box pt={{ base: "180px", md: "80px", xl: "30px" }}>
          <Skeleton
            isLoaded={!isLoading && !salesError}
            fadeDuration={1.5}
            borderRadius={20}
          >
            <Card
              direction="column"
              w="100%"
              px="0px"
              overflowX={{ sm: "scroll", lg: "hidden" }}
            >
              <Flex px="25px" justify="space-between" mb="20px" align="center">
                <Flex>
                  {/*<Flex mr={5}>*/}
                  {/*    <Menu>*/}
                  {/*        <MenuButton as={Button} rightIcon={<MdArrowDropDown />}>*/}
                  {/*            Date*/}
                  {/*        </MenuButton>*/}
                  {/*        <MenuList>*/}
                  {/*            <MenuItem>Last Week</MenuItem>*/}
                  {/*            <MenuItem>Last 30 Days</MenuItem>*/}
                  {/*            <MenuItem>Last 3 Months</MenuItem>*/}

                  {/*        </MenuList>*/}
                  {/*    </Menu>*/}
                  {/*</Flex>*/}
                </Flex>
                <Flex>
                  {filterArray.length > 0 && (
                    <Button
                      onClick={() => filterButtonReset()}
                      color={textColor}
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="100%"
                    >
                      Reset
                    </Button>
                  )}

                  {filterLoading && <Spinner style={{ margin: 10 }} />}
                </Flex>

                <Flex flexDir={"row"}>
                  <Flex>
                    <Popover>
                      <PopoverTrigger>
                        <Button
                          boxShadow={"sm"}
                          borderRadius={5}
                          background={"#f6ad55"}
                          color={"whiteAlpha.900"}
                        >
                          <Flex flexDir={"row"}>
                            <Flex mr={3}>
                              <MdOutlineFilterAlt size={20} />
                            </Flex>
                            <Flex>
                              <Text fontWeight={600}>Filters </Text>
                              {filtersCountFn() > 0 && (
                                <Text
                                  ml={2}
                                  borderRadius={20}
                                  background={"white"}
                                  color={"black"}
                                  px={2}
                                  fontWeight={600}
                                >
                                  {filtersCountFn()}
                                </Text>
                              )}
                            </Flex>
                          </Flex>
                        </Button>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent _focus={{ outline: 0 }}>
                          <PopoverArrow />
                          <PopoverHeader fontWeight={600}>
                            Filter Items
                          </PopoverHeader>
                          <PopoverCloseButton />
                          <PopoverBody>
                            <Accordion allowToggle _focus={{ outline: 0 }}>
                              <AccordionItem _focus={{ outline: 0 }}>
                                <h2>
                                  <AccordionButton>
                                    <Box
                                      flex="1"
                                      textAlign="left"
                                      flexDir={"row"}
                                    >
                                      <Flex>
                                        <Text>Order Number</Text>
                                        {orderRef && (
                                          <Text
                                            ml={2}
                                            borderRadius={20}
                                            background={"gray"}
                                            color={"white"}
                                            px={"10px"}
                                            fontWeight={600}
                                          >
                                            1
                                          </Text>
                                        )}
                                      </Flex>
                                    </Box>

                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <Flex flexDir={"column"}>
                                    <Flex>
                                      <Text fontSize={"sm"}>
                                        You can search by Individual order
                                        number
                                      </Text>
                                    </Flex>
                                    <Flex mt={3} flexDir={"row"}>
                                      <Input
                                        color={textColor}
                                        mr={2}
                                        w={"30%"}
                                        disabled
                                        value={"SO"}
                                      />

                                      <Input
                                        color={textColor}
                                        placeholder={"EX: 12345"}
                                        type={"number"}
                                        max={5}
                                        min={0}
                                        onChange={(e) => {
                                          setOrderRef(e.target.value);
                                        }}
                                        value={orderRef}
                                      />
                                    </Flex>
                                  </Flex>
                                </AccordionPanel>
                              </AccordionItem>

                              <AccordionItem _focus={{ outline: 0 }}>
                                <h2>
                                  <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                      <Flex>
                                        <Text>Invoice Number</Text>

                                        {invoiceRef && (
                                          <Text
                                            ml={2}
                                            borderRadius={20}
                                            background={"gray"}
                                            color={"white"}
                                            px={"10px"}
                                            fontWeight={600}
                                          >
                                            1
                                          </Text>
                                        )}
                                      </Flex>
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <Flex flexDir={"column"}>
                                    <Flex>
                                      <Text fontSize={"sm"}>
                                        You can search by Individual invoice
                                        number
                                      </Text>
                                    </Flex>
                                    <Flex mt={3} flexDir={"row"}>
                                      <Input
                                        color={textColor}
                                        mr={2}
                                        w={"30%"}
                                        disabled
                                        value={"INV"}
                                      />

                                      <Input
                                        color={textColor}
                                        placeholder={"EX: 1234"}
                                        type={"number"}
                                        id={"INVOICE_NO"}
                                        onChange={(e) => {
                                          setInvoiceRef(e.target.value);
                                        }}
                                        min={0}
                                        value={invoiceRef}
                                      />
                                    </Flex>
                                  </Flex>
                                </AccordionPanel>
                              </AccordionItem>
                              <AccordionItem _focus={{ outline: 0 }}>
                                <h2>
                                  <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                      <Flex>
                                        <Text>Sales Agent</Text>

                                        {agentRef && (
                                          <Text
                                            ml={2}
                                            borderRadius={20}
                                            background={"gray"}
                                            color={"white"}
                                            px={"10px"}
                                            fontWeight={600}
                                          >
                                            1
                                          </Text>
                                        )}
                                      </Flex>
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <Flex flexDir={"column"}>
                                    <Flex>
                                      <Text fontSize={"sm"}>
                                        You can search by sales agents list
                                      </Text>
                                    </Flex>
                                    <Flex mt={3} flexDir={"row"}>
                                      <Menu placement={"top"}>
                                        <MenuButton
                                          as={Button}
                                          rightIcon={<MdArrowDropDown />}
                                        >
                                          {agentRef ? agentRef : "Sales Agents"}
                                        </MenuButton>
                                        <MenuList
                                          overflowY={"auto"}
                                          maxHeight={300}
                                        >
                                          {salesPersons?.body?.map(
                                            (item, i) => {
                                              return (
                                                <MenuItem
                                                  key={i}
                                                  color={textColor}
                                                  value={item?.salesperson_code}
                                                  fontSize={14}
                                                  onClick={(e) => {
                                                    setAgentRef(e.target.value);
                                                  }}
                                                >
                                                  {capitalizeFirstLetter(
                                                    item?.salesperson_name
                                                  )}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                        </MenuList>
                                      </Menu>

                                      {/*</Select>*/}
                                    </Flex>
                                  </Flex>
                                </AccordionPanel>
                              </AccordionItem>
                              <AccordionItem _focus={{ outline: 0 }}>
                                <h2>
                                  <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                      <Flex>
                                        <Text>Phone Number</Text>

                                        {mobileRef && (
                                          <Text
                                            ml={2}
                                            borderRadius={20}
                                            background={"gray"}
                                            color={"white"}
                                            px={"10px"}
                                            fontWeight={600}
                                          >
                                            1
                                          </Text>
                                        )}
                                      </Flex>
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <Flex flexDir={"column"}>
                                    <Flex>
                                      <Text fontSize={"sm"}>
                                        You can search by phone number
                                      </Text>
                                    </Flex>
                                    <Flex mt={3} flexDir={"row"}>
                                      <Input
                                        color={textColor}
                                        mr={2}
                                        w={"40%"}
                                        disabled
                                        value={"+971"}
                                      />
                                      <Input
                                        color={textColor}
                                        placeholder={"EX: 058123456"}
                                        type={"number"}
                                        min={0}
                                        maxLength={10}
                                        id={"MOBILE_NUMBER"}
                                        onChange={(e) => {
                                          setMobileRef(e.target.value);
                                        }}
                                        value={mobileRef}
                                      />
                                    </Flex>
                                  </Flex>
                                </AccordionPanel>
                              </AccordionItem>
                              <AccordionItem _focus={{ outline: 0 }}>
                                <h2>
                                  <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                      <Flex>
                                        <Text>Emirates ID Number</Text>

                                        {emiratesRef && (
                                          <Text
                                            ml={2}
                                            borderRadius={20}
                                            background={"gray"}
                                            color={"white"}
                                            px={"10px"}
                                            fontWeight={600}
                                          >
                                            1
                                          </Text>
                                        )}
                                      </Flex>
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <Flex flexDir={"column"}>
                                    <Flex>
                                      <Text fontSize={"sm"}>
                                        You can search by emirates id number
                                      </Text>
                                    </Flex>
                                    <Flex mt={3} flexDir={"row"}>
                                      <Input
                                        color={textColor}
                                        placeholder={"EX: 1234567891234"}
                                        min={0}
                                        type={"number"}
                                        value={emiratesRef}
                                        onChange={(e) => {
                                          setEmiratesRef(e.target.value);
                                        }}
                                      />
                                    </Flex>
                                  </Flex>
                                </AccordionPanel>
                              </AccordionItem>
                              <AccordionItem _focus={{ outline: 0 }}>
                                <h2>
                                  <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                      <Flex>
                                        <Text>Status</Text>

                                        {statusItemSelect.length > 0 && (
                                          <Text
                                            ml={2}
                                            borderRadius={20}
                                            background={"gray"}
                                            color={"white"}
                                            px={"10px"}
                                            fontWeight={600}
                                          >
                                            {statusItemSelect.length}
                                          </Text>
                                        )}
                                      </Flex>
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <Flex flexDir={"column"}>
                                    <Flex>
                                      <Text fontSize={"sm"}>
                                        You can search by order status list
                                      </Text>
                                    </Flex>
                                    <Flex mt={2}>
                                      <Wrap>
                                        {statusItemSelect?.map((item, i) => (
                                          <WrapItem key={i}>
                                            <Tag
                                              size={"sm"}
                                              borderRadius="full"
                                              variant="solid"
                                              colorScheme="green"
                                            >
                                              <TagLabel>
                                                {capitalizeFirstLetter(
                                                  item?.status
                                                )}
                                              </TagLabel>
                                              <TagCloseButton
                                                onClick={(e) =>
                                                  statusListRemove(item?.status)
                                                }
                                              />
                                            </Tag>
                                          </WrapItem>
                                        ))}
                                      </Wrap>
                                    </Flex>
                                    <Flex mt={3} flexDir={"row"} zIndex={999}>
                                      <Menu>
                                        <MenuButton
                                          as={Button}
                                          rightIcon={<MdArrowDropDown />}
                                        >
                                          Select status
                                        </MenuButton>
                                        <MenuList>
                                          {status?.body?.statuses?.map(
                                            (item, i) => (
                                              <MenuItem
                                                key={i}
                                                color={textColor}
                                                value={item}
                                                onClick={(e) =>
                                                  handleAdvancedFilter(
                                                    e,
                                                    "status"
                                                  )
                                                }
                                              >
                                                {statusItemSelect?.map(
                                                  (slectedItem, i) => (
                                                    <>
                                                      {slectedItem?.status ==
                                                        item && (
                                                        <Flex key={i} mr={2}>
                                                          <MdCheckCircle
                                                            color={"green"}
                                                            size={18}
                                                          />
                                                        </Flex>
                                                      )}
                                                    </>
                                                  )
                                                )}
                                                {capitalizeFirstLetter(item)}
                                              </MenuItem>
                                            )
                                          )}
                                        </MenuList>
                                      </Menu>
                                    </Flex>
                                  </Flex>
                                </AccordionPanel>
                              </AccordionItem>
                              <AccordionItem _focus={{ outline: 0 }}>
                                <h2>
                                  <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                      <Flex>
                                        <Text>Location</Text>

                                        {locationItemSelect.length > 0 && (
                                          <Text
                                            ml={2}
                                            borderRadius={20}
                                            background={"gray"}
                                            color={"white"}
                                            px={"10px"}
                                            fontWeight={600}
                                          >
                                            {locationItemSelect.length}
                                          </Text>
                                        )}
                                      </Flex>
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <Flex flexDir={"column"}>
                                    <Flex>
                                      <Text fontSize={"sm"}>
                                        You can search by location
                                      </Text>
                                    </Flex>
                                    <Flex mt={2}>
                                      <Wrap>
                                        {locationItemSelect?.map((item, i) => (
                                          <WrapItem key={i}>
                                            <Tag
                                              size={"sm"}
                                              borderRadius="full"
                                              variant="solid"
                                              colorScheme="green"
                                            >
                                              <TagLabel>
                                                {item?.location === ""
                                                  ? "Empty Zones"
                                                  : item?.location}
                                              </TagLabel>
                                              <TagCloseButton
                                                onClick={() =>
                                                  locationListRemove(
                                                    item?.location
                                                  )
                                                }
                                              />
                                            </Tag>
                                          </WrapItem>
                                        ))}
                                      </Wrap>
                                    </Flex>
                                    <Flex mt={3} flexDir={"row"}>
                                      <Menu placement={"top"}>
                                        <MenuButton
                                          as={Button}
                                          rightIcon={<MdArrowDropDown />}
                                        >
                                          Select location
                                        </MenuButton>
                                        <MenuList
                                          overflowY={"auto"}
                                          maxHeight={500}
                                        >
                                          <>
                                            {locations?.body?.locations?.map(
                                              (item, i) => (
                                                <MenuItem
                                                  key={i}
                                                  color={textColor}
                                                  value={item}
                                                  onClick={(e) =>
                                                    handleAdvancedFilter(
                                                      e,
                                                      "location"
                                                    )
                                                  }
                                                >
                                                  {locationItemSelect?.map(
                                                    (locationSelected) => (
                                                      <>
                                                        {locationSelected?.location ===
                                                          item && (
                                                          <Flex mr={2}>
                                                            <MdCheckCircle
                                                              color={"green"}
                                                              size={18}
                                                            />
                                                          </Flex>
                                                        )}
                                                      </>
                                                    )
                                                  )}

                                                  {item}
                                                </MenuItem>
                                              )
                                            )}
                                            <MenuItem
                                              color={textColor}
                                              value={""}
                                              onClick={(e) =>
                                                handleAdvancedFilter(
                                                  e,
                                                  "location"
                                                )
                                              }
                                            >
                                              {locationItemSelect?.map(
                                                (locationSelected, i) => (
                                                  <div key={i}>
                                                    {locationSelected?.location ===
                                                      "" && (
                                                      <Flex mr={2}>
                                                        <MdCheckCircle
                                                          color={"green"}
                                                          size={18}
                                                        />
                                                      </Flex>
                                                    )}
                                                  </div>
                                                )
                                              )}
                                              Empty Zones
                                            </MenuItem>
                                          </>
                                        </MenuList>
                                      </Menu>
                                    </Flex>
                                  </Flex>
                                </AccordionPanel>
                              </AccordionItem>

                              <AccordionItem _focus={{ outline: 0 }}>
                                <h2>
                                  <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                      <Flex>
                                        <Text>Upcoming EMI Due</Text>

                                        {emiDues && (
                                          <Text
                                            ml={2}
                                            borderRadius={20}
                                            background={"gray"}
                                            color={"white"}
                                            px={"10px"}
                                            fontWeight={600}
                                          >
                                            1
                                          </Text>
                                        )}
                                      </Flex>
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <Checkbox
                                    isChecked={emiDues}
                                    onChange={() => {
                                      emiDues
                                        ? setEmiDues(false)
                                        : setEmiDues(true);
                                    }}
                                  >
                                    {" "}
                                    Get upcoming EMI dues only{" "}
                                  </Checkbox>
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>
                          </PopoverBody>
                          <PopoverFooter>
                            <Flex justifyContent={"space-between"}>
                              <Button
                                colorScheme={"gray"}
                                ml={2}
                                onClick={() => filterButtonReset()}
                              >
                                Reset
                              </Button>

                              <Button
                                colorScheme={"pink"}
                                onClick={() => handleMultiFilter()}
                              >
                                Apply
                              </Button>
                            </Flex>
                          </PopoverFooter>
                        </PopoverContent>
                      </Portal>
                    </Popover>
                  </Flex>

                  <Flex>
                    {userRole === "Admin" && (
                      <Button
                        mx={3}
                        colorScheme={"gray"}
                        borderRadius={10}
                        onClick={() => handleMultiFilter()}
                      >
                        <CSVLink
                          filename={"Orders" + " " + Date()}
                          data={exportData}
                        >
                          {" "}
                          <Flex flexDir={"row"}>
                            {" "}
                            <Icon
                              color="secondaryGray.600"
                              w="22px"
                              mr={2}
                              h="22px"
                              as={MdCloudDownload}
                            />{" "}
                            Export to CSV
                          </Flex>
                        </CSVLink>
                      </Button>
                    )}
                  </Flex>
                </Flex>
              </Flex>

              {/*<Flex>*/}
              {/*        <Flex mx={3}>*/}
              {/*            <Wrap >*/}
              {/*                        {statusItemSelect?.map((item) => (*/}
              {/*                            <WrapItem>*/}
              {/*                                <Tag*/}
              {/*                                    size={"sm"}*/}
              {/*                                    borderRadius='full'*/}
              {/*                                    variant='solid'*/}
              {/*                                    colorScheme='green'*/}
              {/*                                >*/}
              {/*                                    <TagLabel>{capitalizeFirstLetter(item?.status)}</TagLabel>*/}
              {/*                                    <TagCloseButton  onClick={(e) => statusListRemove(item?.status)} />*/}
              {/*                                </Tag>*/}
              {/*                            </WrapItem>*/}
              {/*                        ))}*/}
              {/*                {locationItemSelect?.map((item) => (*/}
              {/*                    <WrapItem>*/}

              {/*                        <Tag*/}
              {/*                            size={"sm"}*/}
              {/*                            borderRadius='full'*/}
              {/*                            variant='solid'*/}
              {/*                            colorScheme='yellow'*/}
              {/*                        >*/}
              {/*                            <TagLabel>{item?.location}</TagLabel>*/}
              {/*                            <TagCloseButton  onClick={() => locationListRemove(item?.location)} />*/}
              {/*                        </Tag>*/}
              {/*                    </WrapItem>*/}
              {/*                ))}*/}
              {/*                {agentRef && (*/}
              {/*                    <WrapItem>*/}

              {/*                        <Tag*/}
              {/*                            size={"sm"}*/}
              {/*                            borderRadius='full'*/}
              {/*                            variant='solid'*/}
              {/*                            colorScheme='pink'*/}
              {/*                        >*/}
              {/*                            <TagLabel>{agentRef}</TagLabel>*/}
              {/*                            <TagCloseButton  onClick={() => setAgentRef("")} />*/}
              {/*                        </Tag>*/}
              {/*                    </WrapItem>*/}
              {/*                    )}*/}
              {/*            </Wrap>*/}
              {/*        </Flex>*/}
              {/*</Flex>*/}

              <Table {...getTableProps()}>
                {sales?.body?.total === 0 ? (
                  <Flex
                    flexDir={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Player
                      autoplay
                      loop
                      src="https://assets2.lottiefiles.com/packages/lf20_g4wqji2g.json"
                      style={{ height: "200px", width: "200px" }}
                    ></Player>

                    <Flex flexDir={"column"} justifyContent={"space-between"}>
                      <Text fontSize={"lg"} my={3}>
                        NO RECORDS YET
                      </Text>
                      <Button my={3} onClick={filterButtonReset}>
                        Reset
                      </Button>
                    </Flex>
                  </Flex>
                ) : (
                  <>
                    <Thead>
                      {headerGroups.map((headerGroup, i) => (
                        <Tr key={i} {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <Th {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </Th>
                          ))}
                        </Tr>
                      ))}
                    </Thead>

                    <Tbody {...getTableBodyProps()}>
                      <>
                        {page.map((row, i) => {
                          prepareRow(row);
                          return (
                            <Tr
                              key={i}
                              cursor={"pointer"}
                              _hover={{
                                background: "rgba(81, 81, 81, 0.16)",
                                textDecoration: "underline",
                              }}
                              onClick={(e) => {
                                orderInit(row.cells[0]?.row?.original);
                              }}
                              {...row.getRowProps()}
                            >
                              {row.cells.map((cell, i) => {
                                return (
                                  <Td
                                    key={i}
                                    {...cell.getCellProps()}
                                    fontSize={{
                                      base: "md",
                                      md: "sm",
                                      xl: "sm",
                                    }}
                                  >
                                    {cell.render("Cell")}
                                  </Td>
                                );
                              })}
                            </Tr>
                          );
                        })}
                      </>
                    </Tbody>
                  </>
                )}
              </Table>
              {sales?.body?.total != 0 && (
                <Flex justifyContent="space-between" m={4} alignItems="center">
                  <Flex>
                    <Tooltip label="First Page">
                      <IconButton
                        onClick={() => {
                          setPageHook(1);
                        }}
                        isDisabled={pageHook === 1}
                        icon={<ArrowLeftIcon h={3} w={3} />}
                        mr={4}
                      />
                    </Tooltip>
                    <Tooltip label="Previous Page">
                      <IconButton
                        onClick={() => {
                          setPageHook((s) => (s === 0 ? 0 : s - 1));
                        }}
                        isDisabled={pageHook === 1}
                        icon={<ChevronLeftIcon h={6} w={6} />}
                      />
                    </Tooltip>
                  </Flex>

                  <Flex alignItems="center">
                    <Text flexShrink="0" mr={8}>
                      Page{" "}
                      <Text fontWeight="bold" as="span">
                        {pageIndex}
                      </Text>{" "}
                      of{" "}
                      <Text fontWeight="bold" as="span">
                        {sales?.body?.total > 10
                          ? Math.ceil(sales?.body?.total / per_page)
                          : 1}
                      </Text>
                    </Text>
                  </Flex>

                  <Flex>
                    <Tooltip label="Next Page">
                      <IconButton
                        onClick={() => {
                          setPageHook((s) => s + 1);
                        }}
                        isDisabled={
                          pageHook ===
                            Math.ceil(sales?.body?.total / per_page) ||
                          parseInt(sales?.body?.total / per_page) == 0
                        }
                        icon={<ChevronRightIcon h={6} w={6} />}
                      />
                    </Tooltip>
                    <Tooltip label="Last Page">
                      <IconButton
                        onClick={() => {
                          setPageHook(Math.ceil(sales?.body?.total / per_page));
                        }}
                        isDisabled={
                          pageHook ===
                            Math.ceil(sales?.body?.total / per_page) ||
                          Math.ceil(sales?.body?.total / per_page) == 0
                        }
                        icon={<ArrowRightIcon h={3} w={3} />}
                        ml={4}
                      />
                    </Tooltip>
                  </Flex>

                  <OrderDrawer
                    selectedOrder={selectedOrder}
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                    setSelectedOrder={setSelectedOrder}
                  />
                </Flex>
              )}
            </Card>
          </Skeleton>
        </Box>
      </Box>
    </>
  );
}

export default Sales;
