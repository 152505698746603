// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom Components
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React from "react";
// Assets
import { IoMdMoon, IoMdSunny } from "react-icons/io";

import routes from "routes.js";
import { useAuth0 } from "@auth0/auth0-react";

import { Player } from "@lottiefiles/react-lottie-player";

export default function HeaderLinks(props) {
  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();

  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const txColor = useColorModeValue("black", "white");
  const { user, buildLogoutUrl } = useAuth0();

  const userRole = user?.["https://admin.b2connect.me/roles"][0];

  const origin = window.location.origin;
  const logoutUrl =
    "https://b2connect.us.auth0.com/logout?returnTo=" + origin + "/auth/login";

  return (
    <Skeleton isLoaded={user} fadeDuration={1.5} borderRadius={20}>
      <Flex flexDir={"row"}>
        <Flex
          w={{ sm: "100%", md: "auto" }}
          alignItems="center"
          flexDirection="row"
          p="10px"
          borderRadius="30px"
        >
          <Button
            variant="no-hover"
            bg="transparent"
            p="0px"
            minW="unset"
            minH="unset"
            h="18px"
            w="max-content"
            onClick={toggleColorMode}
          >
            <Icon
              me="10px"
              h="18px"
              w="18px"
              color={navbarIcon}
              as={colorMode === "light" ? IoMdMoon : IoMdSunny}
            />
          </Button>
        </Flex>

        <Flex
          w={{ sm: "100%", md: "auto" }}
          alignItems="center"
          flexDirection="row"
          flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
          p="10px"
          borderRadius="30px"
          boxShadow={shadow}
        >
          {/*<SearchBar*/}
          {/*  mb={secondary ? { base: "10px", md: "unset" } : "unset"}*/}
          {/*  me="10px"*/}
          {/*  borderRadius="30px"*/}
          {/*/>*/}

          <SidebarResponsive routes={routes} />
          <Menu></Menu>

          <Menu>
            {/*<MenuButton p="0px">*/}
            {/*  <Icon*/}
            {/*    mt="6px"*/}
            {/*    as={MdInfoOutline}*/}
            {/*    color={navbarIcon}*/}
            {/*    w="18px"*/}
            {/*    h="18px"*/}
            {/*    me="10px"*/}
            {/*  />*/}
            {/*</MenuButton>*/}
            <MenuList
              boxShadow={shadow}
              p="20px"
              me={{ base: "30px", md: "unset" }}
              borderRadius="20px"
              bg={menuBg}
              border="none"
              mt="22px"
              minW={{ base: "unset" }}
              maxW={{ base: "360px", md: "unset" }}
            >
              <Flex flexDirection="row">
                <Flex
                  direction="column"
                  mb="12px"
                  align="center"
                  justify="center"
                  px="15px"
                  pt="20px"
                >
                  <Text
                    fontSize={{ base: "lg", xl: "18px" }}
                    color={txColor}
                    fontWeight="bold"
                    lineHeight="150%"
                    textAlign="center"
                    px="10px"
                    mb="14px"
                  >
                    Need help?
                  </Text>
                  <Flex>
                    <Player
                      autoplay
                      loop
                      src="/lottiefiles/support.json"
                      style={{ height: "100px", width: "150px" }}
                    ></Player>
                  </Flex>
                </Flex>
              </Flex>
              <Link href="/dashboard/support">
                <Button
                  bg="whiteAlpha.300"
                  _hover={{ bg: "whiteAlpha.200" }}
                  _active={{ bg: "whiteAlpha.100" }}
                  mb={{ sm: "16px", xl: "24px" }}
                  color={txColor}
                  fontWeight="regular"
                  fontSize="sm"
                  minW="185px"
                  mx="auto"
                >
                  Contact Support
                </Button>
              </Link>
            </MenuList>
          </Menu>

          <Menu>
            <MenuButton p="0px">
              <Avatar
                _hover={{ cursor: "pointer" }}
                color={colorMode == "light" ? "white" : "black"}
                name={user?.name}
                bg={colorMode == "light" ? "#f6ad55" : "white"}
                size="sm"
                w="40px"
                h="40px"
              />
            </MenuButton>
            <MenuList
              boxShadow={shadow}
              p="0px"
              mt="10px"
              borderRadius="20px"
              bg={menuBg}
              border="none"
            >
              <Flex w="100%" mb="0px">
                <Text
                  ps="20px"
                  pt="16px"
                  pb="10px"
                  w="100%"
                  borderBottom="1px solid"
                  borderColor={borderColor}
                  fontSize="sm"
                  fontWeight="700"
                  color={textColor}
                >
                  👋&nbsp; Hey, {user?.name}
                </Text>
              </Flex>
              <Flex flexDirection="column" p="10px">
                {/* <MenuItem
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  borderRadius="8px"
                  px="14px"
                >
                  <Text fontSize="sm">Profile Settings</Text>
                </MenuItem> */}

                <MenuItem
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  color="red.400"
                  borderRadius="8px"
                  px="14px"
                >
                  <Text
                    fontSize="sm"
                    onClick={() => {
                      localStorage.removeItem("auth0_token");
                      window.location.href = logoutUrl;
                    }}
                  >
                    Log out
                  </Text>
                </MenuItem>
              </Flex>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Skeleton>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
