import React, { useRef, useMemo, useState } from "react";
import {
  MdReplay,
  MdOutlineEditNote,
  MdDownload,
  MdDeviceUnknown,
} from "react-icons/md";

import { FaCartArrowDown } from "react-icons/fa";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogContent,
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  SimpleGrid,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useStyleConfig,
  useToast,
} from "@chakra-ui/react";
import IconBox from "../../../components/icons/IconBox";
import { Player } from "@lottiefiles/react-lottie-player";
import RedirectCard from "../../../components/card/RedirectCard";
import {
  useGetUsers,
  useGetDevices,
  useGetUsersCount,
  useGetAssetZonesBuildings,
} from "../../../lib/hooks";
import { MainTable } from "./components/MainTable";
import moment from "moment";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import nationalities from "./nationality.json";

import Html5QrcodePlugin from "./components/Html5QrcodePlugin";
import Card from "components/card/Card";
import { useEffect } from "react";
import { filter, set } from "lodash";

function Assets() {
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const inventoryStatusTypes = [
    {
      key: "INSTALLED",
      value: "INSTALLED",
    },
    {
      key: "DECOMMISSIONED",
      value: "CHECKOUT",
    },
    {
      key: "MISSING",
      value: "MISSING",
    },
    {
      key: "BOXED",
      value: "OTHER",
    },
  ];

  const assetTypes = [
    {
      key: "ACCESS POINT",
      value: "ACCESS_POINT",
    },
    {
      key: "SWITCH",
      value: "SWITCH",
    },
    {
      key: "ROUTER",
      value: "ROUTER",
    },
  ];

  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [apiFilterParam, setApiFilterParam] = useState("");

  const [visible, setVisible] = useState(false);

  const [updateBtnEnable, setUpdateBtnEnable] = useState(false);

  const [editFirstName, setEditFirstName] = useState("");
  const [editLastName, setEditLastName] = useState("");
  const [editNationality, setEditNationality] = useState("");
  const [editEmpID, setEditEmpID] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editGender, setEditGender] = useState("");
  const [editRoom, setEditRoom] = useState("");
  const [editPosition, setEditPosition] = useState("");
  const [editDepartment, setEditDepartment] = useState("");
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const { devicesData, isError, isLoading } = useGetDevices(
    page,
    per_page,
    searchText,
    apiFilterParam
  );

  const { zoneBuildingData, isZoneBuildingError, isZoneBuildingLoading } =
    useGetAssetZonesBuildings();

  const [zones, setZones] = useState([]);
  const [zonesBuildings, setZonesBuildings] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [selectedInventoryStatus, setSelectedInventoryStatus] = useState("");
  const [selectedAssetType, setSelectedAssetType] = useState("");

  const [zoneBuildingFilterValue, setZoneBuildingFilterValue] = useState("");

  const [inventoryFilterValue, setInventoryFilterValue] = useState("");
  const [assetTypeFilterValue, setAssetTypeFilterValue] = useState("");

  const [buildingsForSelectedZone, setBuildingsForSelectedZone] = useState([]);

  useEffect(() => {
    let filtersArray = [];
    if (inventoryFilterValue && inventoryFilterValue !== "") {
      filtersArray.push(inventoryFilterValue);
    }
    if (assetTypeFilterValue && assetTypeFilterValue !== "") {
      filtersArray.push(assetTypeFilterValue);
    }
    if (zoneBuildingFilterValue && zoneBuildingFilterValue !== "") {
      filtersArray.push(zoneBuildingFilterValue);
    }
    let combinedFilter = filtersArray.join(",");
    console.log("COMBINED FILTER: " + filtersArray);
    // let filterValue = `${inventoryFilterValue ? inventoryFilterValue : ""}${
    //   assetTypeFilterValue ? assetTypeFilterValue : ""
    // }${
    //   zoneBuildingFilterValue
    //     ? inventoryFilterValue
    //       ? zoneBuildingFilterValue
    //       : zoneBuildingFilterValue.substring(1, zoneBuildingFilterValue.length)
    //     : ""
    // }`;
    setApiFilterParam(combinedFilter);
    setPage(1);
  }, [inventoryFilterValue, zoneBuildingFilterValue, assetTypeFilterValue]);

  const handleInventoryStatusSelection = (c) => {
    console.log("Selected inventory status is now: " + c.target.value);
    setSelectedInventoryStatus(c.target.value);
    let inventoryFilterVal = c.target.value
      ? `INVENTORY_STATUS:${c.target.value}`
      : "";
    setInventoryFilterValue(inventoryFilterVal);
  };

  const handleAssetTypeSelection = (c) => {
    console.log("Selected asset is now: " + c.target.value);
    console.log("INVENTORY_STATUS" + selectedInventoryStatus);
    setSelectedAssetType(c.target.value);
    let assetTypeFilterVal = c.target.value
      ? `ASSET_TYPE:${c.target.value}`
      : "";
    setAssetTypeFilterValue(assetTypeFilterVal);
  };

  const handleBuildingSelection = (c) => {
    console.log("Selected building is now: " + c.target.value);
    setSelectedBuilding(c.target.value);
    let buildingFilterVal = c.target.value ? `,BUILDING:${c.target.value}` : "";
    setZoneBuildingFilterValue(`ZONE:${selectedZone}${buildingFilterVal}`);
  };

  const handleZoneSelection = (c) => {
    console.log("SelectedZone is now: " + c.target.value);
    setSelectedZone(c.target.value);
    setSelectedBuilding(undefined);
    let zoneFilterVal = c.target.value ? `ZONE:${c.target.value}` : "";
    setZoneBuildingFilterValue(zoneFilterVal);
  };

  useEffect(() => {
    setZonesBuildings(
      !isZoneBuildingError && !isZoneBuildingLoading && zoneBuildingData
        ? zoneBuildingData?.body
        : []
    );
  }, [zoneBuildingData]);

  useEffect(() => {
    setZones(Object.keys(zonesBuildings));
  }, [zonesBuildings]);

  useEffect(() => {
    console.log(selectedZone);
    setBuildingsForSelectedZone(zonesBuildings[selectedZone]);
  }, [selectedZone]);

  const { user } = useAuth0();
  const userRole = user?.["https://admin.b2connect.me/roles"][0];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCell, setSelectedCell] = useState({});
  const {
    isOpen: editOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const textColor = useColorModeValue("secondaryGrey.700", "white");
  const [resetErr, setResetErr] = useState(false);

  const {
    isOpen: isOpenReset,
    onOpen: onOpenReset,
    onClose: onCloseReset,
  } = useDisclosure();

  const {
    isOpen: isMissingOpenReset,
    onOpen: onMissingOpenReset,
    onClose: onMissingCloseReset,
  } = useDisclosure();

  const deviceData =
    !isError && !isLoading && devicesData
      ? devicesData?.body?.items?.map((item) => ({
          id: item.id,
          mac: item.mac,
          serial: item.serial,
          deviceName: item.deviceName,
          description: item.description,
          model: item.model,
          zone: item.zone,
          building: item.building,
          floor: item.floor ? item.floor : "",
          inventory_status: item.inventory_status,
          createdTime: moment(item.createdTime * 1000)
            .utcOffset("+0400")
            .format("LLL"),
          updatedTime: item.updatedTime
            ? moment(item.updatedTime * 1000)
                .utcOffset("+0400")
                .format("LLL")
            : null,
        }))
      : [];

  const usersTotalCount =
    !isError && !isLoading && devicesData ? devicesData?.body?.total : 0;
  const data = React.useMemo(() => deviceData, [devicesData]);

  const notificationsType = [
    {
      id: 1,
      name: "Onboard a Single User",
      icon: "https://assets1.lottiefiles.com/datafiles/6deVuMSwjYosId3/data.json",
      description: "On-Board a user",
      button: "Start",
      path: "/dashboard/boarding-single",
    },

    {
      id: 2,
      name: "Onboard Multiple Users (Bulk)",
      icon: "https://assets3.lottiefiles.com/packages/lf20_yxrxjnkt.json",
      description: "On-Board a batch of users",
      button: "Start",
      path: "/dashboard/boarding-batch",
    },
    {
      id: 3,
      name: "Off-Board",
      icon: "https://assets6.lottiefiles.com/private_files/lf30_aprp5fnm.json",
      description: "Off-Board users",
      button: "Coming Soon",
      path: "/dashboard/offboarding",
    },
  ];

  const columns = useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "MAC",
            accessor: "mac",
          },
          {
            Header: "Serial",
            accessor: "serial",
          },
        ],
      },
      {
        Header: " ",
        columns: [
          {
            Header: "Zone",
            accessor: "zone",
          },
          {
            Header: "Building",
            accessor: "building",
          },
          {
            Header: "Description",
            accessor: "description",
          },
          {
            Header: "Inventory Status",
            accessor: "inventory_status",
          },
          {
            Header: "Device Name",
            accessor: "deviceName",
          },
        ],
      },
    ],
    []
  );

  const handleVisible = () => {
    setVisible(!visible);
  };

  const handleScanSuccess = (value) => {
    console.log(value);
    setSearchText(value);
    setPage(1);
    setVisible(false);
  };

  const handleDrawer = (data) => {
    setSelectedCell(data);
    onOpen();
  };

  const handleSearchText = (data) => {
    setSearchText(data);
    setPage(1);
  };

  let authToken = localStorage.getItem("auth0_token");
  const toast = useToast();

  const handleEditOpen = (e) => {
    setEditFirstName(e?.firstName);
    setEditLastName(e?.lastName);
    setEditNationality(e?.nationality);
    setEditEmail(e?.email);
    setEditEmpID(e?.empId);
    setEditGender(e?.gender);
    setEditRoom(e?.room);
    setEditPosition(e?.position);
    setEditDepartment(e?.department);
    setUpdateBtnEnable(false);
    onEditOpen();
  };

  const handleEditSubmit = async (e) => {
    setUpdateBtnEnable(true);
    let editSubmitBody = {
      login: e.contactNumber,
      firstName: editFirstName ? editFirstName : "",
      lastName: editLastName ? editLastName : null,
      nationality: editNationality ? editNationality : "",
      gender: editGender ? editGender : "",
      email: editEmail ? editEmail : "",
      room: editRoom ? editRoom : "",
      employeeId: editEmpID ? editEmpID : "",
      position: editPosition ? editPosition : "",
      department: editDepartment ? editDepartment : "",
    };
    Object.keys(editSubmitBody).forEach((key) => {
      if (editSubmitBody[key] === null || editSubmitBody[key] === "") {
        delete editSubmitBody[key];
      }
    });
    console.log(editSubmitBody);
    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/users/${e.contactNumber}`,
        editSubmitBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        setUpdateBtnEnable(false);
        onCloseReset();
        toast({
          title: "Updated successfully.",
          description: "You've just updated the details of the user.",
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onEditClose();
            onClose();
          },
        });
      })
      .catch((e) => {
        setResetErr(true);
        onCloseReset();
        toast({
          title: "Something went wrong .",
          description: "Please try again or contact the admin.",
          status: "error",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onEditClose();
            onClose();
          },
        });
      });
  };

  const checkoutDevice = async (e) => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/devices/${e.id}/checkout`,
        null,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        onCloseReset();
        toast({
          title: "Decommission successful.",
          description: "Asset decommissioned successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onCloseReset();
            onClose();
          },
        });
      })
      .catch((e) => {
        setResetErr(true);
        onCloseReset();
        toast({
          title: "Something went wrong .",
          description: "Please try again or contact the admin.",
          status: "error",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onCloseReset();
            onClose();
          },
        });
      });
  };

  const missingDevice = async (e) => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/devices/${e.id}/missing`, null, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        onCloseReset();
        toast({
          title: "Missing Report successful.",
          description: "Asset missing report sent successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onCloseReset();
            onClose();
          },
        });
      })
      .catch((e) => {
        setResetErr(true);
        onCloseReset();
        toast({
          title: "Something went wrong .",
          description: "Please try again or contact the admin.",
          status: "error",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onCloseReset();
            onClose();
          },
        });
      });
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <Card>
          <Stack spacing={3}>
            <Select
              variant="outline"
              placeholder="Any Asset Type"
              onChange={handleAssetTypeSelection}
              value={selectedAssetType}
            >
              {assetTypes.map((item) => (
                <option key={item.key} value={item.value}>
                  {item.key}
                </option>
              ))}
            </Select>
            <Select
              variant="outline"
              placeholder="Any Inventory Status"
              onChange={handleInventoryStatusSelection}
              value={selectedInventoryStatus}
            >
              {inventoryStatusTypes.map((item) => (
                <option key={item.key} value={item.value}>
                  {item.key}
                </option>
              ))}
            </Select>
            <Select
              variant="outline"
              placeholder="All Zones"
              value={selectedZone}
              onChange={handleZoneSelection}
            >
              {zones.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Select>

            {selectedZone && (
              <Select
                variant="outline"
                placeholder="All Buildings"
                value={selectedBuilding}
                onChange={handleBuildingSelection}
              >
                {buildingsForSelectedZone &&
                  buildingsForSelectedZone.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
              </Select>
            )}
          </Stack>
        </Card>
        <Flex direction={"column"} gap={4} alignContent={"left"}>
          <Button
            onClick={handleVisible}
            size="lg"
            backgroundColor={"orange.500"}
            textColor="black"
            _hover={{ backgroundColor: "orange.400" }}
          >
            {visible ? "Close Device Scanner" : "Scan Device"}
          </Button>
          {visible && (
            <Html5QrcodePlugin
              fps={10}
              qrbox={250}
              disableFlip={true}
              qrCodeSuccessCallback={handleScanSuccess}
            />
          )}
        </Flex>
      </SimpleGrid>
      <Drawer onClose={onClose} isOpen={isOpen} size={"md"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton mt={3} />
          <Flex flexDir={"column"}>
            <DrawerHeader mt={1}>{selectedCell.mac}</DrawerHeader>
            <Flex>
              <Flex pl={3}>
                <Button
                  leftIcon={<FaCartArrowDown />}
                  p={2}
                  ml={3}
                  borderRadius="10px"
                  variant="outline"
                  onClick={onOpenReset}
                  colorScheme="blue"
                >
                  {"Decommission"}
                </Button>
                <AlertDialog isOpen={isOpenReset} onClose={onCloseReset}>
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {"Decommision Asset"}
                      </AlertDialogHeader>

                      <AlertDialogBody>
                        Are you sure? You can't undo this action afterwards.
                      </AlertDialogBody>

                      <AlertDialogFooter>
                        <Button
                          colorScheme="red"
                          onClick={() => checkoutDevice(selectedCell)}
                        >
                          {"Confirm"}
                        </Button>
                        <Button ml={3} onClick={onCloseReset}>
                          Cancel
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </Flex>
              <Flex pl={3}>
                <Button
                  leftIcon={<MdDeviceUnknown />}
                  p={2}
                  ml={3}
                  borderRadius="10px"
                  variant="outline"
                  onClick={onMissingOpenReset}
                  colorScheme="red"
                >
                  {"Missing"}
                </Button>
                <AlertDialog
                  isOpen={isMissingOpenReset}
                  onClose={onMissingCloseReset}
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {"Report Missing Asset"}
                      </AlertDialogHeader>

                      <AlertDialogBody>
                        Are you sure? You can't undo this action afterwards.
                      </AlertDialogBody>

                      <AlertDialogFooter>
                        <Button
                          colorScheme="red"
                          onClick={() => missingDevice(selectedCell)}
                        >
                          {"Confirm"}
                        </Button>
                        <Button ml={3} onClick={onMissingCloseReset}>
                          Cancel
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </Flex>
            </Flex>
          </Flex>
          <DrawerBody>
            <Flex>
              <FormControl>
                <Stack spacing={3}>
                  <FormLabel htmlFor="mac">MAC</FormLabel>
                  <Input
                    disabled={true}
                    id="mac"
                    type="text"
                    value={selectedCell.mac}
                    color={"red"}
                    fontWeight="extrabold"
                    _disabled={{ color: "blue.500" }}
                  />
                  <FormLabel htmlFor="description">Description</FormLabel>
                  <Input
                    disabled={true}
                    id="description"
                    type="text"
                    value={selectedCell.description}
                    color={textColor}
                    fontWeight="extrabold"
                    _disabled={{ color: "blue.500" }}
                  />
                  <FormLabel htmlFor="serial">Serial</FormLabel>
                  <Input
                    disabled={true}
                    id="serial"
                    type="text"
                    value={selectedCell.serial}
                    color={textColor}
                    fontWeight="extrabold"
                  />

                  <FormLabel htmlFor="deviceName">Device Name</FormLabel>
                  <Input
                    disabled={true}
                    id="deviceName"
                    type="text"
                    value={selectedCell.deviceName}
                    color={textColor}
                  />

                  <FormLabel htmlFor="model">Model</FormLabel>
                  <Input
                    disabled={true}
                    id="model"
                    type="text"
                    value={selectedCell.model}
                    color={textColor}
                  />
                  <FormLabel htmlFor="zone">Zone</FormLabel>
                  <Input
                    disabled={true}
                    id="zone"
                    type="text"
                    value={selectedCell.zone}
                    color={textColor}
                  />
                  <FormLabel htmlFor="building">Building</FormLabel>
                  <Input
                    disabled={true}
                    id="building"
                    type="text"
                    value={selectedCell.building}
                    color={textColor}
                  />
                  <FormLabel htmlFor="inventoryStatus">
                    Inventory Status
                  </FormLabel>
                  <Input
                    disabled={true}
                    id="inventoryStatus"
                    type="text"
                    value={selectedCell.inventory_status}
                    color={textColor}
                  />
                  <FormLabel htmlFor="createdTime">Created Time</FormLabel>
                  <Input
                    disabled={true}
                    id="createdTime"
                    type="text"
                    value={selectedCell.createdTime}
                    color={textColor}
                  />
                  <FormLabel htmlFor="updatedTime">Updated Time</FormLabel>
                  <Input
                    disabled={true}
                    id="updatedTime"
                    type="text"
                    value={selectedCell.updatedTime}
                    color={textColor}
                  />
                </Stack>
              </FormControl>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Flex>
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={editOpen}
          onClose={onEditClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Device Mapping</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Flex>
                <FormControl ml={4} mt={4}>
                  <FormLabel>Box</FormLabel>
                  <Select
                    color={textColor}
                    placeholder={"Select Box"}
                    onChange={(e) => setEditNationality(e.target.value)}
                  >
                    {nationalities.map((item, i) => (
                      <option
                        key={i}
                        value={item.name.toUpperCase()}
                        selected={
                          item.name.toUpperCase() === selectedCell?.nationality
                        }
                      >
                        {item.name.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button
                disabled={updateBtnEnable}
                colorScheme="pink"
                mr={3}
                onClick={() => handleEditSubmit(selectedCell)}
              >
                Update
              </Button>
              <Button onClick={onEditClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      {/* <Text>{JSON.stringify(selectedBuilding)}</Text> */}
      <MainTable
        loaded={!isLoading}
        searchText={searchText}
        data={data}
        columns={columns}
        setPage={setPage}
        setPerPage={setPer_page}
        handleSearchText={handleSearchText}
        currentpage={page}
        perPage={per_page}
        exports={userRole === "Admin" ? deviceData : false}
        totalPage={parseInt(devicesData?.body?.pages)}
        drawer={true}
        handleDrawer={handleDrawer}
        name={`All Assets (${usersTotalCount})`}
      />
    </Box>
  );
}

export default Assets;
