import { Step, Steps, useSteps } from "chakra-ui-steps";
import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  SimpleGrid,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import fetcher from "../../../../../lib/fetcher";
import { useGroups } from "../../../../../lib/hooks";
import { Player } from "@lottiefiles/react-lottie-player";
import { count } from "sms-length";
import { Link } from "react-router-dom";

const steps = [
  {
    id: 1,
    label: "Select Group",
    description: "Select the desired group",
  },
  {
    id: 2,
    label: "Notification details",
    description: "Enter Your Preferred Filters",
  },
  { id: 3, label: "Preview", description: "Preview And Send" },
];

export const smsGroup = () => {
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });
  const boxBg = useColorModeValue("white", "whiteAlpha.100");
  const textColor = useColorModeValue("#1b254b", "white");
  const [audioAsset, setAudioAsset] = useState("");
  const [groupCount, setGroupCount] = useState(null);
  const [audio] = useState(new Audio(audioAsset));
  const [resLoading, setResLoading] = useState(false);
  const [processDone, setProcessDone] = useState(false);

  const [playing, setPlaying] = useState(false);
  const toggle = () => setPlaying(!playing);
  const {
    groups,
    isError: groupsError,
    isLoading: groupsLoading,
  } = useGroups();

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [groupDetail, setGroupDetail] = useState({});

  const [error, setError] = useState(false);

  const [foundUsersCount, setFoundUsersCount] = useState(0);
  const [smsMessageObject, setSmsMessageObject] = useState({});

  const [title, setTitle] = useState("");
  const [type, setType] = useState("INFO");
  const [group, setGroup] = useState("");
  const nationalityRef = useRef("");
  const locationRef = useRef("");
  const activityRef = useRef("");
  const activityDaysRef = useRef(0);

  const [groupUsers, setGroupUsers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isError = false;
  const toast = useToast();
  let usersCountObject = [
    { filterField: "CHANNEL", filterType: "EQUALS", text: "IN_APP" },
  ];

  const resetFunction = () => {
    setSubject("");
    setTitle("");
    setMessage("");
    setType("");
    nationalityRef.current = "";
    locationRef.current = "";
    activityRef.current = "";
    activityDaysRef.current = 0;
    setFoundUsersCount(0);
    setResLoading(false);
    setProcessDone(false);
  };

  const resetNotification = () => {
    setSubject("");
    setMessage("");
    setTitle("");
    setResLoading(false);
    setProcessDone(false);
    reset();
  };

  const handleProcess = async () => {
    if (activeStep === 0) {
      if (!group) {
        setError(true);
      } else {
        if (groupCount == 0) {
          audio.src = "/sounds/error.mp3";
          await audio.play();
          toast({
            title: "Minimum 1 user in the group.",
            description: "The group should have minimum 1 user.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else {
          nextStep();
          setError(false);
        }
      }
    }
    if (activeStep === 1) {
      if (subject.length < 2 || message.length === 0) {
        setError(true);
      } else {
        nextStep();
        setError(false);
      }
    }
    if (activeStep === 2) {
      setResLoading(true);

      fetcher("notification/groups", {
        notificationBody: {
          name: subject,
          channels: ["SMS"],
          message: message,
          title,
        },
        groups: [group],
      })
        .then(async (res) => {
          if (res?.success) {
            setResLoading(false);
            setProcessDone(true);
            audio.src = "/sounds/mail_sent.mp3";
            await audio.play();
            toast({
              title: `Notification sent successfully`,
              description: `Notification has been sent.`,
              status: "success",
              duration: 2000,
              isClosable: true,
              onCloseComplete: async () => {
                resetFunction();
                reset();
              },
            });
          } else {
            setResLoading(false);

            toast({
              title: "Something went wrong.",
              description: "Please try again or contact the admin.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        })
        .catch(async (e) => {
          setResLoading(false);

          audio.src = "/sounds/error.mp3";
          await audio.play();
          toast({
            title: "Something went wrong.",
            description: "Please try again or contact the admin.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  const handleGroup = (event) => {
    if (event.target.value) {
      const value = JSON.parse(event.target.value);
      setGroup(value.id);
      setGroupDetail(value);
      setGroupCount(value.usersCount);
    } else {
      setGroupCount(0);
    }
  };

  const showGroupUsers = async () => {
    if (group) {
      const users = await fetcher(`groups/${group}`);

      if (users.success && users?.body?.users) {
        setGroupUsers(users?.body?.users);
        onOpen();
      }
    }
  };
  const handleMessage = (e) => {
    setSmsMessageObject(count(e.target.value));
    setMessage(e.target.value);
    // console.log(smsMessageObject)
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Drawer onClose={onClose} isOpen={isOpen} size={"lg"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{`Group Users Phone Number`}</DrawerHeader>
          <DrawerBody>
            <nav>
              <ul style={{ listStyle: "none" }}>
                {groupUsers?.map((item, i) => (
                  <li key={i}>{item.phone}</li>
                ))}
              </ul>
            </nav>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Flex
        flexDir="column"
        width="100%"
        bg={boxBg}
        p={5}
        borderRadius={20}
        shadow={"sm"}
      >
        <Steps colorScheme="pink" activeStep={activeStep}>
          {steps.map(({ label, description, id }, index) => (
            <Step
              label={label}
              key={label}
              description={description}
              textColor={"#fff"}
            >
              {id == 2 ? (
                <Flex px={4} pt={5} width="100%">
                  <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} spacing={10}>
                    <Flex flexDir={"column"}>
                      <Heading fontSize="md">{label}</Heading>
                      <FormControl isInvalid={isError} width={"350px"}>
                        <FormLabel pt={3} htmlFor="subject">
                          Subject
                        </FormLabel>
                        <Input
                          id="subject"
                          type="text"
                          value={subject}
                          color={textColor}
                          isInvalid={error}
                          onChange={(e) => setSubject(e.target.value)}
                        />
                        {!isError ? (
                          <FormHelperText>
                            Enter a notification subject.
                          </FormHelperText>
                        ) : (
                          <FormErrorMessage>
                            Subject is required.
                          </FormErrorMessage>
                        )}
                      </FormControl>
                      <FormControl isInvalid={isError} width={"350px"} pt={5}>
                        <FormLabel htmlFor="message">Message</FormLabel>
                        <Textarea
                          id={"message"}
                          isInvalid={error}
                          value={message}
                          size="sm"
                          onChange={(e) => handleMessage(e)}
                        />
                        {!isError ? (
                          <Flex
                            flexDir={"row"}
                            justifyContent={"space-between"}
                          >
                            <FormHelperText>
                              Enter the notification message.
                            </FormHelperText>
                            <FormHelperText>
                              {message.length !== 0
                                ? `${message.length} (${smsMessageObject?.characterPerMessage} per msg)`
                                : null}
                            </FormHelperText>
                          </Flex>
                        ) : (
                          <FormErrorMessage>
                            Message is required.
                          </FormErrorMessage>
                        )}
                        {smsMessageObject?.messages > 1 && (
                          <FormHelperText>
                            SMS Count{" "}
                            <Badge ml={1} colorScheme="red">
                              {smsMessageObject?.messages
                                ? smsMessageObject?.messages
                                : 1}
                            </Badge>
                            <Popover isLazy placement="right">
                              <PopoverTrigger>
                                <IconButton
                                  size="sm"
                                  justifyContent={"center"}
                                  pb={1}
                                  icon={
                                    <Player
                                      autoplay
                                      loop
                                      speed={0.1}
                                      src="https://assets7.lottiefiles.com/packages/lf20_Z8MOn3.json"
                                      style={{ height: "35px", width: "35px" }}
                                    ></Player>
                                  }
                                />
                              </PopoverTrigger>
                              <PopoverContent>
                                <PopoverHeader fontWeight="semibold">
                                  B2Connect SMS Counter
                                </PopoverHeader>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverBody>
                                  Seems, the entered sms characters exceeded and
                                  reached {smsMessageObject.messages} SMS - It
                                  may incur an additional cost & count!
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Flex>
                  </SimpleGrid>
                </Flex>
              ) : null}

              {id == 1 ? (
                <SimpleGrid
                  columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }}
                  gap="20px"
                  mb="20px"
                >
                  <Stack spacing={4}>
                    <Flex px={4} pt={8} width="100%" flexDirection="column">
                      <Heading fontSize="md">{label}</Heading>
                      <Stack spacing={4} mt={4}>
                        <Flex flexDir={"column"}>
                          <Select
                            id={"group"}
                            placeholder="Select "
                            background={boxBg}
                            onChange={(e) => handleGroup(e)}
                          >
                            {groups
                              ? groups?.body?.map((group, i) => (
                                  <option key={i} value={JSON.stringify(group)}>
                                    {group.name} {group.usersCount} Users
                                  </option>
                                ))
                              : null}
                          </Select>
                          {groups?.body.length == 0 && (
                            <Link to={"/dashboard/group/create"}>
                              <Button
                                mt={5}
                                color="white"
                                backgroundColor="#E0457B"
                                width={"36"}
                                _hover={{ bg: "#E0457B" }}
                              >
                                Create Group
                              </Button>
                            </Link>
                          )}
                          {groupCount > 0 && (
                            <Flex
                              flexDir={"row"}
                              justifyContent={"space-between"}
                            >
                              <Flex mt={3}>
                                <Text>Total users: {groupCount}</Text>
                              </Flex>
                              <Flex mt={3}>
                                <Button size={"sm"} onClick={showGroupUsers}>
                                  View all users
                                </Button>
                              </Flex>
                            </Flex>
                          )}
                          {groupCount == 0 && (
                            <Flex
                              flexDir={"row"}
                              justifyContent={"space-between"}
                            >
                              <Flex mt={3}>
                                <Text color={"red"}>Empty Group</Text>
                              </Flex>
                            </Flex>
                          )}
                        </Flex>
                      </Stack>
                    </Flex>
                  </Stack>
                </SimpleGrid>
              ) : null}

              {id == 3 ? (
                <Flex px={4} pt={8} width="100%" flexDirection="column">
                  <Heading fontSize="md">{label} </Heading>
                  <TableContainer>
                    <Table variant="simple">
                      <TableCaption></TableCaption>
                      <Thead>
                        <Tr>
                          <Th>Subject</Th>
                          <Th>Title</Th>
                          <Th>Message</Th>
                          <Th>Group</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>{subject}</Td>
                          <Td>{title}</Td>
                          <Td>{message}</Td>
                          <Td>
                            {groupDetail?.name} - {groupDetail?.usersCount}{" "}
                            Users
                          </Td>
                          <Td>
                            <Button size={"sm"} onClick={showGroupUsers}>
                              {" "}
                              View all users
                            </Button>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
              ) : null}
            </Step>
          ))}
        </Steps>
        {activeStep === steps.length ? (
          <Flex px={4} py={4} width="100%" flexDirection="column">
            <Heading fontSize="xl" textAlign="center">
              Woohoo! All steps completed!
            </Heading>
            <Button mx="auto" mt={6} size="sm" onClick={reset}>
              Reset
            </Button>
          </Flex>
        ) : (
          <Flex width="100%" flexDir={"row"} justify="space-between" pt={5}>
            <Flex>
              <Button size="sm" onClick={resetNotification}>
                Reset
              </Button>
            </Flex>
            <Flex flexDir={"row"}>
              <Button
                isDisabled={activeStep === 0}
                mr={4}
                onClick={prevStep}
                size="sm"
                variant="ghost"
              >
                Prev
              </Button>
              <Button
                size="sm"
                onClick={handleProcess}
                isLoading={resLoading}
                loadingText={"Sending"}
                isDisabled={processDone || resLoading}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
