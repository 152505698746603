import React from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
import { useAuth0 } from "@auth0/auth0-react";

function SignIn() {
  const { loginWithRedirect, isLoading, isAuthenticated, error } = useAuth0();
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");

  return (
    !error &&
    !isLoading &&
    !isAuthenticated && (
      <DefaultAuth>
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection="column"
        >
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              Sign In
            </Heading>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <FormControl>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                onClick={() => {
                  loginWithRedirect();
                }}
              >
                Sign In
              </Button>
            </FormControl>
          </Flex>
        </Flex>
      </DefaultAuth>
    )
  );
}

export default SignIn;
