import React from "react";

// Chakra imports
import {
  Flex,
  useColorModeValue,
  Image,
  Text,
  Heading,
} from "@chakra-ui/react";

// Custom components

import { HSeparator } from "components/separator/Separator";
import Logo from "../../../assets/img/Main/Logo.svg";
import { useAuth0 } from "@auth0/auth0-react";

export function SidebarBrand() {
  const { user } = useAuth0();
  const logoValue = user?.["https://admin.b2connect.me/app_metadata"]?.logo
  return (
    <Flex direction={"column"}>
      <Flex direction="row" ml={5}>
        <Image src={logoValue ? logoValue : Logo} width={100} />

        <Flex direction={"column"} justifyContent={"center"} ml={3}>
          <Heading size={"md"} color={"white"}>
            B2CONNECT
          </Heading>
          <Text fontSize="md" color={"white"}>
            Think Do Connect
          </Text>
        </Flex>
      </Flex>
      <HSeparator mb="20px" mt={5} />
    </Flex>
  );
}

export default SidebarBrand;
