import React from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  Center,
  Flex,
  Heading,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "./Card";
import QRCode from "react-qr-code";

function RedirectExternalCard(props) {
  const { startContent, endContent, name, value, description, path, type, call, targetBlank } =
    props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  return (
    <Card py="15px" shadow={"sm"}>
      <Flex w={"100%"} h="100%" flexDir={"column"} alignItems={"center"}>
        <Stat my="auto">
          <StatLabel
            lineHeight="100%"
            color={textColorSecondary}
            mb={5}
            textAlign={"center"}
          >
            <Heading size={"sm"}>{name}</Heading>
          </StatLabel>

          <Center mb={5}>{startContent}</Center>

          <Text textAlign={"center"} color={textColorSecondary} fontSize={"md"}>
            {description}
          </Text>
          <StatNumber
            color={textColor}
            textAlign={"center"}
            mt={2}
            fontSize={{
              base: "2xl",
            }}
          >
            {name=="WhatsApp Us" && (<Center m={5}><QRCode size={120} value={path} viewBox={`0 0 120 120`}/></Center>)}
            {call ? (
                <>
                <Popover>

                  <PopoverTrigger>
                    <Button>
                      {targetBlank ? (
                          <Link   target={"_blank"}>
                            {value}
                          </Link>
                      ) : (
                          <Link   >
                            {value}
                          </Link>
                      )}

                    </Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverHeader >
                        <Flex >
                          <Text >
                            Reach us by phone
                          </Text>


                        </Flex>
                        </PopoverHeader>
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Flex  flexDir={"column"}>
                          <Text alignSelf={"center"}>Call us at 800614</Text>
                          <Button>
                            <Link href={path} isExternal={true}>
                              {value}
                            </Link>
                          </Button>
                        </Flex>

                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                  </Popover>

                  </>


            ) : (
                <Button>

                  <Link href={path}  isExternal={true}>
                    {value}
                  </Link>
                </Button>
            )}

          </StatNumber>
        </Stat>

        <Flex ms="auto" w="max-content">
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}

export default RedirectExternalCard;
