import React from "react";
import {Box, SimpleGrid, useColorModeValue} from "@chakra-ui/react";
import IconBox from "../../../components/icons/IconBox";
import {Player} from "@lottiefiles/react-lottie-player";
import RedirectCard from "../../../components/card/RedirectCard";

const notificationsType = [
    {
        id: 1,
        name: "Order Verification",
        icon: "https://assets10.lottiefiles.com/packages/lf20_vpnuhop8.json",
        description: "B2 Call Verification",
        button: "New",
        path: "/dashboard/order-verification",
    },

    {
        id: 2,
        name: "Delivery Confirmation",
        icon: "https://assets5.lottiefiles.com/packages/lf20_3tryizhw.json",
        description: "Order Delivery Confirmation",
        button: "Start",
        path: "/dashboard/order-confirmation",
    },

];

function Main() {
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
                gap="20px"
                mb="20px"
            >
                {notificationsType.map((card, i) => (
                    <>
                        {card?.id == 2 ? (

                                <RedirectCard
                                    key={i}
                                    startContent={
                                        <IconBox
                                            w="60px"
                                            h="60px"
                                            bg={boxBg}
                                            icon={
                                                <Player
                                                    autoplay
                                                    loop
                                                    src={card.icon}
                                                    style={{ height: "120px", width: "120px" }}
                                                ></Player>
                                            }
                                        />
                                    }
                                    name={card.name}
                                    description={card.description}
                                    value={card.button}
                                    path={card.path}
                                    targetBlank={false}
                                />


                        ) : (
                            <RedirectCard
                                startContent={
                                    <IconBox
                                        w="60px"
                                        h="60px"
                                        bg={boxBg}
                                        icon={
                                            <Player
                                                autoplay
                                                loop
                                                src={card.icon}
                                                style={{ height: "120px", width: "120px" }}
                                            ></Player>
                                        }
                                    />
                                }
                                name={card.name}
                                description={card.description}
                                value={card.button}
                                targetBlank={false}
                                path={card.path}
                            />
                        )}

                    </>

                ))}
            </SimpleGrid>
        </Box>
    );
}

export default Main;
