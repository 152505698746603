// Chakra imports
import "assets/css/Plugins.css";
import { mutate } from "swr";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  SimpleGrid,
  Stack,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import React, { useMemo, useState } from "react";
import { useAnnouncements } from "../../../lib/hooks";
import { MainTable } from "../../../components/Table/MainTable";
import moment from "moment";
import MiniStatistics from "../../../components/card/MiniStatistics";
import IconBox from "../../../components/icons/IconBox";
import { Player } from "@lottiefiles/react-lottie-player";
import { Link } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

function Announcements() {
  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(10);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCell, setSelectedCell] = useState({});
  const { announcementsData, isError, isLoading } = useAnnouncements(
    page,
    per_page
  );

  const { user } = useAuth0();

  const userRole = user?.["https://admin.b2connect.me/roles"][0];

  const toast = useToast();
  const {
    isOpen: isOpenDeactivate,
    onOpen: onOpenDeactivate,
    onClose: onCloseDeactivae,
  } = useDisclosure();

  const announcementData =
    !isError && !isLoading && announcementsData
      ? announcementsData?.body?.announcements?.map((item) => ({
          title: item.title,
          id: item.id,
          bkStatus: item.status,
          bkExpairy: item.expiry,
          image: item.image,
          bkDescription: item.description,
          category: item.category,
          created: moment(item.created * 1000)
            .utc()
            .format("LL"),
          expiry: moment(item.expiry * 1000)
            .utc()
            .format("LL"),
          status:
            item.status === "ACTIVE" ? (
              <Badge variant="subtle" colorScheme="green">
                Active
              </Badge>
            ) : (
              <Badge variant="subtle">{item.status}</Badge>
            ),
          siteId: item.siteId,
        }))
      : [];
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const data = React.useMemo(() => announcementData, [announcementsData]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [announcError, setAnnouncError] = useState(false);
  const columns = useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "Title",
            accessor: "title",
          },
          {
            Header: "Category",
            accessor: "category",
          },
        ],
      },
      {
        Header: " ",
        columns: [
          {
            Header: "Created",
            accessor: "created",
          },
          {
            Header: "Expiry",
            accessor: "expiry",
          },
          {
            Header: "Status",
            accessor: "status",
          },
        ],
      },
    ],
    []
  );

  const handleDrawer = (data) => {
    setSelectedCell(data);
    onOpen();
  };
  let authToken = localStorage.getItem("auth0_token");
  var todayDate = new Date().toISOString().slice(0, 10);
  //console.log(selectedCell)
  const announcmentStatus = async (e) => {
    //console.log(e);
    if (e.id) {
      if (e.bkStatus == "INACTIVE") {
        const formData = new FormData();
        formData.append("title", e.title);
        formData.append("category", e.category);
        formData.append("description", e.bkDescription);
        formData.append("status", "ACTIVE");
        formData.append("start", todayDate);
        formData.append("expiry", moment(e.expiry).format("x"));

        await axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/announcement/${e.id}`,
            formData,
            {
              header: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          )
          .then((res) => {
            if (!announcError) {
              onCloseDeactivae();
              toast({
                title: "Updated successfully.",
                description: "You've just updated the announcement.",
                status: "success",
                duration: 3000,
                isClosable: true,
                onCloseComplete: () => {
                  onCloseDeactivae();
                  onClose();
                  mutate("announcement/");
                },
              });
            }
          })
          .catch((e) => {
            setAnnouncError(true);
            onCloseDeactivae();
            toast({
              title: "Something went wrong .",
              description: "Please try again or contact the admin.",
              status: "error",
              duration: 3000,
              isClosable: true,
              onCloseComplete: () => {
                onCloseDeactivae();
                onClose();
              },
            });
          });
      } else {
        const formData = new FormData();
        formData.append("title", e.title);
        formData.append("category", e.category);
        formData.append("description", e.bkDescription);
        formData.append("status", "INACTIVE");
        formData.append("start", todayDate);

        formData.append("expiry", todayDate);

        fetch(`https://api-qa2.b2connect.me/management/announcement/${e.id}`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: formData,
        })
          .then((res) => {
            if (!announcError) {
              toast({
                title: "Updated successfully.",
                description: "You've just updated the announcement.",
                status: "success",
                duration: 4000,
                isClosable: true,
                onCloseComplete: () => {
                  onCloseDeactivae();
                  onClose();
                  mutate("announcement/");
                },
              });
            }
          })
          .catch((e) => {
            setAnnouncError(true);
            toast({
              title: "Something went wrong .",
              description: "Please try again or contact the admin.",
              status: "error",
              duration: 4000,
              isClosable: true,
              onCloseComplete: () => {
                onCloseDeactivae();
                onClose();
              },
            });
          });
      }
    }
  };
  //console.log(announcementData)
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Drawer onClose={onClose} isOpen={isOpen} size={"md"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{selectedCell.title}</DrawerHeader>
          <DrawerBody>
            <Flex>
              <FormControl>
                <Stack spacing={3}>
                  <FormLabel htmlFor="title">Title</FormLabel>
                  <Input
                    id="title"
                    type="text"
                    disabled
                    value={selectedCell.title}
                    color={textColor}
                  />
                  <FormLabel htmlFor="title">Description</FormLabel>
                  <Textarea
                    id="title"
                    type="text"
                    disabled
                    noOfLines={8}
                    value={selectedCell.bkDescription}
                    color={textColor}
                  />

                  <FormLabel htmlFor="category">Category</FormLabel>
                  <Input
                    id="category"
                    type="text"
                    disabled
                    value={selectedCell.category}
                    color={textColor}
                  />
                  <FormLabel htmlFor="category">Preview Image</FormLabel>
                  <Image
                    width={"100%"}
                    height={200}
                    src={`https://api-qa.b2connect.me/app/images/${selectedCell.image}`}
                  />
                  <FormLabel htmlFor="created">Created</FormLabel>
                  <Input
                    id="created"
                    type="text"
                    disabled
                    value={selectedCell.created}
                    color={textColor}
                  />
                  <FormLabel htmlFor="expiry">Expiry</FormLabel>
                  <Input
                    id="expiry"
                    type="text"
                    disabled
                    value={selectedCell.expiry}
                    color={textColor}
                  />
                </Stack>
                <Flex>
                  <Button mt={4} onClick={onOpenDeactivate}>
                    {selectedCell.bkStatus === "ACTIVE"
                      ? "Deactivate"
                      : "Activate"}
                  </Button>
                  <AlertDialog
                    isOpen={isOpenDeactivate}
                    onClose={onCloseDeactivae}
                  >
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                          {selectedCell.bkStatus === "ACTIVE"
                            ? "Deactivate announcement"
                            : "Activate announcement"}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                          Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                          <Button onClick={onCloseDeactivae}>Cancel</Button>
                          <Button
                            colorScheme="red"
                            onClick={() => announcmentStatus(selectedCell)}
                            ml={3}
                          >
                            {selectedCell.bkStatus === "ACTIVE"
                              ? "Deactivate"
                              : "Activate"}
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </Flex>
              </FormControl>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          loaded={!isLoading}
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Player
                  autoplay
                  loop
                  src="https://assets3.lottiefiles.com/packages/lf20_dzdrmlop.json"
                  style={{ height: "120px", width: "120px" }}
                ></Player>
              }
            />
          }
          name="Add new"
          value={
            <div p={3}>
              <Button>
                <Link to={"/dashboard/announcement/new"}>Create</Link>{" "}
              </Button>
            </div>
          }
        />
      </SimpleGrid>
      <MainTable
        loaded={!isLoading}
        data={data}
        columns={columns}
        setPage={setPage}
        setPerPage={setPer_page}
        currentpage={page}
        perPage={per_page}
        exports={userRole === "Admin" ? announcementData : false}
        totalPage={parseInt(announcementsData?.body?.pages)}
        drawer={true}
        handleDrawer={handleDrawer}
        name={"Announcements"}
      />
    </Box>
  );
}

export default Announcements;
