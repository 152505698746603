import React from 'react';

function Confirmation(props) {
    return (
        <div style={{paddingTop: 50}}>
            <iframe  frameborder="0" style={{width: "100%", height: "100vh", background:"url(https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/cupertino_activity_indicator.gif) center center no-repeat", backgroudWidth: "20px "}} src="https://zfrmz.com/oyijtqf5KY0a2xCnYcXQ"></iframe>
        </div>
    );
}

export default Confirmation;