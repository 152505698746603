import React, {useEffect, useState} from "react";
import {
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useStyleConfig,
  useToast,
} from "@chakra-ui/react";
import fetcher from "../../../../lib/fetcher";
import moment from "moment";
import {Player} from "@lottiefiles/react-lottie-player";
import {MdArrowDropDown, MdCall, MdContentCopy, MdOutlineEditNote} from "react-icons/md";
import {createTheme} from "react-data-table-component";
import {VerticalTimeline, VerticalTimelineElement,} from "react-vertical-timeline-component";
import {AiOutlineDeploymentUnit} from "react-icons/ai";
import "react-vertical-timeline-component/style.min.css";

import nationalities from "./nationality.json";

import {useSalesPerson, useStatus} from "../../../../lib/hooks";
import {mutate} from "swr";
import {useAuth0} from "@auth0/auth0-react";


function OrderDrawer({ isOpen, onOpen, onClose, selectedOrder, onUpdate, setSelectedOrder }) {



  const toast = useToast();
  const [orderDetails, setOrderDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [historyOrder, setHistoryOrder] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [salesAgentUpdate, setSalesAgentUpdate] = useState("");
  const [orderStatusUpdate, setOrderStatusUpdate] = useState("");
  const [userUpdate, setUserUpdate] = useState("");
  const [loading, setLoading] = useState(true);
  const { isOpen: eidOpen, onOpen:onEidOpen, onClose: onEidClose } = useDisclosure()
  const {status, isLoading, isError} = useStatus(true);
  const {salesPerson, isLoading: salesPersonLoading, isError: salesPersonError} = useSalesPerson(orderDetails?.salesAgentCode);

  const [audioAsset, setAudioAsset] = useState("");
  const [eidName, setEidName] = useState("");
  const [eidNationality, setEidNationality] = useState("");
  const [eidDOB, setEidDOB] = useState("");
  const [eidGender, setEidGender] = useState("");
  const [eidExpiry, setEidExpiry] = useState("");
  const [orderUpdate, setOrderUpdate] = useState(false);

  const [audio] = useState(new Audio(audioAsset));
  const styles = useStyleConfig("Card");
  const bgBox = useColorModeValue("#F4F8FE", "rgb(10, 20, 54)");
  const textColor = useColorModeValue("#000", "white");
  const InputBox = useColorModeValue("white", "rgb(10, 20, 54)");

  const timeLineBox = useColorModeValue("#DDD", "rgb(10, 20, 54)");
  let token = localStorage.getItem("auth0_token");
  const initialRef = React.useRef()
  const finalRef = React.useRef();


  const { user, buildLogoutUrl } = useAuth0();

  const userRole = user?.["https://admin.b2connect.me/roles"][0];

  createTheme("solarized", {
    background: {
      default: "transparent",
    },
    text: {
      primary: "#268bd2",
      secondary: "#2aa198",
    },
  });

  const handleDrawerClose = () => {
    setOrderDetails({});
    setUserDetails({});
    setPaymentDetails([]);
    setHistoryOrder([]);
    setInvoiceDetails([]);
    setSalesAgentUpdate("");
    setOrderStatusUpdate("");
    setEidName("");
    setEidNationality("");
    setEidDOB("");
    setEidGender("");
    setEidExpiry("");
    setAudioAsset("");
    setSelectedOrder({})
    onClose();
  }
  const copyData = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: "Text Copied.",
        description: "",
        position: 'top-right',
        status: "success",
        duration: 2000,
        isClosable: true,

      });
    })
  }

  const fetchOrder = async () => {
    setLoading(true)
    try {
      const order = await fetcher(`orders/${selectedOrder?.orderId}`);
      const user = await fetcher(`orders/${selectedOrder?.orderId}/user`);
      const invoice = await fetcher(`orders/${selectedOrder?.orderId}/invoice`);

      const payments = await fetcher(
        `orders/${selectedOrder?.orderId}/payments`
      );

      const orderHistory = await fetcher(
        `orders/${selectedOrder?.orderId}/history`
      );

      if (orderHistory?.success && orderHistory?.body) {
        setHistoryOrder(orderHistory?.body);
      }
      if (order?.success && order?.body) {
        setOrderDetails(order.body);
      }
      if (user?.success && user?.body) {
        setUserDetails(user?.body);
      }
      if (payments?.success && payments?.body) {
        setPaymentDetails(payments?.body);
      }

      if(invoice?.success && invoice?.body && invoiceDetails.length == 0) {
        setInvoiceDetails([invoice?.body])
      }
      setLoading(false)
    } catch (e) {
      // console.log(e);
    }
  };



  const handelOrderUpdate = async () => {
    let authToken = localStorage.getItem("auth0_token");

    const orderUpdate = await fetch(process.env.REACT_APP_BASE_URL+ `/orders/${selectedOrder?.orderId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        salesAgentCode: salesAgentUpdate ? salesAgentUpdate : orderDetails?.salesAgentCode,
        status: orderStatusUpdate ? orderStatusUpdate : orderDetails.status
      }),
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
    });
    if(orderUpdate.status < 399) {
      audio.src = "/sounds/mail_sent.mp3";
      await audio.play();
       await mutate("orders/`", true);

      toast({
        title: "Order Updated.",
        description: "",
        position: 'top-right',
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      await fetchOrder();
    }else{
      audio.src = "/sounds/error.mp3";
      await audio.play();
      toast({
        title: "Something Went Wrong.",
        description: "Please try again later or reach out to the support team.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  const eidUpdateHandler = async () => {
    let payload = {
      name: eidName || userDetails?.emiratesProfile?.name,
      sex: eidGender || userDetails?.emiratesProfile?.sex,
      dateOfBirth: moment(eidDOB || userDetails?.emiratesProfile?.dateOfBirth).unix(),
      emiratesId: userDetails?.emiratesProfile?.emiratesId,
      expiryDate: moment(eidExpiry || userDetails?.emiratesProfile?.expiryDate).unix(),
      nationality: eidNationality
    }
    await fetch(`${process.env.REACT_APP_BASE_URL}/users/${userDetails?.id}/emirates-id`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify(payload),
        }
    ).then(async (res) => {
      if(res.status < 399) {
        audio.src = "/sounds/mail_sent.mp3";
        await audio.play();
        setUserUpdate(payload)
        toast({
          title: "User EID Updated.",
          description: "",
          position: 'top-right',
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        onEidClose(true)
      }else{
        audio.src = "/sounds/error.mp3";
        await audio.play();
        toast({
          title: "Something Went Wrong.",
          description: "Please try again later or reach out to the support team.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }).catch(async (e) => {
      audio.src = "/sounds/error.mp3";
      await audio.play();
      toast({
        title: "Something Went Wrong.",
        description: "Please try again later or reach out to the support team.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    })
  }
  function capitalizeFirstLetter(string) {
    if(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);

    }
  }
  useEffect(() => {
    // handleDrawerClose()
    fetchOrder();
  }, [ userUpdate,selectedOrder, onClose]);

  return (
      <>
        {orderDetails && userDetails && (
            <>
              <Drawer onClose={handleDrawerClose} isOpen={isOpen} size={"full"}>
                <DrawerOverlay />
                <DrawerContent background={bgBox}>
                  <DrawerCloseButton />
                  <DrawerHeader>
                    <Flex flexDir={"column"} >
                <span>
              {orderDetails?.salesOrderNo}   ({moment(orderDetails?.time).utc().format("MMMM Do YYYY, h:mm:ss a")})
                </span>
                      <span my={3} style={{fontSize: 14, color: "gray"}}>
                        {orderDetails?.appPlatform && (`Placed on ${capitalizeFirstLetter(orderDetails?.appPlatform)}`)}  {orderDetails?.appVersion && (`Version ${capitalizeFirstLetter(orderDetails?.appVersion)}`)}
                      </span>
                      <Flex  p={2} flexDir={"row"} justifyContent={"space-between"}>
                        <Skeleton isLoaded={!loading}  fadeDuration={1.5} borderRadius={20}>
                        <Flex flexDir={{ base: "column", md: "column", xl: "row" }} alignSelf={"flex-end"}>
                          <Flex>
                            <Menu >
                              <MenuButton as={Button} rightIcon={<MdArrowDropDown />}>
                                {capitalizeFirstLetter(orderStatusUpdate) || capitalizeFirstLetter(orderDetails?.status) || "Select status"}
                              </MenuButton>
                              <MenuList >
                                {status?.body?.statuses?.map((item, i) => {
                                  if(item != "initiated" && item != "created") {
                                    return (
                                        <MenuItem key={i} color={textColor} value={item} fontSize={14} onClick={(e) => {
                                          setOrderUpdate(true)
                                          setOrderStatusUpdate(e.target.value)

                                        }}>
                                          {capitalizeFirstLetter(item)}
                                        </MenuItem>
                                    )
                                  }})}
                              </MenuList>
                            </Menu>
                          </Flex>
                          <Flex>
                            <Input placeholder='Sales Agent' onChange={(e) => {
                              setSalesAgentUpdate(e.target.value)
                              setOrderUpdate(true)
                            }} defaultValue={orderDetails?.salesAgentCode} background={InputBox} w={320} my={{ base: 2, md: 2, xl: 0 }} mx={{ base: 0, md: 0, xl: 2 }} disabled={userRole !== "ProductManager" && userRole !== "Admin"}/>
                          </Flex>
                          <Flex>
                            <Button  onClick={handelOrderUpdate} disabled={!orderUpdate}>Update</Button>
                          </Flex>
                        </Flex>
                        </Skeleton>
                      </Flex>
                    </Flex>
                  </DrawerHeader>
                  <DrawerBody>
                    <Grid
                        templateRows={{
                          base: "repeat(1, 2fr)",
                          md: "repeat(1, 2fr)",
                          lg: "repeat(1, 1fr)",
                          "2xl": "repeat(1, 1fr)",
                        }}
                        templateColumns={{
                          base: "repeat(1, 1fr)",
                          md: "repeat(2, 2fr)",
                          lg: "repeat(4, 2fr)",
                          "2xl": "repeat(5, 2fr)",
                        }}
                        gap="20px"
                    >
                      <GridItem colSpan={{ base: 4, md: 3, lg: 3, "2xl": 4 }}>
                        <Skeleton isLoaded={!loading}  fadeDuration={1.5} borderRadius={20}>
                        <Flex __css={styles} mb={3} flexDirection={"column"}>
                          <Flex flexDirection={"row"} justifyContent={"space-between"}>
                            <Flex>
                              <Player
                                  autoplay
                                  loop
                                  src="https://assets10.lottiefiles.com/packages/lf20_504lfkem.json"
                                  style={{ height: "32px", width: "32px" }}
                              ></Player>
                              <Heading mt={1} ml={2} size={"md"} fontWeight={600}>
                                Items ({orderDetails?.items?.length})
                              </Heading>
                            </Flex>
                            {orderDetails?.isAddon && (
                                <>
                                  <Flex flexDir={"row"} alignItems={"center"}>
                                    <Flex>
                                      <Text  color={"#e24379"}>
                                        WIFI Addon
                                      </Text>
                                    </Flex>
                                  </Flex>
                                </>
                            )}
                          </Flex>



                          {orderDetails?.items?.map((item, i) => (
                              <Flex key={i} flexDir={["column", "column", "row"]} p={3}>
                                <Flex>
                                  <Image
                                      src={item.images[0]}
                                      width={20}
                                      height={20}
                                      borderRadius={10}
                                  />
                                </Flex>
                                <Flex ml={4} flexDirection={"row"}>
                                  <Flex flexDir={["column", "column", "column",]}>
                                    {item.name && (
                                        <Text fontSize={"lg"} fontWeight={600}>
                                          {item.name}
                                        </Text>
                                    )}

                                    {item.sku && (
                                        <Text fontSize={"sm"} fontWeight={500} color={"gray"}>
                                          SKU: {item.sku}
                                        </Text>
                                    )}

                                    <Flex flexDir={"row"} justifyContent={"space-between"}>
                                      {item.offerId && (
                                          <Text fontSize={"sm"} fontWeight={500} color={"gray"}>
                                            Offer ID: {item.offerId}
                                          </Text>
                                      )}
                                      {orderDetails?.salesAgentCode && (
                                          <Text fontSize={"sm"} fontWeight={500} color={"gray"}>
                                            Sales Agent: {orderDetails?.salesAgentCode}
                                          </Text>
                                      )}

                                    </Flex>
                                  </Flex>
                             
                                </Flex>
                              </Flex>
                          ))}

                        </Flex>
                        </Skeleton>
                      </GridItem>

                      <GridItem rowSpan={4} colSpan={{ base: 4, md: 1, lg: 1, "2xl": 1 }}>
                        <Skeleton isLoaded={!loading}  fadeDuration={1.5} borderRadius={20}>
                        <Flex
                            position={"sticky"}
                            flexDir={"column"}
                            __css={styles}
                            sx={{
                              position: "sticky",

                              top: "0",
                            }}
                        >
                          <Flex p={4} flexDir={"column"}>
                            <Flex flexDir={"column"} mb={3}>
                              <Flex>
                                <Heading size={"sm"}>CUSTOMER</Heading>
                              </Flex>
                            </Flex>

                            <Flex>
                              <Flex flexDir={"column"}>
                                <Flex>
                                  <Text>
                                    {userDetails[0]?.firstName} {userDetails[0]?.lastName}
                                  </Text>
                                </Flex>
                              </Flex>
                            </Flex>

                            <Divider pt={2} orientation="horizontal" />
                          </Flex>

                          <Flex p={4} flexDir={"column"}>
                            <Flex flexDir={"column"} mb={3}>
                              <Flex>
                                <Heading size={"sm"}>Contact Information</Heading>
                              </Flex>
                            </Flex>

                            <Flex flexDir={"row"} justifyContent={"space-between"}>
                              <Text fontSize={13}>
                                {userDetails?.contactPhone
                                    ? userDetails.contactPhone
                                    : "No user phone number"}
                              </Text>
                              <Flex>
                                {userDetails?.contactPhone
                                    ? (
                                        <Flex flexDir={"row"} justifyContent={"space-between"}>
                                          <Flex pr={3} cursor={"pointer"} onClick={() => copyData(userDetails?.contactPhone)}>
                                            <Link href={`to:${userDetails?.contactPhone}`}  target={"_blank"}>
                                            <MdCall />
                                            </Link>
                                          </Flex>
                                          <Flex cursor={"pointer"} onClick={() => copyData(userDetails?.contactPhone)}>
                                            <MdContentCopy />
                                          </Flex>

                                        </Flex>

                                    ) : null}
                              </Flex>
                            </Flex>

                            <Flex flexDir={"row"} justifyContent={"space-between"} pt={2}>
                              <Text fontSize={13}>
                                {userDetails?.email
                                    ? userDetails?.email
                                    : "No user email"}
                              </Text>
                              <Flex>
                                {userDetails?.email
                                    ? (
                                        <Flex cursor={"pointer"} onClick={() => copyData(userDetails?.email)}>
                                          <MdContentCopy />
                                        </Flex>

                                    ) : null}

                              </Flex>
                            </Flex>
                            <Flex flexDir={"row"} justifyContent={"space-between"} pt={2}>
                              <Text fontSize={13}>
                                {orderDetails?.userZone}
                              </Text>
                              
                            </Flex>
                            <Divider pt={2} orientation="horizontal" />
                          </Flex>



                          <Flex>
                            <Modal
                                initialFocusRef={initialRef}
                                finalFocusRef={finalRef}
                                isOpen={eidOpen}
                                onClose={onEidClose}
                            >
                              <ModalOverlay />
                              <ModalContent>
                                <ModalHeader>EID Details</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody pb={6}>
                                  <Flex>
                                    <FormControl >
                                      <FormLabel>First name</FormLabel>
                                      <Input color={textColor}  placeholder='Name' defaultValue={userDetails?.emiratesProfile?.name} onChange={(e) => setEidName(e.target.value)}/>
                                    </FormControl>
                                    <FormControl   ml={4}>
                                      <FormLabel>Nationality</FormLabel>
                                      <Select color={textColor} placeholder={"Nationality"} onChange={(e) => setEidNationality(e.target.value)}>
                                        {nationalities.map((item, i) => (
                                            <option key={i} value={item.name} selected={item.name === userDetails?.emiratesProfile?.nationality}>
                                              {item.name.toUpperCase()}
                                            </option>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Flex>

                                  <Flex>
                                    <FormControl mt={4} >
                                      <FormLabel>Gender</FormLabel>
                                      <Select placeholder={"Select Gender"} onChange={(e) => setEidGender(e.target.value)}>
                                        <option selected={userDetails?.emiratesProfile?.sex == "MALE"} value='MALE'>Male</option>
                                        <option  selected={userDetails?.emiratesProfile?.sex === "FEMALE"} value='FEMALE'>Female</option>
                                      </Select>
                                    </FormControl>
                                    <FormControl mt={4} ml={4}>
                                      <FormLabel>EID Number</FormLabel>
                                      <Input color={textColor} placeholder='EID Number' value={userDetails?.emiratesProfile?.emiratesId} disabled/>
                                    </FormControl>
                                  </Flex>
                                  <FormControl mt={4}>
                                    <FormLabel>Date of Birth</FormLabel>
                                    <Input  type={"date"}  defaultValue={moment(userDetails?.emiratesProfile?.dateOfBirth).calendar()} onChange={(e) => setEidDOB(e.target.value)}/>

                                  </FormControl>
                                  <FormControl mt={4} >
                                    <FormLabel>Expiry Date</FormLabel>
                                    <Input  type={"date"} defaultValue={moment(userDetails?.emiratesProfile?.expiryDate).calendar()} onChange={(e) => setEidExpiry(e.target.value)} />
                                  </FormControl>
                                </ModalBody>

                                <ModalFooter>
                                  <Button colorScheme='pink' mr={3} onClick={eidUpdateHandler}>
                                    Update
                                  </Button>
                                  <Button onClick={onEidClose}>Cancel</Button>
                                </ModalFooter>
                              </ModalContent>
                            </Modal>
                          </Flex>
                          <Flex p={4} flexDir={"column"}>
                            <Flex flexDir={"column"} mb={3}>
                              <Flex flexDir={"row"}>
                                <Heading size={"sm"}>EID Details</Heading>
                                <Flex ml={3} cursor={"pointer"} onClick={onEidOpen}>
                                  <MdOutlineEditNote size={22}/>
                                </Flex>
                              </Flex>
                            </Flex>

                            <Flex flexDir={"column"} justifyContent={"space-between"}>
                              <Flex flexDir={"row"} justifyContent={"space-between"}>
                                <Flex flexDir={"column"} >
                                  <Text color={"gray"} fontSize={"sm"}>NAME</Text>
                                  <Text>{userDetails?.emiratesProfile?.name || "Not Provided"}</Text>
                                </Flex>
                                <Flex flexDir={"column"} >
                                  <Text color={"gray"} fontSize={"sm"}>NATIONALITY</Text>
                                  <Text>{userDetails?.emiratesProfile?.nationality || "Not Provided"}</Text>
                                </Flex>
                              </Flex>

                              <Flex flexDir={"column"} justifyContent={"space-between"} my={3}>
                                <Flex flexDir={"column"}  my={3}>
                                  <Text color={"gray"} fontSize={"sm"}>EID NUMBER</Text>
                                  <Text>{userDetails?.emiratesProfile?.emiratesId || "Not Provided"}</Text>
                                </Flex>
                                <Flex flexDir={"column"} >
                                  <Text color={"gray"} fontSize={"sm"}>GENDER</Text>
                                  <Text>{userDetails?.emiratesProfile?.sex || "Not Provided"}</Text>
                                </Flex>

                              </Flex>


                              <Flex flexDir={"row"} justifyContent={"space-between"} my={2}>
                                <Flex flexDir={"column"} >
                                  <Text color={"gray"} fontSize={"sm"}>DATE OF BIRTHDAY</Text>
                                  <Text>{ userDetails?.emiratesProfile?.dateOfBirth ? moment(userDetails?.emiratesProfile?.dateOfBirth).format("LL") : "Not provided"}</Text>
                                </Flex>
                                <Flex flexDir={"column"} >
                                  <Text color={"gray"} fontSize={"sm"}>EXPIRY</Text>
                                  <Text>{moment(userDetails?.emiratesProfile?.expiryDate).format("LL") }</Text>
                                </Flex>
                              </Flex>
                            </Flex>
                          </Flex>

                        </Flex>
                        </Skeleton>
                      </GridItem>

                      <GridItem rowSpan={1} colSpan={{ base: 4, md: 2, lg: 3, "2xl": 2 }}>
                        <Skeleton isLoaded={!loading}  fadeDuration={1.5} borderRadius={20}>

                        <Flex __css={styles}>

                          <Flex p={1} flexDir={"column"}>
                            <Flex>
                              <Heading size={"sm"}>Order payment</Heading>
                            </Flex>
                            <Flex pt={3} flexDir={"row"} justifyContent={"space-between"}>
                              <Flex flexDir={"column"}>
                                <Text fontSize={"lg"}>Payment Type:</Text>
                                <Text fontSize={"sm"}>{orderDetails?.paymentMethod}</Text>
                              </Flex>
                              <Flex>
                                <Text fontSize={"lg"} fontWeight={900}>
                                  Total AED {orderDetails?.total}
                                </Text>
                              </Flex>
                            </Flex>
                            {!salesPersonLoading && !salesPersonError && salesPerson?.length !== 0 && (
                                <Flex py={3}>
                                  <Divider />
                                </Flex>

                            )}

                            {!salesPersonLoading && !salesPersonError && salesPerson?.length !== 0 && (
                                <Flex pt={3} flexDir={"row"} justifyContent={"space-between"}>
                                  <Flex flexDir={"column"}>
                                    <Text fontSize={"lg"}>Sales Person:</Text>
                                    <Text fontSize={"sm"}> {salesPerson[0]?.salesperson_email}</Text>
                                    <Text fontSize={"sm"}>Status:  {salesPerson[0]?.is_active ? "Active" : "InActive"}</Text>

                                  </Flex>
                                  <Flex>
                                    <Text fontSize={"lg"} color={"gray.600"}>
                                      {salesPerson[0]?.salesperson_name}
                                    </Text>
                                  </Flex>
                                </Flex>

                            )}
                          </Flex>
                          {paymentDetails.length !== 0 && (
                              <Flex py={3}>
                                <Divider />

                              </Flex>
                          )}
                          {invoiceDetails.length !== 0 && invoiceDetails &&  (
                              <Box pt={3}>
                                <Flex flexDirection={"row"}>
                                  <Player
                                      autoplay
                                      loop
                                      src="https://assets10.lottiefiles.com/private_files/lf30_w41yuaao.json"
                                      style={{ height: "32px", width: "32px" }}
                                  ></Player>
                                  <Heading mt={1} ml={2} size={"sm"} fontWeight={600}>
                                    Invoice
                                  </Heading>
                                </Flex>
                                <TableContainer sx={{
                                  '&::-webkit-scrollbar': {
                                    width: '16px',
                                    borderRadius: '8px',
                                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                                  },
                                  '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                                    height: "5px",
                                    width: "5px"
                                  },
                                }}>
                                  <Table variant='simple' overflow={"auto"}>

                                    <Thead>
                                      <Tr>
                                        <Th >Customer Name</Th>
                                        <Th>Invoice Number</Th>
                                        <Th>Date</Th>
                                        <Th>Status</Th>
                                        <Th>Balance</Th>

                                      </Tr>
                                    </Thead>
                                    <Tbody>
                                      {invoiceDetails?.map((item, index) => (
                                          <Tr key={index}>
                                            <Td >
                                              <Flex flexDir={"row"} justifyContent={"center"} alignItems={"center"}>
                                                <Flex>
                                                  <Text>
                                                    {item.customerName}
                                                  </Text>
                                                </Flex>

                                              </Flex>
                                            </Td>
                                            <Td><Flex flexDir={"row"} justifyContent={"center"} alignItems={"center"}>
                                              <Flex>
                                                <Text>
                                                  {item.invoiceNumber}
                                                </Text>
                                              </Flex>
                                              <Flex ml={1} cursor={"pointer"} onClick={() => copyData(item.invoiceNumber)}>
                                                <MdContentCopy />
                                              </Flex>
                                            </Flex>
                                            </Td>
                                            <Td > <Text>{item?.date ? moment(item?.date).format("LL") : "No Date"} </Text></Td>
                                            <Td >   {item.status == "paid" ? (
                                                <Badge colorScheme={"green"}>
                                                  <Flex>
                                                    {item.status.replace("_", "  ")}

                                                  </Flex>
                                                </Badge>
                                            ) : null}
                                              {item.status == "partially_paid" ? (
                                                  <Badge variant='outline' colorScheme={"green"}>
                                                    <Flex>
                                                      {item.status.replace("_", "  ")}

                                                    </Flex>
                                                  </Badge>
                                              ) : null}
                                              {item.status != "partially_paid" &&  item.status != "paid"&& (
                                                  <Badge variant='outline' colorScheme={"green"}>
                                                    <Flex>
                                                      {item.status.replace("_", "  ")}

                                                    </Flex>
                                                  </Badge>
                                              ) }
                                            </Td>
                                            <Td fontWeight={600}>   {item.balance > 0 ? (
                                                <Badge variant='outline' colorScheme={"red"}>
                                                  <Flex>
                                                    <Text>
                                                      AED {item.balance}
                                                    </Text>


                                                  </Flex>
                                                </Badge>

                                            ) : (
                                                <Text fontWeight={900}>AED {item.balance}</Text>
                                            )}</Td>

                                          </Tr>


                                      ))}

                                    </Tbody>
                                  </Table>
                                </TableContainer>


                              </Box>
                          )}
                          {paymentDetails.length !== 0 && (

                              <Flex flexDir={"column"} mt={4}>
                                <Flex mb={3}>
                                  <Tooltip label={"All payments history "}>
                                    <Heading size={"sm"}>Payments History</Heading>

                                  </Tooltip>
                                </Flex>
                                <TableContainer sx={{
                                  '&::-webkit-scrollbar': {
                                    width: '16px',
                                    borderRadius: '8px',
                                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                                  },
                                  '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                                    height: "5px",
                                    width: "5px"
                                  },
                                }}>
                                  <Table variant='simple' overflow={"auto"} >

                                    <Thead>
                                      <Tr>
                                        <Th >Invoice Number</Th>
                                        <Th>Payment Mode</Th>
                                        <Th>Transaction Id</Th>
                                        <Th>Date</Th>
                                        <Th>Amount</Th>

                                      </Tr>
                                    </Thead>
                                    <Tbody>
                                      {paymentDetails?.map((item, index) => (
                                              <Tr key={index}>
                                                <Td >
                                                  <Flex flexDir={"row"} justifyContent={"center"} alignItems={"center"}>
                                                    <Flex>
                                                      <Text>
                                                        {item.invoiceNumber}
                                                      </Text>
                                                    </Flex>
                                                    <Flex ml={1} cursor={"pointer"} onClick={() => copyData(item.invoiceNumber)}>
                                                      <MdContentCopy />
                                                    </Flex>
                                                  </Flex>
                                                  </Td>
                                                <Td>{item?.paymentMode}</Td>
                                                <Td > {item?.transactionId}</Td>
                                                <Td > {item?.stamp ? moment(item?.stamp).format("LL") : "No Date"}</Td>
                                                <Td fontWeight={600}> AED {item?.paidAmount}</Td>

                                              </Tr>


                                      ))}

                                    </Tbody>
                                  </Table>
                                </TableContainer>


                              </Flex>
                          )}

                          {orderDetails?.installmentInfo?.length !== 0 &&  (

                              <Flex flexDir={"column"} pt={5}>
                                <Flex py={3} flexDir={"row"} justifyContent={"space-between"}>
                                  <Flex>
                                    <Tooltip label={orderDetails?.installmentInfo?.length === 6 ? "6 Months EMI Payments Schedule" : "3 Months EMI Payments Schedule"}>
                                      <Heading size={"sm"}>Payment Schedule</Heading>
                                    </Tooltip>

                                  </Flex>

                                    {orderDetails?.installmentInfo?.length  && orderDetails?.tenure ? (
                                        <Heading size={"sm"}> <Flex>
                                          <Flex>
                                            <Text>
                                              {orderDetails?.tenure || orderDetails?.installmentInfo?.length} Months EMI
                                            </Text>
                                          </Flex>
                                          <Flex>
                                            {orderDetails?.isAddon && (
                                                <>
                                                  <Flex ml={2} flexDir={"row"} alignItems={"center"}>



                                                      <Text color={"#e24379"}>
                                                         with WIFI Addon
                                                      </Text>

                                                    </Flex>


                                                </>

                                            )}
                                          </Flex>
                                        </Flex>    </Heading>
                                    ) : null}


                                </Flex>
                                <TableContainer>
                                  <Table variant='simple'>

                                    <Thead>
                                      <Tr>
                                        <Th >Amount</Th>
                                        <Th>Due Date</Th>
                                        <Th isNumeric>Status</Th>

                                      </Tr>
                                    </Thead>
                                    <Tbody>
                                      {orderDetails?.installmentInfo?.length !== 0
                                          ? orderDetails?.installmentInfo?.map((item, i) => (
                                              <Tooltip key={i} label={`AED ${item?.price} to be paid on ${moment.utc(item?.dueDate).format("MMMM Do YYYY, h:mm:ss a")}`}>
                                              <Tr>

                                                <Td fontWeight={600}>
                                                    AED {item?.price}
                                                </Td>
                                                <Td>{moment.utc(item?.dueDate).format("LL")}</Td>
                                                <Td isNumeric> {item?.status === "EMI OVERDUE" ? (
                                                    <Badge variant={"outline"} colorScheme={"red"}>{item?.status  || "Pending"}</Badge>
                                                ) : (
                                                    <Badge variant={"outline"} colorScheme={item?.status === "PAID" ? "green" : "gray"}>
                                                      {item?.status  || "Pending"}
                                                    </Badge>
                                                )}</Td>
                                              </Tr>
                                              </Tooltip>

                                          ))
                                          : null}
                                    </Tbody>
                                  </Table>
                                </TableContainer>



                              </Flex>
                          )}
                        </Flex>
                        </Skeleton>
                      </GridItem>

                      <GridItem rowSpan={1} colSpan={{ base: 4, md: 3, lg: 3, "2xl": 2 }}>
                        <Skeleton isLoaded={!loading}  fadeDuration={1.5} borderRadius={20}>
                        <Flex __css={styles} background={"transparent"}>
                          <VerticalTimeline
                              lineColor={timeLineBox}
                              layout={"1-column-left"}
                          >
                            {historyOrder.length !== 0
                                ? historyOrder.map((item, i) => (
                                    <VerticalTimelineElement
                                        key={i}
                                        contentStyle={{
                                          background: "transparent",

                                          color: textColor,
                                        }}
                                        iconStyle={{
                                          background: "rgb(51, 62, 96)",
                                          color: "#fff",
                                        }}
                                        icon={<AiOutlineDeploymentUnit />}
                                    >
                                      <h3>{moment(item.stamp).format("MMMM Do YYYY, h:mm:ss a")}</h3>
                                      <Flex >
                                        <h4>
                                          {item.status.charAt(0).toUpperCase() +
                                              item.status.slice(1)}
                                        </h4>
                                      </Flex>
                                      <p>{item.comment}</p>
                                    </VerticalTimelineElement>
                                ))
                                : null}
                          </VerticalTimeline>
                        </Flex>
                        </Skeleton>
                      </GridItem>
                    </Grid>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </>
        )}
      </>
  );
}

export default OrderDrawer;
