import React from "react";

import IconBox from "../../../../components/icons/IconBox";
import {Player} from "@lottiefiles/react-lottie-player";
import {Box, SimpleGrid, useColorModeValue} from "@chakra-ui/react";
import NotificationCard from "../components/NotificationCard";

const notificationsType = [
  {
    id: 1,
    name: "Send via SMS",
    icon: "https://assets9.lottiefiles.com/packages/lf20_0o15jarv.json",
    description: "Send an individual SMS",
    button: "Send",
    path: "/dashboard/individual/sms",
    type: "sms",
    status: "active",
  },
  {
    id: 2,
    name: "Send via App",
    icon: "https://assets4.lottiefiles.com/packages/lf20_tgeyhrvk.json",
    description: "Send In-App notification",
    button: "In App",
    path: "/dashboard/individual/app",
    type: "in-app",
    status: "active",
  },
  {
    id: 2,
    name: "Send via Email",
    icon: "https://assets8.lottiefiles.com/packages/lf20_kse8jmzt.json",
    description: "Send an individual email",
    button: "Coming soon",
    path: "#",
    type: "in-app",
    status: "active",
  },
  {
    id: 2,
    name: "Send via WhatsApp",
    icon: "https://assets6.lottiefiles.com/private_files/lf30_lghsciar.json",
    description: "Send WhatsApp message",
    button: "Coming soon",
    path: "#",
    type: "in-app",
    status: "active",
  },
];

function Index() {
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        {notificationsType.map((card, i) => (
          <NotificationCard
              key={i}
            startContent={
              <IconBox
                w="60px"
                h="60px"
                bg={boxBg}
                icon={
                  <Player
                    autoplay
                    loop
                    src={card.icon}
                    style={{ height: "120px", width: "120px" }}
                  ></Player>
                }
              />
            }
            name={card.name}
            description={card.description}
            value={card.button}
            path={card.path}
            type={card.type}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
}

export default Index;
