import axios from "axios";

export default function fetcher(url, data = undefined) {
  let authToken = localStorage.getItem("auth0_token");

  return axios({
    method: data ? "POST" : "GET",
    url: `${process.env.REACT_APP_BASE_URL}/${url}`,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    data,
  }).then((res) => {
    if (res.status > 399 && res.status < 200) {
      throw new Error();
    }
    return res.data;
  });
}

export const fetcherNode = (url, data = undefined) => {
  let accessToken = "b3652498-8d52-45ac-9df7-89b9745f0675";

  return axios({
    method: data ? "POST" : "GET",
    url: `${process.env.REACT_APP_NODE_BACKEND}/${url}`,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "accessToken": `${accessToken}`,
    },
    data,
  }).then((res) => {
    if (res.status > 399 && res.status < 200) {
      throw new Error();
    }
    return res.data;
  });
}
