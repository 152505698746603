import React from 'react';
import {Flex, Tab, TabList, Tabs} from "@chakra-ui/react";
import {Link} from "react-router-dom";

function Analytics(props) {
    return (
        <Flex>
            <Flex mt={20} flexDir={"row"} justifyContent={"space-between"} >
                <Flex>
                    <Tabs size={"lg"}  colorScheme={"pink"} defaultIndex={1}>
                        <TabList  >
                            <Tab ><Link to={"/dashboard/sales"}>All Orders</Link> </Tab>
                            <Tab isSelected={true}>Orders Analytics</Tab>
                            <Tab>Completed</Tab>
                            <Tab>Cancelled</Tab>

                        </TabList>
                    </Tabs>
                </Flex>

            </Flex>
        </Flex>
    );
}

export default Analytics;