import { Step, Steps, useSteps } from "chakra-ui-steps";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Select,
  SimpleGrid,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useDepartment, useJobRoles, useLocations } from "../../../lib/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

let nationalities = [
  "INDIA",
  "PAKISTAN",
  "PHILIPPINES",
  "BANGLADESH",
  "AFGHANISTAN",
  "ALAND ISLANDS",
  "ALBANIA",
  "ALGERIA",
  "AMERICAN SAMOA",
  "ANDORRA",
  "ANGOLA",
  "ANGUILLA",
  "ANTARCTICA",
  "ANTIGUA AND BARBUDA",
  "ARGENTINA",
  "ARMENIA",
  "ARUBA",
  "AUSTRALIA",
  "AUSTRIA",
  "AZERBAIJAN",
  "BAHAMAS",
  "BAHRAIN",
  "BARBADOS",
  "BELARUS",
  "BELGIUM",
  "BELIZE",
  "BENIN",
  "BERMUDA",
  "BHUTAN",
  "BOLIVIA",
  "BONAIRE, SINT EUSTATIUS AND SABA",
  "BOSNIA AND HERZEGOVINA",
  "BOTSWANA",
  "BOUVET ISLAND",
  "BRAZIL",
  "BRITISH INDIAN OCEAN TERRITORY",
  "BRUNEI DARUSSALAM",
  "BULGARIA",
  "BURKINA FASO",
  "BURUNDI",
  "CAMBODIA",
  "CAMEROON",
  "CANADA",
  "CAPE VERDE",
  "CAYMAN ISLANDS",
  "CENTRAL AFRICAN REPUBLIC",
  "CHAD",
  "CHILE",
  "CHINA",
  "CHRISTMAS ISLAND",
  "COCOS (KEELING) ISLANDS",
  "COLOMBIA",
  "COMOROS",
  "CONGO",
  "CONGO, DEMOCRATIC REPUBLIC OF THE CONGO",
  "COOK ISLANDS",
  "COSTA RICA",
  "COTE D'IVOIRE",
  "CROATIA",
  "CUBA",
  "CURACAO",
  "CYPRUS",
  "CZECH REPUBLIC",
  "DENMARK",
  "DJIBOUTI",
  "DOMINICA",
  "DOMINICAN REPUBLIC",
  "ECUADOR",
  "EGYPT",
  "EL SALVADOR",
  "EQUATORIAL GUINEA",
  "ERITREA",
  "ESTONIA",
  "ETHIOPIA",
  "FALKLAND ISLANDS (MALVINAS)",
  "FAROE ISLANDS",
  "FIJI",
  "FINLAND",
  "FRANCE",
  "FRENCH GUIANA",
  "FRENCH POLYNESIA",
  "FRENCH SOUTHERN TERRITORIES",
  "GABON",
  "GAMBIA",
  "GEORGIA",
  "GERMANY",
  "GHANA",
  "GIBRALTAR",
  "GREECE",
  "GREENLAND",
  "GRENADA",
  "GUADELOUPE",
  "GUAM",
  "GUATEMALA",
  "GUERNSEY",
  "GUINEA",
  "GUINEA-BISSAU",
  "GUYANA",
  "HAITI",
  "HEARD ISLAND AND MCDONALD ISLANDS",
  "HOLY SEE (VATICAN CITY STATE)",
  "HONDURAS",
  "HONG KONG",
  "HUNGARY",
  "ICELAND",
  "INDONESIA",
  "IRAN, ISLAMIC REPUBLIC OF",
  "IRAQ",
  "IRELAND",
  "ISLE OF MAN",
  "ISRAEL",
  "ITALY",
  "JAMAICA",
  "JAPAN",
  "JERSEY",
  "JORDAN",
  "KAZAKHSTAN",
  "KENYA",
  "KIRIBATI",
  "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
  "KOREA, REPUBLIC OF",
  "KOSOVO",
  "KUWAIT",
  "KYRGYZSTAN",
  "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
  "LATVIA",
  "LEBANON",
  "LESOTHO",
  "LIBERIA",
  "LIBYAN ARAB JAMAHIRIYA",
  "LIECHTENSTEIN",
  "LITHUANIA",
  "LUXEMBOURG",
  "MACAO",
  "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
  "MADAGASCAR",
  "MALAWI",
  "MALAYSIA",
  "MALDIVES",
  "MALI",
  "MALTA",
  "MARSHALL ISLANDS",
  "MARTINIQUE",
  "MAURITANIA",
  "MAURITIUS",
  "MAYOTTE",
  "MEXICO",
  "MICRONESIA, FEDERATED STATES OF",
  "MOLDOVA, REPUBLIC OF",
  "MONACO",
  "MONGOLIA",
  "MONTENEGRO",
  "MONTSERRAT",
  "MOROCCO",
  "MOZAMBIQUE",
  "MYANMAR",
  "NAMIBIA",
  "NAURU",
  "NEPAL",
  "NETHERLANDS",
  "NETHERLANDS ANTILLES",
  "NEW CALEDONIA",
  "NEW ZEALAND",
  "NICARAGUA",
  "NIGER",
  "NIGERIA",
  "NIUE",
  "NORFOLK ISLAND",
  "NORTHERN MARIANA ISLANDS",
  "NORWAY",
  "OMAN",
  "PALAU",
  "PALESTINIAN TERRITORY, OCCUPIED",
  "PANAMA",
  "PAPUA NEW GUINEA",
  "PARAGUAY",
  "PERU",
  "PITCAIRN",
  "POLAND",
  "PORTUGAL",
  "PUERTO RICO",
  "QATAR",
  "REUNION",
  "ROMANIA",
  "RUSSIAN FEDERATION",
  "RWANDA",
  "SAINT BARTHELEMY",
  "SAINT HELENA",
  "SAINT KITTS AND NEVIS",
  "SAINT LUCIA",
  "SAINT MARTIN",
  "SAINT PIERRE AND MIQUELON",
  "SAINT VINCENT AND THE GRENADINES",
  "SAMOA",
  "SAN MARINO",
  "SAO TOME AND PRINCIPE",
  "SAUDI ARABIA",
  "SENEGAL",
  "SERBIA",
  "SERBIA AND MONTENEGRO",
  "SEYCHELLES",
  "SIERRA LEONE",
  "SINGAPORE",
  "SINT MAARTEN",
  "SLOVAKIA",
  "SLOVENIA",
  "SOLOMON ISLANDS",
  "SOMALIA",
  "SOUTH AFRICA",
  "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
  "SOUTH SUDAN",
  "SPAIN",
  "SRI LANKA",
  "SUDAN",
  "SURINAME",
  "SVALBARD AND JAN MAYEN",
  "SWAZILAND",
  "SWEDEN",
  "SWITZERLAND",
  "SYRIAN ARAB REPUBLIC",
  "TAIWAN, PROVINCE OF CHINA",
  "TAJIKISTAN",
  "TANZANIA, UNITED REPUBLIC OF",
  "THAILAND",
  "TIMOR-LESTE",
  "TOGO",
  "TOKELAU",
  "TONGA",
  "TRINIDAD AND TOBAGO",
  "TUNISIA",
  "TURKEY",
  "TURKMENISTAN",
  "TURKS AND CAICOS ISLANDS",
  "TUVALU",
  "UGANDA",
  "UKRAINE",
  "UNITED ARAB EMIRATES",
  "UNITED KINGDOM",
  "UNITED STATES",
  "UNITED STATES MINOR OUTLYING ISLANDS",
  "URUGUAY",
  "UZBEKISTAN",
  "VANUATU",
  "VENEZUELA",
  "VIET NAM",
  "VIRGIN ISLANDS, BRITISH",
  "VIRGIN ISLANDS, U.S.",
  "WALLIS AND FUTUNA",
  "WESTERN SAHARA",
  "YEMEN",
  "ZAMBIA",
  "ZIMBABWE",
];

const contactPattern = /(9715)\d{8}/;
const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

let aldarCompanies = [
  {
    key: "Anantara",
    value: "6380a551787cf469194e6376",
  },
  {
    key: "Aldar H&H",
    value: "62276d54c7e8e2bf756d6802",
  },
  {
    key: "Centro",
    value: "6380a559787cf469194e6377",
  },
  {
    key: "Crown Plaza",
    value: "6380a56c787cf469194e6378",
  },
  {
    key: "Park Inn",
    value: "6380a574787cf469194e6379",
  },
  {
    key: "Radisson",
    value: "6380a588787cf469194e637a",
  },
  {
    key: "Rotana",
    value: "6380a591787cf469194e637b",
  },
  {
    key: "Saadiyat Beach Club",
    value: "6380a5ba787cf469194e637c",
  },
  {
    key: "Staybridge Suites",
    value: "6380a5c5787cf469194e637d",
  },
  {
    key: "W Hotel",
    value: "6380a5d4787cf469194e637e",
  },
  {
    key: "YAS LINKS ABU DHABI",
    value: "62275c2644a506bf7557726f",
  },
];

let eoCompanies = [
  {
    key: "Engineering Office",
    value: "645b5c1247f0cff157759208",
  },
];

export default function index() {
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });
  const boxBg = useColorModeValue("white", "whiteAlpha.100");
  const textColor = useColorModeValue("#1b254b", "white");
  const [audio] = useState(new Audio("/sounds/mail_sent.mp3"));
  const [playing, setPlaying] = useState(false);
  const toggle = () => setPlaying(!playing);
  const { user } = useAuth0();

  const userRole = user?.["https://admin.b2connect.me/roles"][0];
  const siteId = user?.["https://admin.b2connect.me/app_metadata"].siteId;

  let isAldar = false;
  if (siteId === 36) {
    isAldar = true;
  }

  let steps = [
    {
      id: 1,
      label: "User details",
      description: "Enter user details",
    },
    {
      id: 2,
      label: "Employment details",
      description: "Enter the employment filters",
    },
    // {
    //     id: 2,
    //     label: "Company details",
    //     description: "Enter the employment filters",
    // },
    // { id: 3, label: "Preview", description: "Preview and send" },
  ];
  if (userRole !== "Admin") {
    steps.splice(2, 1);
  }

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [nationality, setNationality] = useState("INDIA");
  const [gender, setGender] = useState("M");

  const [fnameError, setFnameError] = useState(false);
  const [lnameError, setLnameError] = useState(false);
  const [nationalityError, setNationalityError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [empIdError, setEmpIdError] = useState(false);

  const [message, setMessage] = useState("");

  const { locations, isLoading, isError: LocationError } = useLocations(true);
  const { job_roles, isError: JobRolesError } = useJobRoles(true);
  const { department, isError: departmentError } = useDepartment(true);

  const [error, setError] = useState(false);

  const [foundUsersCount, setFoundUsersCount] = useState(0);

  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState("");

  const [type, setType] = useState("INFO");
  const [resLoading, setResLoading] = useState(false);
  const [processDone, setProcessDone] = useState(false);
  const [roomData, setRoomData] = useState("");
  const [emailData, setEmailData] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [apiLoading, setApiLoading] = useState(false);

  const [roomDataErr, setRoomDataErr] = useState(false);
  const [emailDataErr, setEmailDataErr] = useState(false);
  const [selectedDepartmentErr, setSelectedDepartmentErr] = useState(false);
  const [selectedRoleErr, setSelectedRoleErr] = useState(false);
  const [selectedCompanyErr, setSelectedCompanyErr] = useState(false);
  const [selectedPhoneNumberErr, setSelectedPhoneNumberErr] = useState(false);

  const nationalityRef = useRef("");
  const departmentRef = useRef("");
  const jobRoleRef = useRef("");

  const locationRef = useRef("");
  const activityRef = useRef("");
  const activityDaysRef = useRef(0);
  const isError = false;
  const toast = useToast();

  const resetNotification = () => {
    setSubject("");
    setMessage("");
    setTitle("");
    setFoundUsersCount(0);
    setFname("");
    setFnameError(false);
    setLname("");
    setLnameError(false);
    setEmployeeId("");
    setEmpIdError(false);
    setEmailData("");
    setEmailDataErr(false);
    setGender("M");
    setGenderError(false);
    setRoomData("");
    setRoomDataErr(false);
    setSelectedDepartment("");
    setSelectedDepartmentErr(false);
    setSelectedRole("");
    setSelectedRoleErr(false);
    setSelectedCompany("");
    setSelectedCompanyErr(false);
    setNationality("INDIA");
    setNationalityError(false);
    setSelectedPhoneNumber("");
    setSelectedPhoneNumberErr(false);
    resetFunction();
    reset();
  };

  const resetFunction = () => {
    setSubject("");
    setTitle("");
    setMessage("");
    setType("");
    nationalityRef.current = "";
    departmentRef.current = "";
    jobRoleRef.current = "";
    locationRef.current = "";
    activityRef.current = "";
    activityDaysRef.current = 0;
    setFoundUsersCount(0);
  };
  const genRand = (len) => {
    return Math.random()
      .toString(36)
      .substring(2, len + 2);
  };
  const handleProcess = async () => {
    let flag1 = false;
    let flag2 = false;
    if (activeStep === 0) {
      if (fname.length < 3) {
        setFnameError(true);
        flag1 = true;
      } else {
        setFnameError(false);
      }
      if (lname.length < 3) {
        setLnameError(true);
        flag1 = true;
      } else {
        setLnameError(false);
      }
      if (nationality.length === 0) {
        setNationalityError(true);
        flag1 = true;
      } else {
        setNationalityError(false);
      }
      if (gender.length === 0) {
        setGenderError(true);
        flag1 = true;
      } else {
        setGenderError(false);
      }
      if (employeeId.length === 0) {
        setEmpIdError(true);
        flag1 = true;
      } else {
        setEmpIdError(false);
      }
      if (!flag1) {
        nextStep();
      }
    }
    if (activeStep === 1) {
      // nextStep();
      if (emailData.length === 0 || !emailPattern.test(emailData)) {
        setEmailDataErr(true);
        flag2 = true;
      } else {
        setEmailDataErr(false);
      }
      if (roomData.length === 0) {
        setRoomDataErr(true);
        flag2 = true;
      } else {
        setRoomDataErr(false);
      }
      if (selectedRole.length === 0) {
        setSelectedRoleErr(true);
        flag2 = true;
      } else {
        setSelectedRoleErr(false);
      }
      if (selectedDepartment.length === 0) {
        setSelectedDepartmentErr(true);
        flag2 = true;
      } else {
        setSelectedDepartmentErr(false);
      }
      if (selectedCompany.length === 0) {
        setSelectedCompanyErr(true);
        flag2 = true;
      } else {
        setSelectedCompanyErr(false);
      }
      if (
        selectedPhoneNumber.length === 0 ||
        !contactPattern.test(selectedPhoneNumber)
      ) {
        setSelectedPhoneNumberErr(true);
        flag2 = true;
      } else {
        setSelectedPhoneNumberErr(false);
      }
      if (!flag2) {
        // nextStep();
        let tableData = {
          login: selectedPhoneNumber,
          password: genRand(8),
          firstName: fname,
          lastName: lname,
          nationality: nationality,
          gender: gender,
          email: emailData,
          siteId: "6239f2c6adf8ee9b4158d582",
          room: roomData,
          companyId: selectedCompany,
          employeeId: employeeId,
          position: selectedRole,
          department: selectedRole,
          profileId: "6243f52ff3971e5ca044eafb",
        };

        setError(false);
        setApiLoading(true);
        try {
          const user = await axios.post(
            "https://api.b2connect.me/management/users/",
            tableData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("auth0_token")}`,
              },
            }
          );

          if (user?.data?.body?.errorCode === 0) {
            setApiLoading(false);
            audio.src = "/sounds/mail_sent.mp3";
            await audio.play();
            toast({
              title: `User created successfully`,
              description: `User has been created.`,
              status: "success",
              duration: 2000,
              isClosable: true,
              onCloseComplete: async () => {
                resetNotification();
              },
            });
          } else {
            audio.src = "/sounds/error.mp3";
            await audio.play();
            setApiLoading(false);

            toast({
              title: "Something went wrong.",
              description: "Please contact the support.",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        } catch (e) {
          audio.src = "/sounds/error.mp3";
          await audio.play();
          setApiLoading(false);

          toast({
            title: "Something went wrong.",
            description: "Please contact the support.",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      }
    }

    if (activeStep === 2) {
      setResLoading(true);
    }
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Flex
        flexDir="column"
        width="100%"
        bg={boxBg}
        p={5}
        borderRadius={20}
        shadow={"sm"}
      >
        <Steps colorScheme="pink" activeStep={activeStep}>
          {steps.map(({ label, description, id }, index) => (
            <Step
              label={label}
              key={label}
              description={description}
              textColor={"#fff"}
            >
              {id == 1 ? (
                <Flex px={4} pt={8} width="100%">
                  <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} spacing={5}>
                    <Flex flexDir={"column"}>
                      <Heading fontSize="md">{label}</Heading>

                      <Flex flexDir={"row"}>
                        <FormControl
                          isRequired
                          isInvalid={fnameError}
                          width={"350px"}
                          mr={10}
                        >
                          <FormLabel pt={3} htmlFor="subject">
                            First Name
                          </FormLabel>
                          <Input
                            type="text"
                            id="subject"
                            color={textColor}
                            value={fname}
                            isInvalid={fnameError}
                            onChange={(e) => setFname(e.target.value)}
                          />
                          {!fnameError ? (
                            <FormHelperText>Enter a first name.</FormHelperText>
                          ) : (
                            <FormErrorMessage>
                              First name is required. Minimum 3 characters.
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          isRequired
                          isInvalid={lnameError}
                          width={"350px"}
                        >
                          <FormLabel pt={3} htmlFor="subject">
                            Last Name
                          </FormLabel>
                          <Input
                            type="text"
                            id="subject"
                            color={textColor}
                            value={lname}
                            isInvalid={lnameError}
                            onChange={(e) => setLname(e.target.value)}
                          />
                          {!lnameError ? (
                            <FormHelperText>Enter last name.</FormHelperText>
                          ) : (
                            <FormErrorMessage>
                              Last name is required. Minimum 3 characters.
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Flex>
                    </Flex>
                    <Flex flexDir={"column"} pt={3}>
                      <Flex flexDir={"row"}>
                        <FormControl
                          isRequired
                          isInvalid={nationalityError}
                          width={"350px"}
                          mr={10}
                        >
                          <FormLabel pt={3} htmlFor="subject">
                            Nationality
                          </FormLabel>
                          <Select
                            id={"location"}
                            placeholder="Select Nationality"
                            background={boxBg}
                            isInvalid={nationalityError}
                            onChange={(e) => setNationality(e.target.value)}
                          >
                            {!isLoading && !isError && nationalities
                              ? nationalities?.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item}
                                    selected={item == "INDIA"}
                                  >
                                    {item}
                                  </option>
                                ))
                              : null}
                          </Select>
                          {nationalityError && (
                            <FormErrorMessage>
                              Nationality is required.
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          isRequired
                          isInvalid={genderError}
                          width={"350px"}
                          mr={10}
                        >
                          <FormLabel pt={3} htmlFor="subject">
                            Gender
                          </FormLabel>
                          <Select
                            id={"location"}
                            placeholder="Select Gender"
                            background={boxBg}
                            isInvalid={genderError}
                            onChange={(e) => setGender(e.target.value)}
                          >
                            {!isLoading && !isError
                              ? [
                                  { value: "M", show: "MALE" },
                                  { value: "F", show: "FEMALE" },
                                ]?.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item.value}
                                    selected={item.value == "M"}
                                  >
                                    {item.show}
                                  </option>
                                ))
                              : null}
                          </Select>
                          {genderError && (
                            <FormErrorMessage>
                              Gender is required.
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Flex>
                    </Flex>
                    <Flex flexDir={"column"} pt={3}>
                      <Flex flexDir={"row"}>
                        <FormControl
                          isRequired
                          isInvalid={empIdError}
                          width={"350px"}
                          mr={10}
                        >
                          <FormLabel pt={3} htmlFor="subject">
                            Employee Id
                          </FormLabel>
                          <Input
                            type="text"
                            color={textColor}
                            value={employeeId}
                            isInvalid={empIdError}
                            onChange={(e) => setEmployeeId(e.target.value)}
                          />
                          {!empIdError ? (
                            <FormHelperText>
                              Enter Employee ID number.
                            </FormHelperText>
                          ) : (
                            <FormErrorMessage>
                              Employee ID is required.
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Flex>
                    </Flex>
                  </SimpleGrid>
                </Flex>
              ) : null}

              {id == 2 ? (
                <Flex px={4} pt={8} width="100%">
                  <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} spacing={5}>
                    <Flex flexDir={"column"}>
                      <Heading fontSize="md">{label}</Heading>

                      <Flex flexDir={"row"}>
                        <FormControl
                          isRequired
                          isInvalid={emailDataErr}
                          width={"350px"}
                          mr={10}
                        >
                          <FormLabel pt={3} htmlFor="subject">
                            Email
                          </FormLabel>
                          <Input
                            type="text"
                            id="subject"
                            color={textColor}
                            value={emailData}
                            isInvalid={emailDataErr}
                            onChange={(e) => setEmailData(e.target.value)}
                          />
                          {!emailDataErr ? (
                            <FormHelperText>
                              Enter valid email address.
                            </FormHelperText>
                          ) : (
                            <FormErrorMessage>
                              Email is required. Enter valid email address.
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          isRequired
                          isInvalid={roomDataErr}
                          width={"350px"}
                        >
                          <FormLabel pt={3} htmlFor="subject">
                            Room
                          </FormLabel>
                          <Input
                            type="text"
                            id="subject"
                            color={textColor}
                            value={roomData}
                            isInvalid={roomDataErr}
                            onChange={(e) => setRoomData(e.target.value)}
                          />
                          {!roomDataErr ? (
                            <FormHelperText>Enter room number.</FormHelperText>
                          ) : (
                            <FormErrorMessage>
                              Room is required.
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Flex>
                    </Flex>
                    <Flex flexDir={"column"} pt={3}>
                      <Flex flexDir={"row"}>
                        <FormControl
                          isRequired
                          isInvalid={selectedDepartmentErr}
                          width={"350px"}
                          mr={10}
                        >
                          <FormLabel pt={3} htmlFor="subject">
                            Department
                          </FormLabel>
                          <Select
                            id={"location"}
                            placeholder="Select Department"
                            background={boxBg}
                            isInvalid={selectedDepartmentErr}
                            onChange={(e) =>
                              setSelectedDepartment(e.target.value)
                            }
                          >
                            {!isLoading && !isError && department
                              ? department?.body?.map((item) => (
                                  <option value={item}>{item}</option>
                                ))
                              : null}
                          </Select>
                        </FormControl>

                        <FormControl
                          isRequired
                          isInvalid={selectedRoleErr}
                          width={"350px"}
                          mr={10}
                        >
                          <FormLabel pt={3} htmlFor="subject">
                            Job Role
                          </FormLabel>
                          <Select
                            id={"location"}
                            placeholder="Select Job Role"
                            background={boxBg}
                            isInvalid={selectedRoleErr}
                            onChange={(e) => setSelectedRole(e.target.value)}
                          >
                            {!isLoading && !isError && job_roles
                              ? job_roles?.body?.map((item) => (
                                  <option value={item}>{item}</option>
                                ))
                              : null}
                          </Select>
                        </FormControl>
                      </Flex>
                    </Flex>
                    <Flex flexDir={"column"} pt={3}>
                      <Flex flexDir={"row"}>
                        <FormControl
                          isRequired
                          isInvalid={selectedCompanyErr}
                          width={"350px"}
                          mr={10}
                        >
                          <FormLabel pt={3} htmlFor="subject">
                            Company
                          </FormLabel>
                          <Select
                            id={"location"}
                            placeholder="Select Company"
                            background={boxBg}
                            isInvalid={selectedCompanyErr}
                            onChange={(e) => setSelectedCompany(e.target.value)}
                          >
                            {isAldar
                              ? aldarCompanies?.map((item) => (
                                  <option key={item.key} value={item.value}>
                                    {item.key}
                                  </option>
                                ))
                              : eoCompanies?.map((item) => (
                                  <option key={item.key} value={item.value}>
                                    {item.key}
                                  </option>
                                ))}
                          </Select>
                        </FormControl>

                        <FormControl
                          isRequired
                          isInvalid={selectedPhoneNumberErr}
                          width={"350px"}
                          mr={10}
                        >
                          <FormLabel pt={3} htmlFor="subject">
                            Phone Number
                          </FormLabel>
                          <Input
                            type="text"
                            id="subject"
                            color={textColor}
                            placeholder={"EX: 97158987654"}
                            value={selectedPhoneNumber}
                            isInvalid={selectedPhoneNumberErr}
                            onChange={(e) =>
                              setSelectedPhoneNumber(e.target.value)
                            }
                          />
                          {!selectedPhoneNumberErr ? (
                            <FormHelperText>
                              Enter valid UAE phone number.
                            </FormHelperText>
                          ) : (
                            <FormErrorMessage>
                              Invalid phone number. Enter valid UAE phone
                              number.
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Flex>
                    </Flex>
                  </SimpleGrid>
                </Flex>
              ) : null}

              {id == 3 ? (
                <Flex px={4} pt={8} width="100%" flexDirection="column">
                  <Heading fontSize="md">{label}</Heading>
                  <TableContainer>
                    <Table variant="simple">
                      <TableCaption></TableCaption>
                      <Thead>
                        <Tr>
                          <Th>Subject</Th>
                          <Th>Message</Th>
                          <Th>Found Users</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>{subject}</Td>
                          <Td>{message}</Td>
                          <Td>{foundUsersCount} Users</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
              ) : null}
            </Step>
          ))}
        </Steps>
        {activeStep === steps.length ? (
          <Flex px={4} py={4} width="100%" flexDirection="column">
            <Heading fontSize="xl" textAlign="center">
              End of the steps
            </Heading>
            <Button mx="auto" mt={6} size="sm" onClick={reset}>
              Reset
            </Button>
          </Flex>
        ) : (
          <Flex width="100%" flexDir={"row"} justify="space-between" pt={5}>
            <Flex>
              <Button
                size="sm"
                onClick={resetNotification}
                isDisabled={resLoading}
              >
                Reset
              </Button>
            </Flex>
            <Flex flexDir={"row"}>
              <Button
                isDisabled={activeStep === 0 || resLoading}
                mr={4}
                onClick={prevStep}
                size="sm"
                variant="ghost"
              >
                Prev
              </Button>

              <Button
                size="sm"
                onClick={handleProcess}
                isLoading={apiLoading}
                loadingText={"Sending"}
                isDisabled={processDone || resLoading}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}
