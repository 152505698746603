import React, { useRef, useMemo, useState } from "react";
import { MdReplay, MdOutlineEditNote, MdDownload } from "react-icons/md";

import { FaCartArrowDown } from "react-icons/fa";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogContent,
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  SimpleGrid,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useStyleConfig,
  useToast,
} from "@chakra-ui/react";
import IconBox from "../../../components/icons/IconBox";
import { Player } from "@lottiefiles/react-lottie-player";
import RedirectCard from "../../../components/card/RedirectCard";
import {
  useGetUsers,
  useGetDevices,
  useGetUsersCount,
  useGetAssetZonesBuildings,
} from "../../../lib/hooks";
import moment from "moment";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

const assetsCards = [
  {
    id: 1,
    name: "Assets List",
    icon: "https://assets4.lottiefiles.com/private_files/lf30_f0fhps6k.json",
    description: "List of registered assets",
    button: "Select",
    path: "/dashboard/assets-list",
  },
  {
    id: 2,
    name: "Analytics",
    icon: "https://assets1.lottiefiles.com/private_files/lf30_ghysqmiq.json",
    description: "Show analytics for assets",
    button: "Select",
    path: "/dashboard/assets-analytics",
  },
  {
    id: 3,
    name: "Box Assets",
    icon: "https://assets6.lottiefiles.com/packages/lf20_d29eunxw.json",
    description: "Package an asset in a box",
    button: "Select",
    path: "/dashboard/assets-box",
  },
];

function AssetsMain() {
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const inventoryStatusTypes = [
    {
      key: "INSTALLED",
      value: "INSTALLED",
    },
    {
      key: "DECOMMISSIONED",
      value: "CHECKOUT",
    },
    {
      key: "MISSING",
      value: "MISSING",
    },
  ];

  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [apiFilterParam, setApiFilterParam] = useState("");

  const [visible, setVisible] = useState(false);

  const [updateBtnEnable, setUpdateBtnEnable] = useState(false);

  const [editFirstName, setEditFirstName] = useState("");
  const [editLastName, setEditLastName] = useState("");
  const [editNationality, setEditNationality] = useState("");
  const [editEmpID, setEditEmpID] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editGender, setEditGender] = useState("");
  const [editRoom, setEditRoom] = useState("");
  const [editPosition, setEditPosition] = useState("");
  const [editDepartment, setEditDepartment] = useState("");
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const { devicesData, isError, isLoading } = useGetDevices(
    page,
    per_page,
    searchText,
    apiFilterParam
  );

  const { zoneBuildingData, isZoneBuildingError, isZoneBuildingLoading } =
    useGetAssetZonesBuildings();

  const [zones, setZones] = useState([]);
  const [zonesBuildings, setZonesBuildings] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [selectedInventoryStatus, setSelectedInventoryStatus] = useState("");
  const [zoneBuildingFilterValue, setZoneBuildingFilterValue] = useState("");

  const [inventoryFilterValue, setInventoryFilterValue] = useState("");

  const [buildingsForSelectedZone, setBuildingsForSelectedZone] = useState([]);

  useEffect(() => {
    let filterValue = `${inventoryFilterValue ? inventoryFilterValue : ""}${
      zoneBuildingFilterValue
        ? inventoryFilterValue
          ? zoneBuildingFilterValue
          : zoneBuildingFilterValue.substring(1, zoneBuildingFilterValue.length)
        : ""
    }`;
    setApiFilterParam(filterValue);
    setPage(1);
  }, [inventoryFilterValue, zoneBuildingFilterValue]);

  const handleInventoryStatusSelection = (c) => {
    console.log("Selected building is now: " + c.target.value);
    setSelectedInventoryStatus(c.target.value);
    let inventoryFilterVal = c.target.value
      ? `INVENTORY_STATUS:${c.target.value}`
      : "";
    setInventoryFilterValue(inventoryFilterVal);
  };

  const handleBuildingSelection = (c) => {
    console.log("Selected building is now: " + c.target.value);
    setSelectedBuilding(c.target.value);
    let buildingFilterVal = c.target.value ? `,BUILDING:${c.target.value}` : "";
    setZoneBuildingFilterValue(`,ZONE:${selectedZone}${buildingFilterVal}`);
  };

  const handleZoneSelection = (c) => {
    console.log("SelectedZone is now: " + c.target.value);
    setSelectedZone(c.target.value);
    setSelectedBuilding(undefined);
    let zoneFilterVal = c.target.value ? `,ZONE:${c.target.value}` : "";
    setZoneBuildingFilterValue(zoneFilterVal);
  };

  useEffect(() => {
    setZonesBuildings(
      !isZoneBuildingError && !isZoneBuildingLoading && zoneBuildingData
        ? zoneBuildingData?.body
        : []
    );
  }, [zoneBuildingData]);

  useEffect(() => {
    setZones(Object.keys(zonesBuildings));
  }, [zonesBuildings]);

  useEffect(() => {
    console.log(selectedZone);
    setBuildingsForSelectedZone(zonesBuildings[selectedZone]);
  }, [selectedZone]);

  const { user } = useAuth0();
  const userRole = user?.["https://admin.b2connect.me/roles"][0];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCell, setSelectedCell] = useState({});
  const {
    isOpen: editOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const textColor = useColorModeValue("secondaryGrey.700", "white");
  const [resetErr, setResetErr] = useState(false);

  const {
    isOpen: isOpenReset,
    onOpen: onOpenReset,
    onClose: onCloseReset,
  } = useDisclosure();

  const deviceData =
    !isError && !isLoading && devicesData
      ? devicesData?.body?.items?.map((item) => ({
          id: item.id,
          mac: item.mac,
          serial: item.serial,
          deviceName: item.deviceName,
          description: item.description,
          model: item.model,
          zone: item.zone,
          building: item.building,
          floor: item.floor ? item.floor : "",
          inventory_status: item.inventory_status,
          createdTime: moment(item.createdTime * 1000)
            .utcOffset("+0400")
            .format("LLL"),
          updatedTime: item.updatedTime
            ? moment(item.updatedTime * 1000)
                .utcOffset("+0400")
                .format("LLL")
            : null,
        }))
      : [];

  const usersTotalCount =
    !isError && !isLoading && devicesData ? devicesData?.body?.total : 0;
  const data = React.useMemo(() => deviceData, [devicesData]);

  const columns = useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "MAC",
            accessor: "mac",
          },
          {
            Header: "Serial",
            accessor: "serial",
          },
        ],
      },
      {
        Header: " ",
        columns: [
          {
            Header: "Zone",
            accessor: "zone",
          },
          {
            Header: "Building",
            accessor: "building",
          },
          {
            Header: "Description",
            accessor: "description",
          },
          {
            Header: "Inventory Status",
            accessor: "inventory_status",
          },
          {
            Header: "Device Name",
            accessor: "deviceName",
          },
        ],
      },
    ],
    []
  );

  const handleVisible = () => {
    setVisible(!visible);
  };

  const handleScanSuccess = (value) => {
    console.log(value);
    setSearchText(value);
    setPage(1);
    setVisible(false);
  };

  const handleDrawer = (data) => {
    setSelectedCell(data);
    onOpen();
  };

  const handleSearchText = (data) => {
    setSearchText(data);
    setPage(1);
  };

  let authToken = localStorage.getItem("auth0_token");
  const toast = useToast();

  const handleEditOpen = (e) => {
    setEditFirstName(e?.firstName);
    setEditLastName(e?.lastName);
    setEditNationality(e?.nationality);
    setEditEmail(e?.email);
    setEditEmpID(e?.empId);
    setEditGender(e?.gender);
    setEditRoom(e?.room);
    setEditPosition(e?.position);
    setEditDepartment(e?.department);
    setUpdateBtnEnable(false);
    onEditOpen();
  };

  const handleEditSubmit = async (e) => {
    setUpdateBtnEnable(true);
    let editSubmitBody = {
      login: e.contactNumber,
      firstName: editFirstName ? editFirstName : "",
      lastName: editLastName ? editLastName : null,
      nationality: editNationality ? editNationality : "",
      gender: editGender ? editGender : "",
      email: editEmail ? editEmail : "",
      room: editRoom ? editRoom : "",
      employeeId: editEmpID ? editEmpID : "",
      position: editPosition ? editPosition : "",
      department: editDepartment ? editDepartment : "",
    };
    Object.keys(editSubmitBody).forEach((key) => {
      if (editSubmitBody[key] === null || editSubmitBody[key] === "") {
        delete editSubmitBody[key];
      }
    });
    console.log(editSubmitBody);
    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/users/${e.contactNumber}`,
        editSubmitBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        setUpdateBtnEnable(false);
        onCloseReset();
        toast({
          title: "Updated successfully.",
          description: "You've just updated the details of the user.",
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onEditClose();
            onClose();
          },
        });
      })
      .catch((e) => {
        setResetErr(true);
        onCloseReset();
        toast({
          title: "Something went wrong .",
          description: "Please try again or contact the admin.",
          status: "error",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onEditClose();
            onClose();
          },
        });
      });
  };

  const checkoutDevice = async (e) => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/devices/${e.id}/checkout`,
        null,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        onCloseReset();
        toast({
          title: "Decommission successful.",
          description: "Device decommissioned successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onCloseReset();
            onClose();
          },
        });
      })
      .catch((e) => {
        setResetErr(true);
        onCloseReset();
        toast({
          title: "Something went wrong .",
          description: "Please try again or contact the admin.",
          status: "error",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onCloseReset();
            onClose();
          },
        });
      });
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        {assetsCards.map((card, i) => (
          <>
            {card?.id == 2 ? (
              <RedirectCard
                key={i}
                startContent={
                  <IconBox
                    w="60px"
                    h="60px"
                    bg={boxBg}
                    icon={
                      <Player
                        autoplay
                        loop
                        src={card.icon}
                        style={{ height: "120px", width: "120px" }}
                      ></Player>
                    }
                  />
                }
                name={card.name}
                description={card.description}
                value={card.button}
                path={card.path}
                targetBlank={false}
              />
            ) : (
              <RedirectCard
                startContent={
                  <IconBox
                    w="60px"
                    h="60px"
                    bg={boxBg}
                    icon={
                      <Player
                        autoplay
                        loop
                        src={card.icon}
                        style={{ height: "70px", width: "70px" }}
                      ></Player>
                    }
                  />
                }
                name={card.name}
                description={card.description}
                value={card.button}
                targetBlank={false}
                path={card.path}
              />
            )}
          </>
        ))}
      </SimpleGrid>
    </Box>
  );
}

export default AssetsMain;
