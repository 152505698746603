// chakra imports
import {Box, Flex, Stack, useColorMode,} from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import SidebarCard from "components/sidebar/components/SidebarCard";
import React from "react";
import {useAuth0} from "@auth0/auth0-react";

// FUNCTIONS
function SidebarContent(props) {
  const { routes } = props;
  const { colorMode } = useColorMode();
  const { user } = useAuth0();

  const userRole = user?.["https://admin.b2connect.me/roles"][0];


   // SIDEBAR
  return (
    <Flex direction="column" height="100%" pt="25px" borderRadius="30px" overflow={"auto"}>
      <Brand />
      <Stack direction="column" mb="auto" mt="8px">
        <Box ps="20px" pe={{ md: "16px", "2xl": "1px" }}>
          <Links key={Math.floor(Math.random() * 10)} routes={routes.filter((item) => item.sidebar === true)} />
        </Box>
      </Stack>

      <Box
        ps="20px"
        pe={{ md: "16px", "2xl": "0px" }}
        mt="60px"
        mb={"35px"}
        borderRadius="30px"
      >
        <SidebarCard />
      </Box>

    </Flex>
  );
}

export default SidebarContent;
