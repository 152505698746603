import { Button, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { Link } from "react-router-dom";
export default function SidebarDocs() {
  const bgColor = useColorModeValue("#f6ad55", "white");
  const txColor = useColorModeValue("white", "black");

  const borderColor = useColorModeValue("#f6ad55", "navy.800");

  return (
    <Flex
      justify="center"
      direction="column"
      align="center"
      bg={bgColor}
      borderRadius="30px"
      me="20px"
      position="relative"
    >
      <Flex
        direction="column"
        mb="12px"
        align="center"
        justify="center"
        px="15px"
        pt="20px"
      >
        <Text
          fontSize={{ base: "lg", xl: "18px" }}
          color={txColor}
          fontWeight="bold"
          lineHeight="150%"
          textAlign="center"
          px="10px"
          mb="14px"
        >
          Need help?
        </Text>
        <Flex>
          <Player
            autoplay
            loop
            src="https://assets10.lottiefiles.com/packages/lf20_ssaoeg55.json"
            style={{ height: "100px", width: "150px" }}
          ></Player>
        </Flex>
      </Flex>
      <Link to="/dashboard/support">
        <Button
          bg="whiteAlpha.300"
          _hover={{ bg: "whiteAlpha.200" }}
          _active={{ bg: "whiteAlpha.100" }}
          mb={{ sm: "16px", xl: "24px" }}
          color={txColor}
          fontWeight="regular"
          fontSize="sm"
          minW="185px"
          mx="auto"
        >
          Contact Support
        </Button>
      </Link>
    </Flex>
  );
}
