import { Step, Steps, useSteps } from "chakra-ui-steps";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useDepartment, useJobRoles, useLocations } from "../../../lib/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import * as xlsx from "xlsx";
import axios from "axios";
import TableUser from "./table";
import { DownloadExcel } from "react-excel-export/dist/index.es";

let nationalities = [
  "America",
  "Austria",
  "Bangladesh",
  "Bangladeshi",
  "Cameroon",
  "Chaina",
  "Chinese",
  "Colombia",
  "Ecuador",
  "Egypt",
  "Egyptian",
  "Eritrea",
  "Ethiopia",
  "Filipino",
  "France",
  "French",
  "Germany",
  "Ghana",
  "India",
  "Indian",
  "Indonesia",
  "Italian",
  "Italy",
  "Jordan",
  "Jordanian",
  "Kazakhstan",
  "Kenya",
  "Lebanese",
  "Malaysia",
  "Moldova",
  "Morocco",
  "Myanmar",
  "Myanmar (Burma)",
  "Mynmar",
  "Nepal",
  "Nepali",
  "Nigeria",
  "Nigerian",
  "Not Mentioned",
  "Oman",
  "PAKISTAN",
  "Pakistan",
  "Pakistani",
  "Panama",
  "Philippines",
  "Romania",
  "Ruwandan",
  "Rwanda",
  "SIERRA LEONE",
  "Serbia",
  "Seychelles",
  "South Africa",
  "Sri Lanka",
  "Sri Lankan",
  "Sri lanka",
  "Sudan",
  "Swis",
  "Syria Arab Republic",
  "Tanzania",
  "Thailand",
  "Tunisia",
  "Turkey",
  "US",
  "Uganda",
  "Ukrain",
  "Ukraine",
  "Ukrainian",
  "Uzbekistan",
  "Vietnam",
  "Zambia",
  "Zimbabwe",
  "Zimbabwean",
  "bangladesh",
  "egypt",
  "india",
  "nepal",
  "pakistan",
  "pakistani",
  "philippines",
  "sri lanka",
];

export default function batch() {
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });
  const boxBg = useColorModeValue("white", "whiteAlpha.100");
  const textColor = useColorModeValue("#1b254b", "white");
  const [playing, setPlaying] = useState(false);
  const { user } = useAuth0();
  const [xlData, setXlData] = useState([]);
  const [message, setMessage] = useState("");
  const { locations, isLoading, isError: LocationError } = useLocations(true);
  const { job_roles, isError: JobRolesError } = useJobRoles(true);
  const { department, isError: departmentError } = useDepartment(true);
  const [foundUsersCount, setFoundUsersCount] = useState(0);
  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState("");
  const [type, setType] = useState("INFO");
  const [resLoading, setResLoading] = useState(false);
  const [processDone, setProcessDone] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [successDataFinal, setSuccessDataFinal] = useState([]);
  const nationalityRef = useRef("");
  const departmentRef = useRef("");
  const jobRoleRef = useRef("");
  const locationRef = useRef("");
  const activityRef = useRef("");
  const activityDaysRef = useRef(0);

  const isError = false;
  const FiledData = useRef([]);
  const [failedData, setFailedData] = useState([]);
  let exportDataFailed = [];

  let companyList = [
    {
      name: "Anantara",
      id: "6380a551787cf469194e6376",
    },
    {
      name: "Aldar H&H",
      id: "62276d54c7e8e2bf756d6802",
    },
    {
      name: "Centro",
      id: "6380a559787cf469194e6377",
    },
    {
      name: "Crowne Plaza",
      id: "6380a56c787cf469194e6378",
    },
    {
      name: "Park Inn",
      id: "6380a574787cf469194e6379",
    },
    {
      name: "Radisson",
      id: "6380a588787cf469194e637a",
    },
    {
      name: "Rotana",
      id: "6380a591787cf469194e637b",
    },
    {
      name: "Saadiyat Beach Club",
      id: "6380a5ba787cf469194e637c",
    },
    {
      name: "Staybridge Suites",
      id: "6380a5c5787cf469194e637d",
    },
    {
      name: "W Hotel",
      id: "6380a5d4787cf469194e637e",
    },
    {
      name: "YAS LINKS ABU DHABI",
      id: "62275c2644a506bf7557726f",
    },
  ];

  let steps = [
    {
      id: 1,
      label: "Select a Batch File",
      description: "Please choose a valid batch file",
    },

    {
      id: 2,
      label: "Preview",
      description: "Preview details",
    },
    {
      id: 3,
      label: "Finish",
      description: "Submit details",
    },
  ];

  const resetNotification = () => {
    setSubject("");
    setMessage("");
    setTitle("");
    setFoundUsersCount(0);
    resetFunction();
    reset();
  };

  const resetFunction = () => {
    setSubject("");
    setTitle("");
    setMessage("");
    setType("");
    nationalityRef.current = "";
    departmentRef.current = "";
    jobRoleRef.current = "";
    locationRef.current = "";
    activityRef.current = "";
    activityDaysRef.current = 0;
    setFoundUsersCount(0);
  };

  const handleProcess = async () => {
    let filedDataV = [];
    let successData = [];
    let filedDataVV = [];
    let successDataVV = [];

    if (activeStep === 1) {
      if (tableData?.length > 0) {
        const user = await axios.post(
          "https://api.b2connect.me/management/users/batch",
          { items: tableData },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth0_token")}`,
            },
          }
        );

        for (let i = 0; i < user?.data?.body?.length; i++) {
          if (user?.data?.body[i]?.errorCode > 0) {
            filedDataV.push(tableData[user?.data?.body[i]?.id]);
          } else {
            successData.push(tableData[user?.data?.body[i]?.id]);
          }
        }

        for (let i = 0; i < filedDataV?.length; i++) {
          filedDataVV.push({
            "* Department": filedDataV[i]?.department,
            "* Employee ID": filedDataV[i]?.employeeId,
            "* Gender": filedDataV[i]?.gender,
            "* Job Role": filedDataV[i]?.position,
            Email: filedDataV[i]?.email,
            "First Name": filedDataV[i]?.firstName,
            "Last Name": filedDataV[i]?.lastName,
            Nationality: filedDataV[i]?.nationality,
            "Room#": filedDataV[i]?.room,
            PhoneNumber: filedDataV[i]?.login,
          });
        }
        for (let i = 0; i < successData?.length; i++) {
          successDataVV.push({
            "* Department": successData[i]?.department,
            "* Employee ID": successData[i]?.employeeId,
            "* Gender": successData[i]?.gender,
            "* Job Role": successData[i]?.position,
            Email: successData[i]?.email,
            "First Name": successData[i]?.firstName,
            "Last Name": successData[i]?.lastName,
            Nationality: successData[i]?.nationality,
            "Room#": successData[i]?.room,
            PhoneNumber: filedDataV[i]?.login,
          });
        }
        setFailedData(filedDataVV);
        setSuccessDataFinal(successDataVV);

        console.log(successDataFinal);
        nextStep();
      } else {
      }
    }
  };

  const convertData = (data) => {
    if (data?.length > 0) {
      setTableData(data);
      nextStep();
    }
  };

  const convertDataXlx = (data) => {
    console.log(data);
    if (data?.length > 0) {
      setXlData(data);
    }
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    let finalData = [];

    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        convertDataXlx(json);
        for (let i = 0; i < json.length; i++) {
          finalData.push({
            login: json[i]?.["PhoneNumber"],
            password: Math.floor(Math.random() * 90000) + 10000,
            firstName: json[i]?.["First Name"],
            lastName: json[i]?.["Last Name"],
            nationality: json[i]?.["Nationality"],
            gender: json[i]?.["* Gender"] === "Male" ? "M" : "F",
            email: json[i]?.["Email"],
            siteId: "6239f2c6adf8ee9b4158d582", //Auth0 value
            room: json[i]?.["Room#"],
            companyId:
              companyList.find((x) => x.name == json[i]?.["Company"])?.id ||
              "62276d54c7e8e2bf756d6802",
            position: json[i]?.["* Job Role"],
            department: json[i]?.["* Department"],
            profileId: "6243f52ff3971e5ca044eafb",
            employeeId: json[i]?.["* Employee ID"],
          });
        }

        convertData(finalData);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  console.log(failedData);
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Flex
        flexDir="column"
        width="100%"
        bg={boxBg}
        p={5}
        borderRadius={20}
        shadow={"sm"}
      >
        <Steps colorScheme="pink" activeStep={activeStep}>
          {steps.map(({ label, description, id }, index) => (
            <Step
              label={label}
              key={label}
              description={description}
              textColor={"#fff"}
            >
              {id === 1 ? (
                <Flex px={4} pt={8} width="100%">
                  <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} spacing={5}>
                    <Flex flexDir={"column"}>
                      <Heading fontSize="md">{label}</Heading>

                      <Flex flexDir={"row"}>
                        <FormControl
                          isInvalid={isError}
                          width={"350px"}
                          mr={10}
                        >
                          <br />
                          <input
                            type="file"
                            id="subject"
                            color={textColor}
                            onChange={readUploadFile}
                          />
                          <FormHelperText pt={3} htmlFor="subject">
                            Please upload a batch file. Max 500 rows of data!
                          </FormHelperText>

                          {!isError ? (
                            <FormHelperText>
                              Upload a file as per our shared file template.
                              Supported file formats: .xls,.csv,.xlsx
                            </FormHelperText>
                          ) : (
                            <FormErrorMessage>
                              File is required.
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Flex>
                    </Flex>
                  </SimpleGrid>
                </Flex>
              ) : null}

              {id === 2 ? (
                <Flex px={4} pt={8} width="100%" flexDirection="column">
                  <Heading fontSize="md">{label}</Heading>
                  <Flex mt={5} flexDir={"column"}>
                    <Flex mb={5}>
                      <Text>Total Found Users {tableData?.length}</Text>
                    </Flex>

                    {failedData || successDataFinal ? (
                      <Flex flexDir={"row"}>
                        <Flex>
                          {successDataFinal?.length > 0 && (
                            <Button colorScheme={"green"}>
                              <DownloadExcel
                                data={successDataFinal}
                                buttonLabel="Download Success Report"
                                fileName="success-report"
                              />
                            </Button>
                          )}
                        </Flex>

                        <Flex ml={3}>
                          {failedData?.length > 0 && (
                            <Button colorScheme={"blackAlpha"}>
                              <DownloadExcel
                                data={failedData}
                                buttonLabel="Download Failed Report"
                                fileName="failed-report"
                              />
                            </Button>
                          )}
                        </Flex>
                      </Flex>
                    ) : null}
                    <Flex>
                      <TableUser tableDataA={tableData} />
                    </Flex>
                  </Flex>
                </Flex>
              ) : null}

              {id === 3 ? (
                <Flex px={4} pt={8} width="100%" flexDirection="column">
                  <Heading fontSize="md">{label}</Heading>
                  <Flex mt={5} flexDir={"column"}>
                    <Flex mb={5}>
                      <Text>Total Found Users {tableData?.length}</Text>
                    </Flex>

                    {failedData || successDataFinal ? (
                      <Flex flexDir={"row"}>
                        <Flex>
                          {successDataFinal?.length > 0 && (
                            <Button colorScheme={"green"}>
                              <DownloadExcel
                                data={successDataFinal}
                                buttonLabel="Download Success Report"
                                fileName="success-report"
                              />
                            </Button>
                          )}
                        </Flex>

                        <Flex ml={3}>
                          {failedData?.length > 0 && (
                            <Button colorScheme={"blackAlpha"}>
                              <DownloadExcel
                                data={failedData}
                                buttonLabel="Download Failed Report"
                                fileName="failed-report"
                              />
                            </Button>
                          )}
                        </Flex>
                      </Flex>
                    ) : null}
                    <Flex>
                      <TableUser tableDataA={tableData} />
                    </Flex>
                  </Flex>
                </Flex>
              ) : null}
            </Step>
          ))}
        </Steps>
        {activeStep === steps.length ? (
          <Flex px={4} py={4} width="100%" flexDirection="column">
            <Heading fontSize="xl" textAlign="center">
              End of the steps
            </Heading>
            <Button mx="auto" mt={6} size="sm" onClick={reset}>
              Reset
            </Button>
          </Flex>
        ) : (
          <Flex width="100%" flexDir={"row"} justify="space-between" pt={5}>
            <Flex>
              <Button
                size="sm"
                onClick={resetNotification}
                isDisabled={resLoading}
              >
                Reset
              </Button>
            </Flex>
            <Flex flexDir={"row"}>
              <Button
                isDisabled={activeStep === 0 || resLoading}
                mr={4}
                onClick={prevStep}
                size="sm"
                variant="ghost"
              >
                Prev
              </Button>
              <Button
                size="sm"
                onClick={handleProcess}
                isLoading={resLoading}
                loadingText={"Sending"}
                isDisabled={processDone || resLoading}
              >
                {activeStep === steps.length - 1 ? "Done" : "Next"}
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}
