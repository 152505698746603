import React, { useRef, useMemo, useState } from "react";
import { MdReplay, MdOutlineEditNote, MdDownload } from "react-icons/md";

import { FaCartArrowDown } from "react-icons/fa";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogContent,
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  SimpleGrid,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useStyleConfig,
  useToast,
} from "@chakra-ui/react";
import IconBox from "../../../components/icons/IconBox";
import { Player } from "@lottiefiles/react-lottie-player";
import RedirectCard from "../../../components/card/RedirectCard";
import {
  useGetUsers,
  useGetDevices,
  useGetUsersCount,
  useGetAssetZonesBuildings,
  useGetAssetLogs,
  useGetBoxLogs,
} from "../../../lib/hooks";
import { MainTable } from "./components/MainTable";
import moment from "moment";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import nationalities from "./nationality.json";

import Html5QrcodePlugin from "./components/Html5QrcodePlugin";
import Card from "components/card/Card";
import { useEffect } from "react";
import { filter, set } from "lodash";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

let chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Asset Logs",
    },
  },
};

let boxChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Box Logs",
    },
  },
};

const labels = [
  "2023-01-18",
  "2023-01-25",
  "2023-01-26",
  "2023-01-27",
  "2023-01-28",
  "2023-01-29",
  "2023-01-30",
  "2023-01-31",
  "2023-01-32",
];

function AssetsAnalytics() {
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const inventoryStatusTypes = [
    {
      key: "DECOMMISSIONED",
      value: "CHECKOUT",
    },
    {
      key: "BOXED",
      value: "OTHER",
    },
  ];

  const boxStatusTypes = [
    {
      key: "IN STORAGE",
      value: "IN_STORAGE",
    },
    {
      key: "SHIPPED",
      value: "SHIPPED",
    },
  ];

  const boxTypes = [
    {
      key: "NEW AND GOOD",
      value: "NEW_AND_GOOD",
    },
    {
      key: "NEED TO CLEAN",
      value: "NEED_TO_CLEAN",
    },
    {
      key: "TO BE REFURBISHED",
      value: "TO_BE_REFURBISHED",
    },
    {
      key: "UNKNOWN",
      value: "UNKNOWN",
    },
  ];

  const assetTypes = [
    {
      key: "ACCESS POINT",
      value: "ACCESS_POINT",
    },
    {
      key: "SWITCH",
      value: "SWITCH",
    },
    {
      key: "ROUTER",
      value: "ROUTER",
    },
  ];

  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [apiFilterParam1, setApiFilterParam1] = useState("");
  const [apiFilterParam2, setApiFilterParam2] = useState("");
  const [apiFilterParam3, setApiFilterParam3] = useState("");
  const [apiFilterParam4, setApiFilterParam4] = useState("");
  const [apiFilterParam5, setApiFilterParam5] = useState("");

  const [visible, setVisible] = useState(false);

  const [updateBtnEnable, setUpdateBtnEnable] = useState(false);

  const [editFirstName, setEditFirstName] = useState("");
  const [editLastName, setEditLastName] = useState("");
  const [editNationality, setEditNationality] = useState("");
  const [editEmpID, setEditEmpID] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editGender, setEditGender] = useState("");
  const [editRoom, setEditRoom] = useState("");
  const [editPosition, setEditPosition] = useState("");
  const [editDepartment, setEditDepartment] = useState("");
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const { devicesData, isError, isLoading } = useGetDevices(
    page,
    per_page,
    searchText,
    apiFilterParam1
  );

  const {
    devicesData: devicesData2,
    isError: isError2,
    isLoading: isLoading2,
  } = useGetDevices(page, per_page, searchText, apiFilterParam2);

  const {
    devicesData: devicesData3,
    isError: isError3,
    isLoading: isLoading3,
  } = useGetDevices(page, per_page, searchText, apiFilterParam3);
  const {
    devicesData: devicesData4,
    isError: isError4,
    isLoading: isLoading4,
  } = useGetDevices(page, per_page, searchText, apiFilterParam4);

  const {
    devicesData: devicesData5,
    isError: isError5,
    isLoading: isLoading5,
  } = useGetDevices(page, per_page, searchText, apiFilterParam5);

  const { zoneBuildingData, isZoneBuildingError, isZoneBuildingLoading } =
    useGetAssetZonesBuildings();

  const [zones, setZones] = useState([]);
  const [zonesBuildings, setZonesBuildings] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [selectedInventoryStatus, setSelectedInventoryStatus] = useState("");
  const [selectedAssetType, setSelectedAssetType] = useState("");

  const [selectedZoneLog, setSelectedZoneLog] = useState("");
  const [selectedBuildingLog, setSelectedBuildingLog] = useState("");
  const [selectedInventoryStatusLog, setSelectedInventoryStatusLog] =
    useState("CHECKOUT");
  const [selectedBoxStatusLog, setSelectedBoxStatusLog] =
    useState("IN_STORAGE");
  const [selectedAssetTypeLog, setSelectedAssetTypeLog] = useState("");
  const [selectedBoxTypeLog, setSelectedBoxTypeLog] = useState("");

  const [zoneBuildingFilterValue, setZoneBuildingFilterValue] = useState("");

  const [inventoryFilterValue, setInventoryFilterValue] = useState("");
  const [assetTypeFilterValue, setAssetTypeFilterValue] = useState("");

  const [logFilter, setLogFilter] = useState("?status=CHECKOUT");
  const [boxLogFilter, setBoxLogFilter] = useState("?status=IN_STORAGE");

  const [buildingsForSelectedZone, setBuildingsForSelectedZone] = useState([]);
  const [buildingsForSelectedZoneLog, setBuildingsForSelectedZoneLog] =
    useState([]);

  const [totalInstalledAssetsCount, setTotalInstalledAssetsCount] = useState(0);
  const [totalDecommissionedAssetsCount, setTotalDecommissionedAssetsCount] =
    useState(0);
  const [totalMissingAssetsCount, setTotalMissingAssetsCount] = useState(0);
  const [totalBoxedAssetsCount, setTotalBoxedAssetsCount] = useState(0);

  const [totalAssetsCount, setTotalAssetsCount] = useState(0);

  const [logLabels, setLogLabels] = useState([]);
  const [logData, setLogData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [boxLogLabels, setBoxLogLabels] = useState([]);
  const [boxLogData, setBoxLogData] = useState([]);
  const [boxChartData, setBoxChartData] = useState([]);

  const { assetsLogData, isAssetsLogError, isAssetsLogLoading } =
    useGetAssetLogs(logFilter);

  const { boxesLogData, isBoxesLogError, isBoxesLogLoading } =
    useGetBoxLogs(boxLogFilter);

  useEffect(() => {
    if (!isAssetsLogLoading && !isAssetsLogError && assetsLogData) {
      console.log(assetsLogData?.body?.map((a) => a.date));
      setLogLabels(assetsLogData?.body?.map((a) => a.date));
      console.log(assetsLogData?.body?.map((b) => b.count));
      setLogData(assetsLogData?.body?.map((b) => b.count));
    }
  }, [assetsLogData]);

  useEffect(() => {
    setChartData({
      labels: logLabels,
      datasets: [
        {
          label: inventoryStatusTypes.find(
            (a) => a.value == selectedInventoryStatusLog
          ).key,
          data: logData,
          backgroundColor: "rgb(224,69,123)",
        },
      ],
    });
  }, [logData]);

  useEffect(() => {
    if (!isBoxesLogLoading && !isBoxesLogError && boxesLogData) {
      console.log(boxesLogData?.body?.map((a) => a.date));
      setBoxLogLabels(boxesLogData?.body?.map((a) => a.date));
      console.log(boxesLogData?.body?.map((b) => b.count));
      setBoxLogData(boxesLogData?.body?.map((b) => b.count));
    }
  }, [boxesLogData]);

  useEffect(() => {
    setBoxChartData({
      labels: boxLogLabels,
      datasets: [
        {
          label: boxStatusTypes.find((a) => a.value == selectedBoxStatusLog)
            .key,
          data: boxLogData,
          backgroundColor: "rgb(224,69,123)",
        },
      ],
    });
  }, [boxLogData]);

  useEffect(() => {
    let filtersArray1 = [];
    let filtersArray2 = [];
    let filtersArray3 = [];
    let filtersArray4 = [];
    let filtersArray5 = [];

    if (assetTypeFilterValue && assetTypeFilterValue !== "") {
      filtersArray1.push(assetTypeFilterValue);
      filtersArray2.push(assetTypeFilterValue);
      filtersArray3.push(assetTypeFilterValue);
      filtersArray4.push(assetTypeFilterValue);
      filtersArray5.push(assetTypeFilterValue);
    }
    if (zoneBuildingFilterValue && zoneBuildingFilterValue !== "") {
      filtersArray1.push(zoneBuildingFilterValue);
      filtersArray2.push(zoneBuildingFilterValue);
      filtersArray3.push(zoneBuildingFilterValue);
      filtersArray4.push(zoneBuildingFilterValue);
      filtersArray5.push(zoneBuildingFilterValue);
    }
    filtersArray2.push("INVENTORY_STATUS:INSTALLED");
    filtersArray3.push("INVENTORY_STATUS:CHECKOUT");
    filtersArray4.push("INVENTORY_STATUS:MISSING");
    filtersArray5.push("INVENTORY_STATUS:OTHER");

    setApiFilterParam1(filtersArray1.join(","));
    setApiFilterParam2(filtersArray2.join(","));
    setApiFilterParam3(filtersArray3.join(","));
    setApiFilterParam4(filtersArray4.join(","));
    setApiFilterParam5(filtersArray5.join(","));

    setPage(1);
  }, [zoneBuildingFilterValue, assetTypeFilterValue]);

  const handleInventoryStatusLogSelection = (c) => {
    console.log("Selected inventory status is now: " + c.target.value);
    setSelectedInventoryStatusLog(c.target.value ? c.target.value : "CHECKOUT");
  };

  const handleBoxStatusLogSelection = (c) => {
    console.log("Selected box status is now: " + c.target.value);
    setSelectedBoxStatusLog(c.target.value ? c.target.value : "IN_STORAGE");
  };

  const handleAssetTypeLogSelection = (c) => {
    console.log("Selected asset is now: " + c.target.value);
    setSelectedAssetTypeLog(c.target.value);
  };

  const handleBoxTypeLogSelection = (c) => {
    console.log("Selected box type is now: " + c.target.value);
    setSelectedBoxTypeLog(c.target.value);
  };

  const handleBuildingLogSelection = (c) => {
    console.log("Selected building is now: " + c.target.value);
    setSelectedBuildingLog(c.target.value);
  };

  const handleZoneLogSelection = (c) => {
    console.log("Selected Zone is now: " + c.target.value);
    setSelectedZoneLog(c.target.value);
    setSelectedBuildingLog(undefined);
  };

  const handleAssetTypeSelection = (c) => {
    console.log("Selected asset is now: " + c.target.value);
    console.log("INVENTORY_STATUS" + selectedInventoryStatus);
    setSelectedAssetType(c.target.value);
    let assetTypeFilterVal = c.target.value
      ? `ASSET_TYPE:${c.target.value}`
      : "";
    setAssetTypeFilterValue(assetTypeFilterVal);
  };

  const handleBuildingSelection = (c) => {
    console.log("Selected building is now: " + c.target.value);
    setSelectedBuilding(c.target.value);
    let buildingFilterVal = c.target.value ? `,BUILDING:${c.target.value}` : "";
    setZoneBuildingFilterValue(`ZONE:${selectedZone}${buildingFilterVal}`);
  };

  const handleZoneSelection = (c) => {
    console.log("SelectedZone is now: " + c.target.value);
    setSelectedZone(c.target.value);
    setSelectedBuilding(undefined);
    let zoneFilterVal = c.target.value ? `ZONE:${c.target.value}` : "";
    setZoneBuildingFilterValue(zoneFilterVal);
  };

  useEffect(() => {
    setZonesBuildings(
      !isZoneBuildingError && !isZoneBuildingLoading && zoneBuildingData
        ? zoneBuildingData?.body
        : []
    );
  }, [zoneBuildingData]);

  useEffect(() => {
    setZones(Object.keys(zonesBuildings));
  }, [zonesBuildings]);

  useEffect(() => {
    console.log(selectedZone);
    setBuildingsForSelectedZone(zonesBuildings[selectedZone]);
  }, [selectedZone]);

  useEffect(() => {
    console.log(selectedZoneLog);
    setBuildingsForSelectedZoneLog(zonesBuildings[selectedZoneLog]);
  }, [selectedZoneLog]);

  useEffect(() => {
    let filter = `?status=${selectedInventoryStatusLog}${
      selectedAssetTypeLog ? `&assetType=${selectedAssetTypeLog}` : ""
    }${selectedZoneLog ? `&zone=${selectedZoneLog}` : ""}${
      selectedBuildingLog ? `&building=${selectedBuildingLog}` : ""
    }`;
    console.log(filter);
    setLogFilter(filter);
  }, [
    selectedInventoryStatusLog,
    selectedAssetTypeLog,
    selectedZoneLog,
    selectedBuildingLog,
  ]);

  useEffect(() => {
    let filter = `?status=${selectedBoxStatusLog}${
      selectedBoxTypeLog ? `&type=${selectedBoxTypeLog}` : ""
    }`;
    console.log(filter);
    setBoxLogFilter(filter);
  }, [selectedBoxStatusLog, selectedBoxTypeLog]);

  const { user } = useAuth0();
  const userRole = user?.["https://admin.b2connect.me/roles"][0];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCell, setSelectedCell] = useState({});
  const {
    isOpen: editOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const textColor = useColorModeValue("secondaryGrey.700", "white");
  const [resetErr, setResetErr] = useState(false);

  const {
    isOpen: isOpenReset,
    onOpen: onOpenReset,
    onClose: onCloseReset,
  } = useDisclosure();

  useEffect(() => {
    setTotalAssetsCount(
      !isError && !isLoading && devicesData ? devicesData?.body?.total : 0
    );
  }, [devicesData]);

  useEffect(() => {
    setTotalInstalledAssetsCount(
      !isError2 && !isLoading2 && devicesData2 ? devicesData2?.body?.total : 0
    );
  }, [devicesData2]);

  useEffect(() => {
    setTotalDecommissionedAssetsCount(
      !isError3 && !isLoading3 && devicesData3 ? devicesData3?.body?.total : 0
    );
  }, [devicesData3]);

  useEffect(() => {
    setTotalMissingAssetsCount(
      !isError4 && !isLoading4 && devicesData4 ? devicesData4?.body?.total : 0
    );
  }, [devicesData4]);

  useEffect(() => {
    setTotalBoxedAssetsCount(
      !isError5 && !isLoading5 && devicesData5 ? devicesData5?.body?.total : 0
    );
  }, [devicesData5]);

  const deviceData =
    !isError && !isLoading && devicesData
      ? devicesData?.body?.items?.map((item) => ({
          id: item.id,
          mac: item.mac,
          serial: item.serial,
          deviceName: item.deviceName,
          description: item.description,
          model: item.model,
          zone: item.zone,
          building: item.building,
          floor: item.floor ? item.floor : "",
          inventory_status: item.inventory_status,
          createdTime: moment(item.createdTime * 1000)
            .utcOffset("+0400")
            .format("LLL"),
          updatedTime: item.updatedTime
            ? moment(item.updatedTime * 1000)
                .utcOffset("+0400")
                .format("LLL")
            : null,
        }))
      : [];

  const usersTotalCount =
    !isError && !isLoading && devicesData ? devicesData?.body?.total : 0;
  const data = React.useMemo(() => deviceData, [devicesData]);

  const notificationsType = [
    {
      id: 1,
      name: "Onboard a Single User",
      icon: "https://assets1.lottiefiles.com/datafiles/6deVuMSwjYosId3/data.json",
      description: "On-Board a user",
      button: "Start",
      path: "/dashboard/boarding-single",
    },

    {
      id: 2,
      name: "Onboard Multiple Users (Bulk)",
      icon: "https://assets3.lottiefiles.com/packages/lf20_yxrxjnkt.json",
      description: "On-Board a batch of users",
      button: "Start",
      path: "/dashboard/boarding-batch",
    },
    {
      id: 3,
      name: "Off-Board",
      icon: "https://assets6.lottiefiles.com/private_files/lf30_aprp5fnm.json",
      description: "Off-Board users",
      button: "Coming Soon",
      path: "/dashboard/offboarding",
    },
  ];

  const columns = useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "MAC",
            accessor: "mac",
          },
          {
            Header: "Serial",
            accessor: "serial",
          },
        ],
      },
      {
        Header: " ",
        columns: [
          {
            Header: "Zone",
            accessor: "zone",
          },
          {
            Header: "Building",
            accessor: "building",
          },
          {
            Header: "Description",
            accessor: "description",
          },
          {
            Header: "Inventory Status",
            accessor: "inventory_status",
          },
          {
            Header: "Device Name",
            accessor: "deviceName",
          },
        ],
      },
    ],
    []
  );

  const handleVisible = () => {
    setVisible(!visible);
  };

  const handleScanSuccess = (value) => {
    console.log(value);
    setSearchText(value);
    setPage(1);
    setVisible(false);
  };

  const handleDrawer = (data) => {
    setSelectedCell(data);
    onOpen();
  };

  const handleSearchText = (data) => {
    setSearchText(data);
    setPage(1);
  };

  let authToken = localStorage.getItem("auth0_token");
  const toast = useToast();

  const handleEditOpen = (e) => {
    setEditFirstName(e?.firstName);
    setEditLastName(e?.lastName);
    setEditNationality(e?.nationality);
    setEditEmail(e?.email);
    setEditEmpID(e?.empId);
    setEditGender(e?.gender);
    setEditRoom(e?.room);
    setEditPosition(e?.position);
    setEditDepartment(e?.department);
    setUpdateBtnEnable(false);
    onEditOpen();
  };

  const handleEditSubmit = async (e) => {
    setUpdateBtnEnable(true);
    let editSubmitBody = {
      login: e.contactNumber,
      firstName: editFirstName ? editFirstName : "",
      lastName: editLastName ? editLastName : null,
      nationality: editNationality ? editNationality : "",
      gender: editGender ? editGender : "",
      email: editEmail ? editEmail : "",
      room: editRoom ? editRoom : "",
      employeeId: editEmpID ? editEmpID : "",
      position: editPosition ? editPosition : "",
      department: editDepartment ? editDepartment : "",
    };
    Object.keys(editSubmitBody).forEach((key) => {
      if (editSubmitBody[key] === null || editSubmitBody[key] === "") {
        delete editSubmitBody[key];
      }
    });
    console.log(editSubmitBody);
    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/users/${e.contactNumber}`,
        editSubmitBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        setUpdateBtnEnable(false);
        onCloseReset();
        toast({
          title: "Updated successfully.",
          description: "You've just updated the details of the user.",
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onEditClose();
            onClose();
          },
        });
      })
      .catch((e) => {
        setResetErr(true);
        onCloseReset();
        toast({
          title: "Something went wrong .",
          description: "Please try again or contact the admin.",
          status: "error",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onEditClose();
            onClose();
          },
        });
      });
  };

  const checkoutDevice = async (e) => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/devices/${e.id}/checkout`,
        null,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        onCloseReset();
        toast({
          title: "Decommission successful.",
          description: "Device decommissioned successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onCloseReset();
            onClose();
          },
        });
      })
      .catch((e) => {
        setResetErr(true);
        onCloseReset();
        toast({
          title: "Something went wrong .",
          description: "Please try again or contact the admin.",
          status: "error",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            onCloseReset();
            onClose();
          },
        });
      });
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <Card>
          <Stack spacing={3}>
            <Select
              variant="outline"
              placeholder="Any Asset Type"
              onChange={handleAssetTypeSelection}
              value={selectedAssetType}
            >
              {assetTypes.map((item) => (
                <option key={item.key} value={item.value}>
                  {item.key}
                </option>
              ))}
            </Select>
            <Select
              variant="outline"
              placeholder="All Zones"
              value={selectedZone}
              onChange={handleZoneSelection}
            >
              {zones.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Select>

            {selectedZone && (
              <Select
                variant="outline"
                placeholder="All Buildings"
                value={selectedBuilding}
                onChange={handleBuildingSelection}
              >
                {buildingsForSelectedZone &&
                  buildingsForSelectedZone.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
              </Select>
            )}
          </Stack>
        </Card>
        <Card>
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"50%"}
            flexDir="column"
          >
            <Text fontSize={"xl"} fontWeight="bold" color="teal" mb={3}>
              {"TOTAL"}
            </Text>
            <Text fontSize={"2xl"} fontWeight="bold">
              {totalAssetsCount}
            </Text>
          </Flex>
        </Card>
        <Card>
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"50%"}
            flexDir="column"
          >
            <Text fontSize={"xl"} fontWeight="bold" color="blue.400" mb={3}>
              {"INSTALLED"}
            </Text>
            <Text fontSize={"2xl"} fontWeight="bold">
              {totalInstalledAssetsCount}
            </Text>
          </Flex>
        </Card>
        <Card>
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"50%"}
            flexDir="column"
          >
            <Text fontSize={"xl"} fontWeight="bold" color="black.500" mb={3}>
              {"DECOMMISSIONED"}
            </Text>
            <Text fontSize={"2xl"} fontWeight="bold">
              {totalDecommissionedAssetsCount}
            </Text>
          </Flex>
        </Card>
        <Card>
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"50%"}
            flexDir="column"
          >
            <Text fontSize={"xl"} fontWeight="bold" color="red.500" mb={3}>
              {"MISSING"}
            </Text>
            <Text fontSize={"2xl"} fontWeight="bold">
              {totalMissingAssetsCount}
            </Text>
          </Flex>
        </Card>
        <Card>
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"50%"}
            flexDir="column"
          >
            <Text fontSize={"xl"} fontWeight="bold" color="orange.500" mb={3}>
              {"BOXED"}
            </Text>
            <Text fontSize={"2xl"} fontWeight="bold">
              {totalBoxedAssetsCount}
            </Text>
          </Flex>
        </Card>
      </SimpleGrid>
      <Text fontSize={"3xl"} fontWeight="extrabold" my={3} ml={2}>
        Asset Status Logs
      </Text>
      <SimpleGrid gap="20px" mb="20px" direction="row">
        <Card>
          <Stack spacing={3} direction={{ base: "column", lg: "row" }}>
            <Select
              variant="outline"
              placeholder="Select Status Type"
              onChange={handleInventoryStatusLogSelection}
              value={selectedInventoryStatusLog}
            >
              {inventoryStatusTypes.map((item) => (
                <option key={item.key} value={item.value}>
                  {item.key}
                </option>
              ))}
            </Select>
            <Select
              variant="outline"
              placeholder="Any Asset Type"
              onChange={handleAssetTypeLogSelection}
              value={selectedAssetTypeLog}
            >
              {assetTypes.map((item) => (
                <option key={item.key} value={item.value}>
                  {item.key}
                </option>
              ))}
            </Select>
            <Select
              variant="outline"
              placeholder="All Zones"
              value={selectedZoneLog}
              onChange={handleZoneLogSelection}
            >
              {zones.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Select>

            {selectedZoneLog && (
              <Select
                variant="outline"
                placeholder="All Buildings"
                value={selectedBuildingLog}
                onChange={handleBuildingLogSelection}
              >
                {buildingsForSelectedZoneLog &&
                  buildingsForSelectedZoneLog.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
              </Select>
            )}
          </Stack>
        </Card>
      </SimpleGrid>
      {logData?.length > 0 ? (
        <Flex mx={{ base: 0, lg: 10 }}>
          <Line options={chartOptions} data={chartData} />
        </Flex>
      ) : (
        ""
      )}
      <Text fontSize={"3xl"} fontWeight="extrabold" my={5} ml={2}>
        Box Status Logs
      </Text>
      <SimpleGrid gap="20px" mb="20px" direction="row">
        <Card>
          <Stack spacing={3} direction={{ base: "column", lg: "row" }}>
            <Select
              variant="outline"
              placeholder="Select Status Type"
              onChange={handleBoxStatusLogSelection}
              value={selectedBoxStatusLog}
            >
              {boxStatusTypes.map((item) => (
                <option key={item.key} value={item.value}>
                  {item.key}
                </option>
              ))}
            </Select>
            <Select
              variant="outline"
              placeholder="Any Box Type"
              onChange={handleBoxTypeLogSelection}
              value={selectedBoxTypeLog}
            >
              {boxTypes.map((item) => (
                <option key={item.key} value={item.value}>
                  {item.key}
                </option>
              ))}
            </Select>
          </Stack>
        </Card>
      </SimpleGrid>
      {logData?.length > 0 ? (
        <Flex mx={{ base: 0, lg: 10 }}>
          <Line options={boxChartOptions} data={boxChartData} />
        </Flex>
      ) : (
        ""
      )}
    </Box>
  );
}

export default AssetsAnalytics;
