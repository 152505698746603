// Chakra imports
import {
  Box,
  Flex,
  Text,
  Select,
  useColorModeValue,
  Center,
  Skeleton,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";

import React, { useEffect, useState } from "react";
import { useChartDount } from "../../../../lib/hooks";
import ReactApexChart from "react-apexcharts";
import IconBox from "../../../../components/icons/IconBox";
import { Player } from "@lottiefiles/react-lottie-player";
import { useAuth0 } from "@auth0/auth0-react";

export default function Conversion(props) {
  const { loaded, ...rest } = props;
  const [mounted, setMounded] = useState(false);

  const { dataChart, isError } = useChartDount(mounted);
  const [selectedFilter, setSelectedFilter] = useState("department");

  let total = 0;
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgColor = useColorModeValue("#ffff", "#111c44");

  const { user, isLoading: userLoading } = useAuth0();
  useEffect(() => {
    const token = localStorage.getItem("auth0_token");
    if (user && !userLoading && token) {
      setMounded(true);
    }
  });
  const donutColors = {
    series1: "#E0457B",
    series2: "#B64D92",
    series3: "#855598",
    series4: "#57568D",
    series5: "#375275",
    series6: "#68BAA5",
    series7: "#E097A8",
    series8: "#fdc4d3",
    series9: "#ffd5e0",
  };

  const pieChartOptions = {
    labels: dataChart?.body?.[selectedFilter]?.map((item) => item?.fieldName),
    colors: [
      donutColors.series1,
      donutColors.series2,
      donutColors.series3,
      donutColors.series4,
      donutColors.series5,
      donutColors.series6,
      donutColors.series7,
      donutColors.series8,
      donutColors.series9,
    ],
    style: {
      color: "white",
    },
    chart: {
      width: "50px",
      foreColor: textColor,
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
    },
    dataLabels: {
      enabled: true,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: true,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },

    tooltip: {
      enabled: true,
      theme: "dark",
      y: {
        formatter: function (val) {
          let valString = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Adds thousands separator to hover tooltip value
          return valString;
        },
      },
    },
  };
  const selectChange = (e) => {
    setSelectedFilter(e.target.value);
  };
  return (
    <Skeleton
      p="20px"
      align="center"
      direction="column"
      w="100%"
      background={bgColor}
      {...rest}
      isLoaded={loaded}
      fadeDuration={1.5}
      borderRadius={20}
    >
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          User Analytics
        </Text>
        <Select
          fontSize="sm"
          variant="subtle"
          defaultValue="monthly"
          width="unset"
          fontWeight="700"
          onChange={selectChange}
          value={selectedFilter}
        >
          {dataChart && dataChart.body
            ? Object.keys(dataChart?.body).map((option, index) => (
                <option
                  key={index}
                  style={{
                    textTransform: "uppercase",
                  }}
                  onClick={() => setSelectedFilter({ option })}
                  value={option}
                >
                  {option.replace("_", " ").toUpperCase()}
                </option>
              ))
            : null}
        </Select>
      </Flex>
      <Flex></Flex>
      {dataChart?.body?.[selectedFilter]?.length !== 0 ? (
        <ReactApexChart
          options={pieChartOptions || []}
          series={
            (dataChart &&
              !isError &&
              dataChart?.body?.[selectedFilter]?.map(
                (item) => item.fieldCount
              )) ||
            []
          }
          type="pie"
          width="100%"
          height="100%"
        />
      ) : (
        <Flex flexDir={"column"} justifyContent={"center"}>
          <Center height={"230px"}>
            <IconBox
              w="56px"
              h="56px"
              icon={
                <Player
                  autoplay
                  loop
                  src="https://assets2.lottiefiles.com/packages/lf20_g4wqji2g.json"
                  style={{ height: "120px", width: "120px" }}
                ></Player>
              }
            />
          </Center>
          <Center>
            <Text mt={-10}>NO RECORDS YET</Text>
          </Center>
        </Flex>
      )}
    </Skeleton>
  );
}
