import React, { useState, useEffect } from "react";
import { usePagination, useTable } from "react-table";
import { CSVLink } from "react-csv";

import {
  MdArrowDropDown,
  MdCheckCircle,
  MdCloudDownload,
  MdOutlineFilterAlt,
} from "react-icons/md";
import { FaBoxes } from "react-icons/fa";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Select,
  Skeleton,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import Card from "./card/Card";
import { Player } from "@lottiefiles/react-lottie-player";
import { useAuth0 } from "@auth0/auth0-react";

export function MainTable({
  searchText,
  handleSearchText,
  setPerPage,
  exports,
  setPage,
  columns,
  data,
  currentpage,
  perPage,
  totalPage,
  name,
  drawer,
  totalRows,
  handleDrawer,
  tableName,
  loaded,
  setSortConfig,
  setFilterConfig,
}) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { user } = useAuth0();
  const userRole = user?.["https://admin.b2connect.me/roles"][0];

  const [sortWidth, setSortWidth] = useState("auto");
  const [sortValue, setSortValue] = useState("");
  const [sortText, setSortText] = useState("Default");

  const [employeeId, setEmployeeID] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [contact, setContact] = useState("");
  const [nationality, setNationality] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [department, setDepartment] = useState("");
  const [room, setRoom] = useState("");

  const [filterCount, setFilterCount] = useState(0);

  useEffect(() => {
    let filterArray = [];
    let filterValue = "";
    if (employeeId) {
      filterArray.push(`EMPLOYEE_ID:${employeeId}`);
    }
    if (nameFilter) {
      filterArray.push(`NAME:${nameFilter}`);
    }
    if (contact) {
      filterArray.push(`CONTACTNUMBER:${contact}`);
    }
    if (nationality) {
      filterArray.push(`NATIONALITY:${nationality}`);
    }
    if (jobRole) {
      filterArray.push(`JOB_ROLE:${jobRole}`);
    }
    if (department) {
      filterArray.push(`DEPARTMENT:${department}`);
    }
    if (room) {
      filterArray.push(`ROOMNUMBER:${room}`);
    }

    if (filterArray.length > 0) {
      filterValue = `filter=${filterArray.join(";")}`;
    }
    setFilterCount(filterArray.length);
    setFilterConfig(filterValue);
  }, [employeeId, nameFilter, contact, nationality, jobRole, department, room]);

  const resetFilters = () => {
    setEmployeeID("");
    setNameFilter("");
    setContact("");
    setNationality("");
    setJobRole("");
    setDepartment("");
    setRoom("");
  };

  useEffect(() => {
    // Define the multiplier in em units. For example, 1 character = 0.5em
    const emMultiplier = 10;
    console.log(sortText);

    // Calculate the width based on the length of the sortText
    const calculatedWidth = `${sortText.length * emMultiplier + 40}px`;
    console.log(calculatedWidth);
    setSortWidth(calculatedWidth);
  }, [sortText]);

  const handleSortChange = (event) => {
    // Set the value
    setSortValue(event.target.value);

    // Find and set the text of the selected option
    const selectedOptionText =
      event.target.options[event.target.selectedIndex].text;
    setSortText(selectedOptionText);
  };

  useEffect(() => {
    setSortConfig(sortValue);
  }, [sortValue]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: currentpage,
          }),
          [state, currentpage]
        );
      },
      initialState: { pageIndex: currentpage },
      manualPagination: true,
      pageCount: totalPage,
    },
    usePagination
  );

  return (
    <Card direction="column" w="100%" px="0px" overflowX={"scroll"}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {name}
        </Text>
        <Spacer />
        {/* <Input
          type="input"
          id="search"
          fontWeight="200"
          variant="main"
          placeholder="Search Contact number, First name or Last name..."
          _placeholder={{ fontWeight: "1500", color: "black" }}
          h="44px"
          maxh="44px"
          w="400px"
          maxw="100px"
          onChange={(e) => {
            handleSearchText(e.target.value);
          }}
        /> */}

        {data.length > 0 && exports && (
          <CSVLink filename={name + " " + Date()} data={exports}>
            <Flex flexDir={"row"}>
              {" "}
              <Icon
                color="secondaryGray.600"
                w="22px"
                mr={2}
                h="22px"
                as={MdCloudDownload}
              />{" "}
              Export to CSV
            </Flex>{" "}
          </CSVLink>
        )}
      </Flex>
      <Flex ml={6}>
        <Flex>
          <Popover>
            <PopoverTrigger>
              <Button
                boxShadow={"sm"}
                borderRadius={5}
                background={"#f6ad55"}
                color={"whiteAlpha.900"}
              >
                <Flex flexDir={"row"}>
                  <Flex mr={3}>
                    <MdOutlineFilterAlt size={20} />
                  </Flex>
                  <Flex>
                    <Text fontWeight={600}>Filters </Text>
                    {filterCount > 0 && (
                      <Text
                        ml={2}
                        borderRadius={20}
                        background={"white"}
                        color={"black"}
                        px={2}
                        fontWeight={600}
                      >
                        {filterCount}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent _focus={{ outline: 0 }}>
                <PopoverArrow />
                <PopoverHeader fontWeight={600}>Filter Items</PopoverHeader>
                <PopoverCloseButton />
                <PopoverBody>
                  <Accordion allowToggle _focus={{ outline: 0 }}>
                    <AccordionItem _focus={{ outline: 0 }}>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left" flexDir={"row"}>
                            <Flex>
                              <Text>Employee ID</Text>
                              {employeeId && (
                                <Text
                                  ml={2}
                                  borderRadius={20}
                                  background={"gray"}
                                  color={"white"}
                                  px={"10px"}
                                  fontWeight={600}
                                >
                                  1
                                </Text>
                              )}
                            </Flex>
                          </Box>

                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Flex flexDir={"column"}>
                          <Flex>
                            <Text fontSize={"sm"}>
                              You can search by employee id
                            </Text>
                          </Flex>
                          <Flex mt={3} flexDir={"row"}>
                            <Input
                              color={textColor}
                              placeholder={"Eg: 1234"}
                              type={"text"}
                              max={5}
                              min={0}
                              onChange={(e) => {
                                setEmployeeID(e.target.value);
                              }}
                              value={employeeId}
                            />
                          </Flex>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem _focus={{ outline: 0 }}>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left" flexDir={"row"}>
                            <Flex>
                              <Text>Name</Text>
                              {nameFilter && (
                                <Text
                                  ml={2}
                                  borderRadius={20}
                                  background={"gray"}
                                  color={"white"}
                                  px={"10px"}
                                  fontWeight={600}
                                >
                                  1
                                </Text>
                              )}
                            </Flex>
                          </Box>

                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Flex flexDir={"column"}>
                          <Flex>
                            <Text fontSize={"sm"}>You can search by name</Text>
                          </Flex>
                          <Flex mt={3} flexDir={"row"}>
                            <Input
                              color={textColor}
                              placeholder={"Eg: John"}
                              type={"text"}
                              max={5}
                              min={0}
                              onChange={(e) => {
                                setNameFilter(e.target.value);
                              }}
                              value={nameFilter}
                            />
                          </Flex>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem _focus={{ outline: 0 }}>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left" flexDir={"row"}>
                            <Flex>
                              <Text>Contact No.</Text>
                              {contact && (
                                <Text
                                  ml={2}
                                  borderRadius={20}
                                  background={"gray"}
                                  color={"white"}
                                  px={"10px"}
                                  fontWeight={600}
                                >
                                  1
                                </Text>
                              )}
                            </Flex>
                          </Box>

                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Flex flexDir={"column"}>
                          <Flex>
                            <Text fontSize={"sm"}>
                              You can search by contact number
                            </Text>
                          </Flex>
                          <Flex mt={3} flexDir={"row"}>
                            <Input
                              color={textColor}
                              mr={2}
                              w={"40%"}
                              disabled
                              value={"971"}
                            />
                            <Input
                              color={textColor}
                              placeholder={"EX: 12345"}
                              type={"number"}
                              min={0}
                              maxLength={10}
                              onChange={(e) => {
                                setContact(e.target.value);
                              }}
                              value={contact}
                            />
                          </Flex>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem _focus={{ outline: 0 }}>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left" flexDir={"row"}>
                            <Flex>
                              <Text>Nationality</Text>
                              {nationality && (
                                <Text
                                  ml={2}
                                  borderRadius={20}
                                  background={"gray"}
                                  color={"white"}
                                  px={"10px"}
                                  fontWeight={600}
                                >
                                  1
                                </Text>
                              )}
                            </Flex>
                          </Box>

                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Flex flexDir={"column"}>
                          <Flex>
                            <Text fontSize={"sm"}>
                              You can search by nationality
                            </Text>
                          </Flex>
                          <Flex mt={3} flexDir={"row"}>
                            <Input
                              color={textColor}
                              placeholder={"Eg: INDIA"}
                              type={"text"}
                              max={5}
                              min={0}
                              onChange={(e) => {
                                setNationality(e.target.value.toUpperCase());
                              }}
                              value={nationality}
                            />
                          </Flex>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem _focus={{ outline: 0 }}>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left" flexDir={"row"}>
                            <Flex>
                              <Text>Position</Text>
                              {jobRole && (
                                <Text
                                  ml={2}
                                  borderRadius={20}
                                  background={"gray"}
                                  color={"white"}
                                  px={"10px"}
                                  fontWeight={600}
                                >
                                  1
                                </Text>
                              )}
                            </Flex>
                          </Box>

                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Flex flexDir={"column"}>
                          <Flex>
                            <Text fontSize={"sm"}>
                              You can search by position
                            </Text>
                          </Flex>
                          <Flex mt={3} flexDir={"row"}>
                            <Input
                              color={textColor}
                              placeholder={"Eg: Manager"}
                              type={"text"}
                              max={5}
                              min={0}
                              onChange={(e) => {
                                setJobRole(e.target.value);
                              }}
                              value={jobRole}
                            />
                          </Flex>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem _focus={{ outline: 0 }}>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left" flexDir={"row"}>
                            <Flex>
                              <Text>Department</Text>
                              {department && (
                                <Text
                                  ml={2}
                                  borderRadius={20}
                                  background={"gray"}
                                  color={"white"}
                                  px={"10px"}
                                  fontWeight={600}
                                >
                                  1
                                </Text>
                              )}
                            </Flex>
                          </Box>

                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Flex flexDir={"column"}>
                          <Flex>
                            <Text fontSize={"sm"}>
                              You can search by department
                            </Text>
                          </Flex>
                          <Flex mt={3} flexDir={"row"}>
                            <Input
                              color={textColor}
                              placeholder={"Eg: IT"}
                              type={"text"}
                              max={5}
                              min={0}
                              onChange={(e) => {
                                setDepartment(e.target.value);
                              }}
                              value={department}
                            />
                          </Flex>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem _focus={{ outline: 0 }}>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left" flexDir={"row"}>
                            <Flex>
                              <Text>Room/Block</Text>
                              {room && (
                                <Text
                                  ml={2}
                                  borderRadius={20}
                                  background={"gray"}
                                  color={"white"}
                                  px={"10px"}
                                  fontWeight={600}
                                >
                                  1
                                </Text>
                              )}
                            </Flex>
                          </Box>

                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Flex flexDir={"column"}>
                          <Flex>
                            <Text fontSize={"sm"}>
                              You can search by room number & block
                            </Text>
                          </Flex>
                          <Flex mt={3} flexDir={"row"}>
                            <Input
                              color={textColor}
                              placeholder={"Eg: B1"}
                              type={"text"}
                              min={0}
                              onChange={(e) => {
                                setRoom(e.target.value);
                              }}
                              value={room}
                            />
                          </Flex>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </PopoverBody>
                <PopoverFooter>
                  <Flex justifyContent={"center"}>
                    <Button colorScheme={"gray"} ml={2} onClick={resetFilters}>
                      Reset
                    </Button>
                  </Flex>
                </PopoverFooter>
              </PopoverContent>
            </Portal>
          </Popover>
        </Flex>

        <Flex>
          <Select
            mx={4}
            value={sortValue}
            onChange={handleSortChange}
            width={sortWidth}
            placeholder="Sort By"
          >
            <option value="" selected>
              Default
            </option>
            <option value="sortBy=EMPLOYEE_ID&sortOrder=ASC">
              Employee Id - Ascending
            </option>
            <option value="sortBy=EMPLOYEE_ID&sortOrder=DESC">
              Employee Id - Descending
            </option>
            <option value="sortBy=FIRSTNAME&sortOrder=ASC">
              Name - Ascending
            </option>
            <option value="sortBy=FIRSTNAME&sortOrder=DESC">
              Name - Descending
            </option>
            <option value="sortBy=CONTACTNUMBER&sortOrder=ASC">
              Contact Number - Ascending
            </option>
            <option value="sortBy=CONTACTNUMBER&sortOrder=DESC">
              Contact Number - Descending
            </option>
            <option value="sortBy=NATIONALITY&sortOrder=ASC">
              Nationality - Ascending
            </option>
            <option value="sortBy=NATIONALITY&sortOrder=DESC">
              Nationality - Descending
            </option>
            <option value="sortBy=JOB_ROLE&sortOrder=ASC">
              Position - Ascending
            </option>
            <option value="sortBy=JOB_ROLE&sortOrder=DESC">
              Position - Descending
            </option>
          </Select>
        </Flex>
      </Flex>
      {/* <Flex px="25px" mt="-5" mr={19} placeContent="flex-end">
        {searchText?.length > 0 && (
          <Text fontWeight={1500}>
            Search Contact number, First name or Last name...
          </Text>
        )}
      </Flex> */}
      <Skeleton isLoaded={loaded} fadeDuration={1} borderRadius={20} m={5}>
        <Table {...getTableProps()}>
          {data.length > 0 ? (
            <>
              <Thead>
                {headerGroups.map((headerGroup, index) => (
                  <Tr key={index}>
                    {headerGroup.headers.map((column) => (
                      <Th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <Tr
                      {...row.getRowProps()}
                      cursor={drawer ? "pointer" : null}
                      _hover={
                        drawer ? { background: "rgba(81, 81, 81, 0.16)" } : null
                      }
                      key={i}
                      onClick={(e) =>
                        drawer
                          ? handleDrawer(row.cells[0]?.row?.original)
                          : null
                      }
                    >
                      {row.cells.map((cell, i) => {
                        return (
                          <Td
                            {...cell.getCellProps()}
                            fontSize={{ base: "md", md: "sm", xl: "sm" }}
                            key={i}
                          >
                            {cell.render("Cell")}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </>
          ) : (
            <Flex
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Player
                autoplay
                loop
                src="https://assets2.lottiefiles.com/packages/lf20_g4wqji2g.json"
                style={{ height: "200px", width: "200px" }}
              ></Player>

              <Flex flexDir={"column"} justifyContent={"space-between"}>
                <Text fontSize={"lg"} my={3}>
                  NO RECORDS YET
                </Text>
              </Flex>
            </Flex>
          )}
        </Table>
      </Skeleton>
      {data.length > 0 ? (
        <>
          <Flex justifyContent="space-between" m={4} alignItems="center">
            <Flex>
              <Tooltip label="First Page">
                <IconButton
                  onClick={() => {
                    setPage(1);
                  }}
                  isDisabled={currentpage === 1}
                  icon={<ArrowLeftIcon h={3} w={3} />}
                  mr={4}
                />
              </Tooltip>
              <Tooltip label="Previous Page">
                <IconButton
                  onClick={() => {
                    setPage((s) => (s === 0 ? 0 : s - 1));
                  }}
                  isDisabled={currentpage === 1}
                  icon={<ChevronLeftIcon h={6} w={6} />}
                />
              </Tooltip>
            </Flex>

            <Flex alignItems="center">
              <Text flexShrink="0" mr={8}>
                Page{" "}
                <Text fontWeight="bold" as="span">
                  {pageIndex}
                </Text>{" "}
                of{" "}
                <Text fontWeight="bold" as="span">
                  {totalPage || 1}
                </Text>
              </Text>
              <Text flexShrink="0" color={textColor}>
                Go to page:
              </Text>{" "}
              <NumberInput
                ml={2}
                mr={8}
                w={28}
                min={1}
                max={totalPage}
                onChange={(e) => {
                  const page = e ? Number(e) : 1;
                  setPage(page);
                }}
                color={textColor}
                isDisabled={currentpage === totalPage || !totalPage}
                defaultValue={pageIndex}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Select
                w={32}
                value={perPage}
                onChange={(e) => {
                  setPerPage(Number(e.target.value));
                }}
              >
                {[5, 10, 20].map((pageSize, i) => (
                  <>
                    <option key={i} value={pageSize}>
                      Show {pageSize}
                    </option>
                  </>
                ))}
              </Select>
            </Flex>

            <Flex>
              <Tooltip label="Next Page">
                <IconButton
                  onClick={() => {
                    setPage((s) => s + 1);
                  }}
                  isDisabled={currentpage === totalPage || !totalPage}
                  icon={<ChevronRightIcon h={6} w={6} />}
                />
              </Tooltip>
              <Tooltip label="Last Page">
                <IconButton
                  onClick={() => {
                    setPage(totalPage);
                  }}
                  isDisabled={currentpage === totalPage || !totalPage}
                  icon={<ArrowRightIcon h={3} w={3} />}
                  ml={4}
                />
              </Tooltip>
            </Flex>
          </Flex>
        </>
      ) : null}
    </Card>
  );
}
