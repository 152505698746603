import React, {useReducer, useState} from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Textarea,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import FileUploaderSingle from "./components/FileUploaderSingle";
import axios from "axios";
import {DateRangeInput} from "@datepicker-react/styled";
import moment from "moment";


const initialState = {
  startDate: null,
  endDate: null,
  focusedInput: null,
}

function reducer(state, action) {
  switch (action.type) {
    case 'focusChange':
      return {...state, focusedInput: action.payload}
    case 'dateChange':
      return action.payload
    default:
      throw new Error()
  }
}

function New() {
  //hooks
  const [formTitle, setFormTitle] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [formCategory, setFormCategory] = useState("");
  const [formImage, setFormImage] = useState("");
  const [files, setFiles] = useState([]);
  const toast = useToast({
    title: "Announcement Created.",
    description: "You've successfully created new announcement.",
    status: "success",
    duration: 4000,
    isClosable: true,
  });
  let authToken = localStorage.getItem("auth0_token");
  const [state, dispatch] = useReducer(reducer, initialState)

  //temp validation
  const [formTitleError, setFormTitleError] = useState(false);
  const [formDescriptionError, setFormDescriptionError] = useState(false);
  const [formCategoryError, setFormCategoryError] = useState(false);
  const [formImageError, setFormImageError] = useState(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const inputBg = useColorModeValue("white", "transparent");
  const buttonBg = useColorModeValue("#f6ad55", "#1e2647");


  const handleSubmit = async () => {
    if (!formTitle) {
      setFormTitleError(true);
    } else {
      setFormTitleError(false);
    }
    if (!state?.startDate) {
      setFormTitleError(true);
    } else {
      setFormTitleError(false);
    }
    if (!state?.endDate) {
      setFormTitleError(true);
    } else {
      setFormTitleError(false);
    }
    if (!formDescription) {
      setFormDescriptionError(true);
    } else {
      setFormDescriptionError(false);
    }
    if (!formCategory) {
      setFormCategoryError(true);
    } else {
      setFormCategoryError(false);
    }
    if (!files) {
      setFormImageError(true);
    } else {
      setFormImageError(false);
    }
    var startDate = new Date(state?.startDate);
    var momentObj = moment(startDate);
    var momentStartDate = momentObj.format('YYYY-MM-DD'); // 2016-07-15

    var endDate = new Date(state?.endDate);
    var momentObj = moment(endDate);
    var momentEndDate = momentObj.format('YYYY-MM-DD'); // 2016-07-15



    if (files && formCategory && formDescription && formTitle) {



      let formData = new FormData();
      formData.append("title", formTitle);
      formData.append("category", formCategory);
      formData.append("description", formDescription);
      formData.append("file", files[0]);
      formData.append("start", momentStartDate);
      formData.append("expiry", momentEndDate);

      try{
        await axios.post(`${process.env.REACT_APP_BASE_URL}/announcement/`, formData, {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }).then((res) => {

          toast({
            title: "Announcement created successfully .",
            description: "You've just created new announcement.",
            status: "success",
            duration: 4000,
            isClosable: true,
          })
        }).catch(e => {
          toast({
            title: "Something went wrong .",
            description: "Please try again or contact the admin.",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        });

      }catch (e) {
        //console.log(e)
      }


    }else{
      toast({
        title: "Please fill all the data.",
        description: "All the fields are required.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };
  return (
    <Box pt={{ base: "150px", md: "80px", xl: "80px" }}>
      <FormControl width={{ base: "100%", md: "90%", lg: "70%", "2xl": "60%" }}>
        <FormLabel htmlFor="title">Title</FormLabel>
        <Input
          id="title"
          type="text"
          placeholder={"Announcement Title"}
          background={inputBg}
          onChange={(e) => setFormTitle(e.target.value)}
          color={textColor}
          isInvalid={formTitleError}
        />
        <FormHelperText>Enter your title here.</FormHelperText>
      </FormControl>
      <FormControl
        width={{ base: "100%", md: "90%", lg: "70%", "2xl": "60%" }}
        mt={3}
      >


        <FormLabel htmlFor="country">Categories</FormLabel>
        <Select
          id="category"
          placeholder="Select category"
          background={inputBg}
          onChange={(e) => setFormCategory(e.target.value)}
          color={textColor}
          isInvalid={formCategoryError}
        >
          <option value={"Corporate News"}>Corporate News</option>
          <option>Health &amp; Safety</option>
          <option>Human Resource</option>
          <option>Maintenance &amp; Support</option>
          <option>Games &amp; Activities</option>
          <option>Events &amp; Promotions</option>
          <option>Environment &amp; Eco-friendly</option>
        </Select>

      </FormControl>
      <FormControl
          width={{ base: "100%", md: "90%", lg: "70%", "2xl": "60%" }}
          mt={3}
          zIndex={999}
      >
        <FormLabel htmlFor="title">Visibility</FormLabel>
        <DateRangeInput
            onDatesChange={data => dispatch({type: 'dateChange', payload: data})}
            onFocusChange={focusedInput => dispatch({type: 'focusChange', payload: focusedInput})}
            startDate={state.startDate}
            endDate={state.endDate}
            focusedInput={state.focusedInput}
            background={"black"}
            minBookingDate={new Date()}
        />

        <FormHelperText>Enter announcement visibility dates.</FormHelperText>
      </FormControl>

      <FormControl
        width={{ base: "100%", md: "90%", lg: "70%", "2xl": "60%" }}
        mt={3}
      >
        <FormLabel htmlFor="description">Description</FormLabel>
        <Textarea
          id={"description"}
          placeholder="Announcement Description "
          onChange={(e) => setFormDescription(e.target.value)}
          color={textColor}
          isInvalid={formDescriptionError}
          background={inputBg}
        />
        <FormHelperText>Enter your description here.</FormHelperText>
      </FormControl>
      <FormControl
        width={{ base: "100%", md: "90%", lg: "70%", "2xl": "60%" }}
        mt={3}
      >
        <FormLabel htmlFor="image">Upload an Image</FormLabel>
        <FileUploaderSingle files={files} setFiles={setFiles} />
      </FormControl>
      <FormControl
        width={{ base: "100%", md: "90%", lg: "80%", "2xl": "70%" }}
        mt={5}
      >
        <Button
          bg={buttonBg}
          color={"white"}
          onClick={handleSubmit}
          disabled={!formTitle || !formDescription || !formCategory || !files || !state?.startDate || !state?.endDate}
        >
          Submit
        </Button>
      </FormControl>
    </Box>
  );
}

export default New;
