import React, { useState, useMemo } from "react";

// Chakra imports
import {
  Badge,
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

import { useSurvey } from "../../../lib/hooks";
import { MainTable } from "../../../components/Table/MainTable";

//Custom imports
import moment from "moment";
import { Player } from "@lottiefiles/react-lottie-player";
import IconBox from "../../../components/icons/IconBox";

export default function Survey() {
  // Hooks
  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(25);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCell, setSelectedCell] = useState({});

  // Chakra Color Mode
  const { survey, isError, isLoading } = useSurvey();

  const handleDrawer = (data) => {
    setSelectedCell(data);
    onOpen();
  };

  const surveyTableData =
    !isError && !isLoading && survey
      ? survey?.body?.surveys?.map((item) => ({
          title: item.title,
          category: item.category,
          surveyLink: (
            <Flex flexDir={"row"}>
              {" "}
              <IconBox
                icon={
                  <Player
                    autoplay
                    loop
                    src={
                      "https://assets6.lottiefiles.com/private_files/lf30_JvZwDp.json"
                    }
                    style={{ height: "20px", width: "20px" }}
                  ></Player>
                }
              />{" "}
              <Link href={item.surveyLink} target={"_blank"} ml={1}>
                Survey URL
              </Link>
            </Flex>
          ),
          created: moment(item.created * 1000)
            .utc()
            .format("LL"),
          expiry: moment(item.expiry * 1000)
            .utc()
            .format("LL"),
          status:
            item.status == "ACTIVE" ? (
              <Badge colorScheme={"green"}> Active </Badge>
            ) : (
              <Badge>Inactive</Badge>
            ),
          siteId: item.siteId,
        }))
      : [];

  const data = React.useMemo(() => surveyTableData, [survey]);
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const columns = useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "Title",
            accessor: "title",
          },
          {
            Header: "Category",
            accessor: "category",
          },
          {
            Header: "Link",
            accessor: "surveyLink",
          },
        ],
      },
      {
        Header: " ",
        columns: [
          {
            Header: "Created",
            accessor: "created",
          },
          {
            Header: "Expiry",
            accessor: "expiry",
          },
          {
            Header: "Status",
            accessor: "status",
          },
        ],
      },
    ],
    []
  );
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Drawer onClose={onClose} isOpen={isOpen} size={"md"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{selectedCell.title}</DrawerHeader>
          <DrawerBody>
            <Flex>
              <FormControl>
                <Stack spacing={3}>
                  <FormLabel htmlFor="title">Title</FormLabel>
                  <Input
                    id="title"
                    type="text"
                    disabled
                    value={selectedCell.title}
                    color={textColor}
                  />

                  <FormLabel htmlFor="category">Category</FormLabel>
                  <Input
                    id="category"
                    type="text"
                    disabled
                    value={selectedCell.category}
                    color={textColor}
                  />
                  <FormLabel htmlFor="created">Created</FormLabel>
                  <Input
                    id="created"
                    type="text"
                    disabled
                    value={selectedCell.created}
                    color={textColor}
                  />
                  <FormLabel htmlFor="expiry">Expiry</FormLabel>
                  <Input
                    id="expiry"
                    type="text"
                    disabled
                    value={selectedCell.expiry}
                    color={textColor}
                  />
                </Stack>
              </FormControl>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <MainTable
        loaded={!isLoading}
        tableName={"Surveys"}
        data={data}
        columns={columns}
        setPage={setPage}
        setPerPage={setPer_page}
        currentpage={page}
        perPage={per_page}
        exports={survey ? survey?.body?.surveys : []}
        totalPage={per_page ? parseInt(survey?.body?.total / per_page) : 1}
        name={"Survey"}
        drawer={true}
        handleDrawer={handleDrawer}
      />
      {(data === undefined || data.length == 0) && (
        <Center>
          <Text fontSize={"lg"} my={5} mr={1}>
            For creating a new survey, please contact your B2 Account Manager or
            email to
          </Text>
          <Link fontSize={"lg"} href="mailto:support@bsquaredwifi.com">
            support@bsquaredwifi.com
          </Link>
        </Center>
      )}
    </Box>
  );
}
