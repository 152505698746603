import React, { useState } from "react";

import { Step, Steps, useSteps } from "chakra-ui-steps";

// Chakra imports
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

// Custom Components
import IconBox from "../../../../components/icons/IconBox";
import { Player } from "@lottiefiles/react-lottie-player";
import MiniStatistics from "../../../../components/card/MiniStatistics";
import { useFilterGroups, useHistory } from "../../../../lib/hooks";
import { Link } from "react-router-dom";
import { MainTable } from "./components/Table/MainTable";
import { useAuth0 } from "@auth0/auth0-react";
import { count } from "sms-length";
import millify from "millify";
import fetcher from "../../../../lib/fetcher";
import { mutate } from "swr";
import moment from "moment";

const steps = [
  {
    id: 1,
    label: "Select Group",
    description: "Select Group",
  },
  {
    id: 2,
    label: "Notification Options",
    description: "Enter Your Notification Details",
  },
  { id: 3, label: "Preview", description: "Preview Your Data" },
];

function Index() {
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });
  const [processDone, setProcessDone] = useState(false);

  // Chakra Color Mode
  const boxBg = useColorModeValue("white", "whiteAlpha.100");
  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(5);
  const { user } = useAuth0();
  const userRole = user?.["https://admin.b2connect.me/roles"][0];

  const [selectedGroup, setSelectedGroup] = useState("Test Group");
  const [selectedChannel, setSelectedChannel] = useState("in-app");
  const [notificationTitle, setNotificatiionTitle] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [smsMessageObject, setSmsMessageObject] = useState({});
  const [error, setError] = useState(false);

  const isError = false;

  const [selectedCell, setSelectedCell] = useState({});
  const { history, isLoading } = useHistory(page, per_page);
  const { filterGroups, isGroupsLoading } = useFilterGroups(page, per_page);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [notificationEventHistory, setNotificationEventHistory] = useState({});
  const toast = useToast();
  const {
    isOpen: isOpenDeactivate,
    onOpen: onOpenDeactivate,
    onClose: onCloseDeactivae,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();

  function isWhatPercentOf(numA, numB) {
    return (numA / numB) * 100;
  }

  const capitalizeFirstLetterOfEachWord = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
  };
  const historyData = filterGroups
    ? filterGroups?.body?.map((item, i) => ({
        name: item.name,
        eventId: item.id,
        owner: (
          <Tooltip key={i} label={item.owner}>
            <Avatar
              name={item.createdBy}
              bg={"#f6ad56"}
              color={"white"}
              size={"sm"}
            />
          </Tooltip>
        ),
        total: (
          <Badge mx={1} colorScheme={"purple"}>
            {millify(item.count, {
              precision: 2,
              decimalSeparator: ".",
            })}
          </Badge>
        ),
        date: moment
          .utc(item?.timestamp * 1000)
          .utcOffset("+04:00")
          .format("MMMM Do YYYY, h:mm:ss a"),
      }))
    : [];

  const data = React.useMemo(() => historyData, [filterGroups]);

  const columns = React.useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "Group Name",
            accessor: "name",
          },
          {
            Header: "Created by",
            accessor: "owner",
          },
          {
            Header: "Creation Date",
            accessor: "date",
          },
          {
            Header: "Total Users",
            accessor: "total",
          },
        ],
      },
    ],
    []
  );

  const resetGroup = () => {
    setSelectedChannel("in-app");
    setNotificatiionTitle("");
    setNotificationMessage("");
    setProcessDone(false);
    reset();
  };

  const handleProcess = async () => {
    if (activeStep === 0) {
      nextStep();
    }
    if (activeStep === 1) {
      nextStep();
    }

    if (activeStep === 2) {
      setProcessDone(true);
      toast({
        title: `Notification sent successfully`,
        description: `Notification has been sent.`,
        status: "success",
        duration: 4000,
        isClosable: true,
        onCloseComplete: async () => {
          resetGroup();
          reset();
        },
      });
    }
  };

  const handleSelectChannel = (e) => {
    setSelectedChannel(e.target.value);
    console.log(e.target.value);
  };

  const handleMessage = (e) => {
    setSmsMessageObject(count(e.target.value));
    setNotificationMessage(e.target.value);
  };

  const handleDrawer = async (data) => {
    setSelectedCell(data);
    setSelectedGroup(data.name);

    const historyEvent = filterGroups?.body?.find((obj) => {
      return obj.id === data.eventId;
    });

    setNotificationEventHistory(historyEvent);

    onOpen();
  };

  const handleDrawerRetry = async (data) => {
    const historyEvent = await fetcher(`notification/resend/${data}`, {});
    //console.log(historyEvent)
    if (historyEvent.success) {
      toast({
        title: `Notification scheduled successfully`,
        description: `Notification has been scheduled.`,
        status: "success",
        duration: 2000,
        isClosable: true,
        onCloseComplete: async () => {
          await mutate("notification/history");

          onClose();
        },
      });
    }

    onOpen();
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Drawer onClose={onClose} isOpen={isOpen} size={"md"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{selectedCell.title}</DrawerHeader>
          <DrawerBody>
            <Flex>
              <FormControl>
                <Stack spacing={3}>
                  <FormLabel htmlFor="title">Group Name</FormLabel>
                  <Input
                    id="title"
                    type="text"
                    disabled
                    value={notificationEventHistory?.name}
                    color={textColor}
                  />

                  <FormLabel htmlFor="category">Created By</FormLabel>
                  <Input
                    id="Owner"
                    type="text"
                    disabled
                    value={notificationEventHistory?.createdBy}
                    color={textColor}
                  />
                  <FormLabel htmlFor="category">Total Users</FormLabel>
                  <Input
                    id="Owner"
                    type="text"
                    disabled
                    value={notificationEventHistory.count}
                    color={textColor}
                  />
                  <FormLabel htmlFor="expiry">Creation Date</FormLabel>
                  <Input
                    id="date"
                    type="text"
                    disabled
                    value={moment
                      .utc(notificationEventHistory?.timestamp * 1000)
                      .utcOffset("+04:00")
                      .format("MMMM Do YYYY, h:mm:ss a")}
                    color={textColor}
                  />
                  <FormLabel htmlFor="filters">Filters</FormLabel>

                  {notificationEventHistory?.filters?.map((filter, index) => {
                    return (
                      <Text pl={4} key={index}>
                        <Text as="span" fontWeight="medium">
                          {capitalizeFirstLetterOfEachWord(filter.filterField)}
                        </Text>
                        {`: ${filter.text}`}
                      </Text>
                    );
                  })}
                </Stack>
                <Button
                  mt={4}
                  onClick={() => {
                    handleProcess();
                    onClose();
                  }}
                  colorScheme={"pink"}
                >
                  Select Group
                </Button>
                <Flex>
                  <AlertDialog
                    isOpen={isOpenDeactivate}
                    onClose={onCloseDeactivae}
                  >
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                          {selectedCell.bkStatus === "ACTIVE"
                            ? "Deactivate announcement"
                            : "Activate announcement"}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                          Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                          <Button onClick={onCloseDeactivae}>Cancel</Button>
                          <Button colorScheme="red" ml={3}>
                            {selectedCell.bkStatus === "ACTIVE"
                              ? "Deactivate"
                              : "Activate"}
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </Flex>
              </FormControl>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          loaded={!isLoading}
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Player
                  autoplay
                  loop
                  src="/lottiefiles/individual.json"
                  style={{ height: "120px", width: "120px" }}
                ></Player>
              }
            />
          }
          name="Individual"
          value={
            <div p={4}>
              <Button>
                <Link to={"/dashboard/notification/individual"}>Send</Link>{" "}
              </Button>
            </div>
          }
        />
        <MiniStatistics
          loaded={!isLoading}
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Player
                  autoplay
                  loop
                  src="/lottiefiles/group.json"
                  style={{ height: "80px", width: "80px" }}
                ></Player>
              }
            />
          }
          name="Group"
          value={
            <div m={4}>
              <Button>
                <Link to={"/dashboard/notification/group"}>Send</Link>
              </Button>
            </div>
          }
        />
        <MiniStatistics
          loaded={!isLoading}
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Player
                  autoplay
                  loop
                  src="/lottiefiles/filter.json"
                  style={{ height: "80px", width: "80px" }}
                ></Player>
              }
            />
          }
          name="Filters"
          value={
            <div m={4}>
              <Button>
                <Link to={"/dashboard/notification/filter"}>Send</Link>
              </Button>
            </div>
          }
        />
      </SimpleGrid> */}
      <Flex
        flexDir="column"
        width="100%"
        bg={boxBg}
        p={5}
        borderRadius={20}
        shadow={"sm"}
      >
        <Steps colorScheme="pink" activeStep={activeStep}>
          {steps.map(({ label, description, id }, index) => (
            <Step
              label={label}
              key={label}
              description={description}
              textColor={"#fff"}
            >
              {id == 1 ? (
                <MainTable
                  mt={3}
                  loaded={!isGroupsLoading}
                  tableName={"Notifications"}
                  data={data}
                  exports={
                    userRole === "Admin" ? history?.body?.notifications : false
                  }
                  columns={columns}
                  setPage={setPage}
                  setPerPage={setPer_page}
                  currentpage={page}
                  perPage={per_page}
                  drawer={true}
                  handleDrawer={handleDrawer}
                  totalPage={1}
                  name={"Saved Groups"}
                />
              ) : null}
              {id == 2 ? (
                <Flex px={4} pt={8} width="100%" flexDirection="column">
                  <Heading fontSize="md">{label}</Heading>
                  <FormControl isInvalid={isError} width={"350px"} pt={5}>
                    <FormLabel pt={3} htmlFor="selectedGroup">
                      Selected Group
                    </FormLabel>
                    <Input
                      id="selectedGroup"
                      type="text"
                      value={`${selectedGroup}`}
                      color={textColor}
                      disabled={true}
                    />
                    <FormLabel>Channel</FormLabel>
                    <Select
                      value={selectedChannel}
                      onChange={handleSelectChannel}
                    >
                      {/* <option selected value={"sms"}>
                        SMS
                      </option> */}
                      <option selected value={"in-app"}>
                        IN APP
                      </option>
                    </Select>
                    <FormLabel pt={3} htmlFor="title">
                      Title
                    </FormLabel>
                    <Input
                      id="title"
                      type="text"
                      value={notificationTitle}
                      color={textColor}
                      isInvalid={error}
                      onChange={(e) => setNotificatiionTitle(e.target.value)}
                    />
                    {!isError ? (
                      <FormHelperText>
                        Enter a notification title.
                      </FormHelperText>
                    ) : (
                      <FormErrorMessage>Title is required.</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={isError} width={"350px"} pt={5}>
                    <FormLabel htmlFor="message">Message</FormLabel>

                    <Textarea
                      id={"message"}
                      value={notificationMessage}
                      onChange={(e) => handleMessage(e)}
                      isInvalid={error}
                      size="sm"
                    />
                    {!isError ? (
                      <>
                        <Flex
                          flexDir={"row"}
                          justifyContent={"space-between"}
                        ></Flex>
                        <Flex flexDir={"row"} justifyContent={"space-between"}>
                          <FormHelperText>
                            Enter the notification message.
                          </FormHelperText>
                          <FormHelperText>
                            {notificationMessage.length !== 0
                              ? `${notificationMessage.length} (${smsMessageObject?.characterPerMessage} per msg)`
                              : null}
                          </FormHelperText>
                        </Flex>
                      </>
                    ) : (
                      <FormErrorMessage>Message is required.</FormErrorMessage>
                    )}
                    {smsMessageObject?.messages > 1 && (
                      <FormHelperText>
                        SMS Count{" "}
                        <Badge ml={1} colorScheme="red">
                          {smsMessageObject?.messages
                            ? smsMessageObject?.messages
                            : 1}
                        </Badge>
                        <Popover isLazy placement="right">
                          <PopoverTrigger>
                            <IconButton
                              size="sm"
                              justifyContent={"center"}
                              pb={1}
                              icon={
                                <Player
                                  autoplay
                                  loop
                                  speed={0.1}
                                  src="https://assets7.lottiefiles.com/packages/lf20_Z8MOn3.json"
                                  style={{ height: "35px", width: "35px" }}
                                ></Player>
                              }
                            />
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverHeader fontWeight="semibold">
                              B2Connect SMS Counter
                            </PopoverHeader>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody>
                              Seems, the entered sms characters exceeded and
                              reached {smsMessageObject.messages} SMS - It may
                              incur an additional cost & count!
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </FormHelperText>
                    )}
                  </FormControl>
                </Flex>
              ) : null}
              {id == 3 ? (
                <Flex px={4} pt={8} width="100%" flexDirection="column">
                  <Heading fontSize="md">{label} 3</Heading>
                  <TableContainer>
                    <Table variant="simple">
                      <TableCaption>
                        Check the current information and confirm
                      </TableCaption>
                      <Thead>
                        <Tr>
                          <Th>Channel</Th>
                          <Th>Title</Th>
                          <Th>Message</Th>
                          <Th>Group Name</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>
                            {selectedChannel === "in-app"
                              ? ["IN APP"]
                              : ["SMS"]}
                          </Td>
                          <Td>{notificationTitle}</Td>
                          <Td>{notificationMessage}</Td>
                          <Td>{selectedGroup}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
              ) : null}
            </Step>
          ))}
        </Steps>
        {activeStep === steps.length ? (
          <Flex px={4} py={4} width="100%" flexDirection="column">
            <Heading fontSize="xl" textAlign="center">
              Woohoo! All steps completed!
            </Heading>
            <Button mx="auto" mt={6} size="sm" onClick={resetGroup}>
              Reset
            </Button>
          </Flex>
        ) : (
          <Flex width="100%" flexDir={"row"} justify="space-between" pt={5}>
            <Flex>
              <Button size="sm" onClick={resetGroup} isDisabled={isLoading}>
                Reset
              </Button>
            </Flex>
            <Flex flexDir={"row"}>
              <Button
                isDisabled={activeStep === 0 || isLoading}
                mr={4}
                onClick={prevStep}
                size="sm"
                variant="ghost"
              >
                Prev
              </Button>
              <Button
                size="sm"
                onClick={handleProcess}
                isLoading={isLoading}
                loadingText={"Sending"}
                isDisabled={processDone || isLoading}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}

export default Index;
