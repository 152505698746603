import {
  Button,
  Center,
  Flex,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo } from "react";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import { useStates } from "../../../../lib/hooks";
import moment from "moment";
import IconBox from "../../../../components/icons/IconBox";
import { Player } from "@lottiefiles/react-lottie-player";
import { Link } from "react-router-dom";
import millify from "millify";

export default function RecentNotification(props) {
  const { columnsData, tableData, loaded } = props;
  const { states, isLoading, isError } = useStates();
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => (tableData ? tableData : []), [tableData]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Skeleton isLoaded={loaded} fadeDuration={1.5} borderRadius={20}>
      <Card
        direction="column"
        w="100%"
        h="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" align="center" mt={4} mb="8px">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Recent Notification
          </Text>
        </Flex>
        {data.length > 0 ? (
          <Flex flexDir={"column"} justifyContent={"space-between"}>
            <Table
              {...getTableProps()}
              variant="simple"
              color="gray.500"
              mb="24px"
            >
              <Thead>
                {headerGroups.map((headerGroup, index) => (
                  <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => (
                      <Th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        pe="10px"
                        key={index}
                        borderColor={borderColor}
                      >
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {column.render("Header")}
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        let data = "";
                        if (cell.column.Header === "NAME") {
                          data = (
                            <Flex align="center" my={2}>
                              <Text
                                color={textColor}
                                textTransform={"uppercase"}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                {cell.value}
                              </Text>
                            </Flex>
                          );
                        } else if (cell.column.Header === "TOTAL COUNT") {
                          data = (
                            <Flex align="center">
                              <Text
                                me="10px"
                                color={textColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                {millify(cell.row.original.totalCount, {
                                  precision: 2,
                                  decimalSeparator: ".",
                                })}
                              </Text>
                            </Flex>
                          );
                        } else if (cell.column.Header === "CHANNELS") {
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value[0] === "IN_APP" ? (
                                <Flex flexDir={"row"}>
                                  <IconBox
                                    w="22px"
                                    h="22px"
                                    icon={
                                      <Player
                                        autoplay
                                        loop
                                        src="https://assets1.lottiefiles.com/private_files/lf30_xtkkgbco.json"
                                        style={{
                                          height: "22px",
                                          width: "22px",
                                        }}
                                      ></Player>
                                    }
                                  />

                                  <IconBox
                                    w="22px"
                                    h="22px"
                                    icon={
                                      <Player
                                        autoplay
                                        loop
                                        src="https://assets2.lottiefiles.com/temp/lf20_dFXDxA.json"
                                        style={{
                                          height: "52px",
                                          width: "52px",
                                        }}
                                      ></Player>
                                    }
                                  />
                                </Flex>
                              ) : (
                                <IconBox
                                  w="22px"
                                  h="22px"
                                  icon={
                                    <Player
                                      autoplay
                                      loop
                                      src="/lottiefiles/no_data.json"
                                      style={{ height: "25px", width: "28px" }}
                                    ></Player>
                                  }
                                />
                              )}
                            </Text>
                          );
                        } else if (cell.column.Header === "DATE") {
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {moment(cell.value).utc().format("LL")}
                            </Text>
                          );
                        }
                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={index}
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {data}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            <Flex flexDir={"row"} justifyContent={"center"}>
              <Link to={"/dashboard/notifications"}>
                <Button>See all</Button>
              </Link>
            </Flex>
          </Flex>
        ) : (
          <Flex flexDir={"column"} justifyContent={"center"}>
            <Center height={"230px"}>
              <IconBox
                w="56px"
                h="56px"
                icon={
                  <Player
                    autoplay
                    loop
                    src="https://assets2.lottiefiles.com/packages/lf20_g4wqji2g.json"
                    style={{ height: "120px", width: "120px" }}
                  ></Player>
                }
              />
            </Center>
            <Center>
              <Text mt={-10}>NO RECORDS YET</Text>
            </Center>
          </Flex>
        )}
      </Card>
    </Skeleton>
  );
}
