export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TOTAL COUNT",
    accessor: "total count",
  },
  {
    Header: "CHANNELS",
    accessor: "channels",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

