import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdAnnouncement,
  MdDashboard,
  MdListAlt,
  MdLock,
  MdNotifications,
  MdShoppingBag,
  MdSensors,
  MdMoveToInbox,
  MdWifi,
} from "react-icons/md";
import { RiAdminFill } from "react-icons/ri";

// Views Import
import MainDashboard from "views/admin/default";
import Notifications from "views/admin/notifications";
import NotificationsNew from "views/admin/notifications-new";

import Announcements from "views/admin/announcements";
import Survey from "views/admin/survey";
import Individual from "views/admin/notifications/individual";
import Sales from "views/admin/sales";

import SignInCentered from "views/auth/signIn";
import NewAnnouncement from "views/admin/announcements/new";

import Group from "views/admin/notifications/group";
import GroupNew from "views/admin/notifications-new/group";
import CreateGroup from "views/admin/notifications-new/filter";

import { StepsExample } from "views/admin/notifications/individual/Steps";
import { StepsExampleNew } from "views/admin/notifications-new/individual/Steps";

import Support from "views/admin/support";
import Filter from "./views/admin/notifications/group/filter";
import { FilterSms } from "./views/admin/notifications/group/steps/sms";
import { FilterApp } from "./views/admin/notifications/group/steps/app";
import { appGroup } from "./views/admin/notifications/group/single/app";
import { createGroup } from "./views/admin/notifications/group/single/create";
import { smsGroup } from "./views/admin/notifications/group/single/sms";
import SalesTools from "./views/admin/order-verification/main";
import Verification from "./views/admin/order-verification/verification";
import Confirmation from "./views/admin/order-verification/confirmation";
import Analytics from "./views/admin/sales/analytics";
import CoAdmin from "./views/admin/corporate-admin/index";
import Boarding from "./views/admin/boarding/main";
import Batch from "./views/admin/boarding/batch";
import BatchSingle from "./views/admin/boarding";
import Assets from "views/admin/assets";
import AssetsAnalytics from "views/admin/assets/analytics";
import AssetsMain from "views/admin/assets/main";
import AssetBox from "views/admin/assets/box";
import Packages from "views/admin/packages";

const routes = [
  {
    key: 1,
    name: "Dashboard",
    access: ["ProductManager", "admin"],
    layout: "/dashboard",
    path: "/main",
    sidebar: true,
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    key: 2,
    name: "Notifications",
    layout: "/dashboard",
    access: ["ProductManager", "Admin"],
    path: "/notifications",
    sidebar: true,
    icon: (
      <Icon as={MdNotifications} width="20px" height="20px" color="inherit" />
    ),
    component: NotificationsNew,
    secondary: true,
  },
  {
    key: 3,
    name: "Surveys",
    layout: "/dashboard",
    access: ["ProductManager", "Admin"],
    icon: <Icon as={MdListAlt} width="20px" height="20px" color="inherit" />,
    path: "/surveys",
    sidebar: true,
    component: Survey,
  },
  {
    key: 4,
    name: "Announcements",
    layout: "/dashboard",
    access: ["ProductManager", "Admin"],
    icon: (
      <Icon as={MdAnnouncement} width="20px" height="20px" color="inherit" />
    ),
    path: "/announcements",
    sidebar: true,
    component: Announcements,
  },
  {
    key: 5,
    name: "Create a New Announcement",
    layout: "/dashboard",
    access: [],
    path: "/announcement/new",
    sidebar: false,
    component: NewAnnouncement,
  },
  {
    key: 6,
    name: "Individual Notification",
    layout: "/dashboard",
    path: "/notification/individual-old",
    sidebar: false,
    component: Individual,
  },
  {
    key: 7,
    name: "Group Notification",
    layout: "/dashboard",
    access: ["ProductManager", "Admin"],
    path: "/notification/group",
    sidebar: false,
    component: Group,
  },
  {
    key: 8,
    name: "SMS Notification Group",
    layout: "/dashboard",
    access: ["Admin"],
    path: "/group/sms",
    sidebar: false,
    component: smsGroup,
  },
  {
    key: 9,
    name: "App Notification Group",
    layout: "/dashboard",
    access: ["Admin"],
    path: "/group/app",
    sidebar: false,
    component: appGroup,
  },
  {
    key: 10,
    name: "Create Notification Group",
    layout: "/dashboard",
    access: ["Admin"],
    path: "/group/create",
    sidebar: false,
    component: createGroup,
  },
  {
    key: 11,
    name: "Notification With Filter",
    layout: "/dashboard",
    access: ["ProductManager", "Admin"],
    path: "/notification/filter",
    sidebar: false,
    component: Filter,
  },
  {
    key: 12,
    name: "Notification With Filter",
    layout: "/dashboard",
    access: ["ProductManager", "Admin"],
    path: "/filter/sms",
    sidebar: false,
    component: FilterSms,
  },
  {
    key: 13,
    name: "Notification With Filter",
    layout: "/dashboard",
    access: ["ProductManager", "Admin"],
    path: "/filter/app",
    sidebar: false,
    component: FilterApp,
  },
  {
    key: 14,
    name: "Send SMS Notification",
    layout: "/dashboard",
    access: ["Corporate User", "Admin"],
    path: "/individual/sms",
    sidebar: false,
    component: StepsExample,
  },
  {
    key: 15,
    name: "Product Sales",
    layout: "/dashboard",
    path: "/sales",
    sidebar: true,
    access: ["ProductManager", "Admin"],
    icon: (
      <Icon as={MdShoppingBag} width="20px" height="20px" color="inherit" />
    ),
    component: Sales,
  },
  {
    key: 16,
    name: "Product Sales",
    layout: "/dashboard",
    path: "/order-analytics",
    sidebar: false,
    access: ["ProductManager", "Admin"],
    icon: (
      <Icon as={MdShoppingBag} width="20px" height="20px" color="inherit" />
    ),
    component: Analytics,
  },
  {
    key: 17,
    name: "Login",
    layout: "/auth",
    path: "/login",
    access: [],
    sidebar: false,
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    key: 18,
    name: "Support",
    layout: "/dashboard",
    path: "/support",
    access: [],
    sidebar: false,
    component: Support,
  },
  {
    key: 19,
    name: "Send In App Notification",
    layout: "/dashboard",
    access: ["Corporate User", "admin"],
    path: "/individual/app",
    sidebar: false,
    component: StepsExample,
  },

  {
    key: 20,
    name: "Order Verification",
    layout: "/dashboard",
    access: ["admin", "ProductManager"],
    path: "/order-verification",
    sidebar: false,
    component: Verification,
  },
  {
    key: 21,
    name: "Delivery Confirmation",
    layout: "/dashboard",
    access: ["admin", "ProductManager"],
    path: "/order-confirmation",
    sidebar: false,
    component: Confirmation,
  },
  {
    key: 22,
    name: "Support Tools",
    layout: "/dashboard",
    access: ["admin", "ProductManager"],
    path: "/support-tools",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    sidebar: true,
    component: SalesTools,
  },
  {
    key: 23,
    name: "Corporate Admin",
    layout: "/dashboard",
    access: ["admin", "ProductManager"],
    path: "/support-tools",
    icon: <Icon as={RiAdminFill} width="20px" height="20px" color="inherit" />,
    sidebar: true,
    component: CoAdmin,
  },
  {
    key: 24,
    name: "Users",
    layout: "/dashboard",
    access: ["admin", "ProductManager"],
    path: "/users",
    icon: <Icon as={RiAdminFill} width="20px" height="20px" color="inherit" />,
    sidebar: true,
    component: Boarding,
  },
  {
    key: 25,
    name: "Batch Onboarding",
    layout: "/dashboard",
    access: ["admin", "ProductManager"],
    path: "/boarding-batch",

    sidebar: false,
    component: Batch,
  },
  {
    key: 26,
    name: "Single Onboarding",
    layout: "/dashboard",
    access: ["admin", "ProductManager"],
    path: "/boarding-single",
    sidebar: false,
    component: BatchSingle,
  },
  {
    key: 27,
    name: "Assets List",
    layout: "/dashboard",
    access: ["admin", "ProductManager"],
    path: "/assets-list",
    icon: <Icon as={MdSensors} width="20px" height="20px" color="inherit" />,
    sidebar: false,
    component: Assets,
  },
  {
    key: 28,
    name: "Assets Analytics",
    layout: "/dashboard",
    access: ["admin", "ProductManager"],
    path: "/assets-analytics",
    icon: <Icon as={MdSensors} width="20px" height="20px" color="inherit" />,
    sidebar: false,
    component: AssetsAnalytics,
  },
  {
    key: 29,
    name: "Assets",
    layout: "/dashboard",
    access: ["admin", "ProductManager"],
    path: "/assets-main",
    icon: <Icon as={MdSensors} width="20px" height="20px" color="inherit" />,
    sidebar: true,
    component: AssetsMain,
  },
  {
    key: 30,
    name: "Box",
    layout: "/dashboard",
    access: ["admin", "ProductManager"],
    path: "/assets-box",
    icon: (
      <Icon as={MdMoveToInbox} width="20px" height="20px" color="inherit" />
    ),
    sidebar: false,
    component: AssetBox,
  },
  {
    key: 31,
    name: "Packages",
    layout: "/dashboard",
    access: ["admin", "ProductManager"],
    path: "/packages",
    icon: <Icon as={MdWifi} width="20px" height="20px" color="inherit" />,
    sidebar: true,
    component: Packages,
  },
  {
    key: 31,
    name: "Notifications New",
    layout: "/dashboard",
    access: ["ProductManager", "Admin"],
    path: "/notifications-new",
    sidebar: false,
    icon: (
      <Icon as={MdNotifications} width="20px" height="20px" color="inherit" />
    ),
    component: NotificationsNew,
    secondary: true,
  },
  {
    key: 32,
    name: "Send Individual Notification",
    layout: "/dashboard",
    access: ["Corporate User", "Admin"],
    path: "/notification/individual",
    sidebar: false,
    component: StepsExampleNew,
  },
  {
    key: 33,
    name: "Send Group Notification",
    layout: "/dashboard",
    access: ["Corporate User", "Admin"],
    path: "/notification/group-new",
    sidebar: false,
    component: GroupNew,
  },
  {
    key: 33,
    name: "Create Group ",
    layout: "/dashboard",
    access: ["Corporate User", "Admin"],
    path: "/notification/create-group",
    sidebar: false,
    component: CreateGroup,
  },
];

export default routes;
